import React from 'react';
import styled, { css } from 'styled-components';
import Button from '@/components/Button';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import PreorderModals from '@/components/modals/PreorderModals';
import { useModal } from '@tager/web-components';

function Preorder() {
  const openModal = useModal();

  function openContactModal() {
    openModal(PreorderModals, {});
  }
  return (
    <Component>
      <ButtonWrapper>
        <Button variant="primary-fourth" onClick={openContactModal}>
          Preorder for Free
        </Button>
      </ButtonWrapper>
      <Description>
        3269 people are already waiting for this program.
      </Description>
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonWrapper = styled.div`
  //width: 196px;
  //height: 55px;
  width: 147px;
  height: 41px;
  button {
    font-size: 12px;
    line-height: 17px;
    border-radius: 40px;
    font-weight: bold;
    z-index: 8;
    //font-size: 16px;
    //line-height: 23px;
    color: ${colors.white};
    //border-radius: 53.3333px;
    transition: all 0.4s ease;
  }
  ${media.tabletSmall(css`
    button {
      cursor: default;
    }
  `)}
  ${media.mobileLarge(css`
    width: 130px;
    height: 32px;
    button {
      font-size: 10px;
      line-height: 14px;
    }
  `)}
`;

const Description = styled.p`
  max-width: 159px;
  font-size: 12px;
  margin-left: 16px;
  font-weight: 600;
  line-height: 150%;
  color: ${colors.white};
  //margin-left: 21px;
  //font-size: 16px;
  //max-width: 212px;

  ${media.mobileLarge(css`
    max-width: 120px;
    font-size: 10px;
    margin-left: 10px;
  `)}
`;

export default Preorder;
