import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function ListInclude() {
  return (
    <List>
      <ItemCell>
        <Item>5 Categories</Item>
      </ItemCell>
      <ItemCell>
        <Item>25 Videos</Item>
      </ItemCell>
      <ItemCell>
        <Item>400+ Minutes</Item>
      </ItemCell>
    </List>
  );
}

const List = styled.div`
  position: relative;
  display: flex;
  background: radial-gradient(245.85% 703.31% at 0% 0%, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0) 78.57%), #040407;
  border: 1.33333px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 85.3333px rgba(255, 255, 255, 0.06);
  border-radius: 32px;
  padding: 20px 80px;
  margin: 0 auto;
  max-width: 970px;
  @media (max-height: 1000px) {
    padding: 15px 80px;
  }
  ${media.laptop(css`
    max-width: 970px;
    border-radius: 24px;
    padding: 25px 102px;
    @media (max-height: 1000px) {
      padding: 15px 80px;
      max-width: 750px;
    }
  `)}
  ${media.tablet(css`
    max-width: 720px;
    border-radius: 24px;
    padding: 15px 80px;
  `)}
  ${media.mobile(css`
    flex-direction: column;
    padding: 0;
    background: transparent;
    border: none;
    box-shadow: none;
    align-items: center;
    border-radius: 0;
  `)}
  
`;

const ItemCell = styled.div`
  padding: 0 45px;
  ${media.laptop(css`
    padding: 0 45px;
    @media (max-height: 1000px) {
      padding: 0 20px;
    }
  `)}

  ${media.tablet(css`
    padding: 0 20px;
  `)}
  ${media.mobile(css`
    padding: 23px 0 22px;
    max-width: 87.5%;
    width: 100%;
    background: radial-gradient(
        245.85% 703.31% at 0% 0%,
        rgba(255, 255, 255, 0.13) 0%,
        rgba(255, 255, 255, 0) 78.57%
      ),
      #040407;
    border: 1.33333px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 85.3333px rgba(255, 255, 255, 0.06);
    border-radius: 16px;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  `)}
`;

const Item = styled.div`
  font-weight: bold;
    font-size: 28px;
  line-height: 150%;
  color: ${colors.white};
  ${media.laptop(css`
    font-size: 28px;
    @media (max-height: 1000px) {
      font-size: 24px;
    }
  `)}
  ${media.tablet(css`
    font-size: 24px;
  `)}
    ${media.mobile(css`
      font-size: 20px;
    `)}
  ${media.mobileSmall(css`
    font-size: 18px;
  `)}
`;

export default ListInclude;
