import styled, { css } from 'styled-components';
import { media } from '@/utils/mixin';
import {
  containerWidth,
  gutters,
  smallContainerWidth,
} from '@/constants/theme';

const ContentContainer = styled.div<{ isSmall?: boolean }>`
  ${(props) => css`
    max-width: ${containerWidth}px;
    width: 100%;
    margin: 0 auto;
    padding: 0 ${gutters.desktop}px;
    ${media.laptop(css`
      max-width: 1260px;
      padding: 0 ${gutters.tablet}px;
    `)}
    ${media.tabletSmall(css`
      max-width: 1260px;
      padding: 0 ${gutters.desktop}px;
    `)}
    ${media.mobile(css`
      padding: 0 ${gutters.mobile}px;
    `)}
    ${
      props.isSmall
        ? css`
            max-width: ${smallContainerWidth}px;
          `
        : css``
    }
  `}
`;

export default ContentContainer;
