import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { FOOTER_NAVBAR_LIST } from '../../Footer.constants';
import FooterNavItem from './components/FooterNavItem';
import { LayoutContext } from '@/components/Layout/LayoutCtx';

type Props = {
  SectionScroll: LayoutContext;
};

function FooterNav({ SectionScroll }: Props) {
  return (
    <Navbar>
      <List>
        {FOOTER_NAVBAR_LIST.map((navbarItem, index) => {
          return (
            <ItemCell key={index}>
              <FooterNavItem
                navbarItem={navbarItem}
                SectionScroll={SectionScroll}
              />
            </ItemCell>
          );
        })}
      </List>
    </Navbar>
  );
}

const Navbar = styled.nav``;
const List = styled.div`
  display: flex;
  width: 878px;
  justify-content: space-between;
  ${media.laptop(css`
    width: 676px;
  `)}
  ${media.tabletSmall(css`
    width: 541px;
  `)}
    ${media.mobile(css`
      width: 400px;
      flex-wrap: wrap;
      max-height: 100px;
      justify-content: flex-start;
    `)}
    ${media.mobileLarge(css`
      width: 50vw;
      max-height: 96px;
      flex-direction: column;
    `)}
    
`;
const ItemCell = styled.div`
  ${media.mobile(css`
    padding-right: 20px;
  `)}
`;

export default FooterNav;
