import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

type Props = {
  text: React.ReactNode;
};

function CourseLaunchText({ text }: Props) {
  return <Wrapper>{text}</Wrapper>;
}

const Wrapper = styled.p<{ isFontsUp?: boolean }>`
  width: 100%;
  margin: 0 auto;
  font-weight: bold;
  font-size: 100px;
  line-height: 150%;
  text-align: center;
  color: ${colors.white};
  span {
    color: ${colors.redInvalid};
  }
  @media (max-height: 950px) {
    font-size: 80px;
  }
  @media (max-height: 820px) {
    font-size: 70px;
  }
  ${media.laptop(css`
    font-size: 80px;
    @media (max-height: 950px) {
      font-size: 72px;
    }
    @media (max-height: 700px) {
      font-size: 64px;
    }
  `)}
  ${media.tablet(css`
    font-size: 64px;
  `)}
  ${media.tabletSmall(css`
    font-size: 64px;
    @media (max-height: 700px) {
      font-sizt: 56px;
    }
  `)}
  ${media.mobile(css`
    font-size: 28px;
  `)}
  ${media.mobileSmall(css`
    font-size: 21px;
  `)}
`;

export default CourseLaunchText;
