import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useModal } from '@tager/web-components';

import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { useMedia } from '@tager/web-core';

import { ReactComponent as HeaderLogo } from '@/assets/svg/logo.svg';

import Button from '@/components/Button';
import { useLayoutContext } from '@/components/Layout/LayoutCtx';
import PreorderModals from '@/components/modals/PreorderModals';

import { HEADER_MENU_LIST } from './Header.constants';
import NavBlock from './components/NavBlock';
import { scrollToElement } from '@/utils/common';

function Header() {
  const openModal = useModal();
  const isMobile = useMedia(`(max-width: ${breakpoints.tabletLarge - 1}px)`);

  const {
    arrayRef: { heroRef, statementsRef },
    isFixed,
    setFixed,
  } = useLayoutContext();
  const SectionScroll = useLayoutContext();

  const [isNavigationOpen, setNavigationOpen] = useState(false);

  const navRef = useRef<HTMLDivElement>(null);

  function toggleNavigation() {
    setNavigationOpen(!isNavigationOpen);
  }

  useEffect(() => {
    function listener(this: Document) {
      if (!heroRef.current || !statementsRef.current || !isMobile) return;
      if (heroRef.current.getBoundingClientRect().bottom <= 400 && !isFixed) {
        setFixed(true);
        setNavigationOpen(false);
      }
      if (
        (heroRef.current.getBoundingClientRect().bottom > 400 && isFixed) ||
        statementsRef.current.getBoundingClientRect().bottom < 100
      ) {
        setFixed(false);
        setNavigationOpen(false);
      }
    }

    document.addEventListener('scroll', listener);

    return () => document.removeEventListener('scroll', listener);
  }, [isFixed]);

  useEffect(() => {
    function pageClick(this: Document, e: any) {
      if (isNavigationOpen && navRef.current !== e.target) {
        setNavigationOpen(false);
      }
    }

    document.addEventListener('click', pageClick);

    return () => document.removeEventListener('click', pageClick);
  }, [isNavigationOpen]);

  function openContactModal() {
    openModal(PreorderModals, {});
  }
  return (
    <HeaderContainer isFixed={isFixed}>
      <HeaderWrapper>
        <HeaderLeft>
          <LogoContainer>
            <LogoLink href="/" />
            <HeaderLogo />
          </LogoContainer>
        </HeaderLeft>
        <HeaderRight isFixed={isFixed}>
          <HeaderNav
            ref={navRef}
            isNavigationOpen={isNavigationOpen}
            isFixed={isFixed}
          >
            <Nav>
              <NavList>
                {HEADER_MENU_LIST.map((menuItem, index) => {
                  return (
                    <NavBlock
                      SectionScroll={SectionScroll}
                      setNavigationOpen={() => setNavigationOpen(false)}
                      menuItem={menuItem}
                      key={index}
                    />
                  );
                })}
              </NavList>
            </Nav>
          </HeaderNav>
          <HeaderPreorder isFixed={isFixed}>
            <ButtonWrapper>
              <Button variant="primary" onClick={openContactModal}>
                Preorder for Free
              </Button>
            </ButtonWrapper>
            <PreorderDescription>
              Preorder now to get access to the entire course content for $9.99
              <PreorderCost>$99.99</PreorderCost>
            </PreorderDescription>
          </HeaderPreorder>
        </HeaderRight>
        <BurgerMenu isFixed={isFixed} onClick={toggleNavigation}>
          <BurgerInner isOpened={isNavigationOpen} />
        </BurgerMenu>
      </HeaderWrapper>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.header<{ isFixed: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  max-height: 100px;
  height: 100%;
  z-index: 9;
  background-color: ${colors.black};
  display: flex;
  transition: all 0.3s ease-in-out;
  ${media.mobileMedium(css`
    max-height: 48px;
  `)}
  ${(props) =>
    props.isFixed
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : ''}
`;

const HeaderWrapper = styled.div`
  display: flex;
  max-width: 1646px;
  width: 100%;
  margin: 0 auto;
  padding: 0 40px;
  ${media.laptop(css`
    max-width: 1260px;
    padding: 0 45px;
  `)};
  ${media.tabletSmall(css`
    padding: 34px 20px;
    position: relative;
  `)}
  ${media.mobileMedium(css`
    padding: 14.5px 20px;
  `)}
`;

const HeaderLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoContainer = styled.div`
  position: relative;
  svg {
    height: 32px;
    width: 70px;
    ${media.mobile(css`
      height: 19px;
      width: 34px;
    `)}
  }
`;
const LogoLink = styled.a`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
  z-index: 10;
`;

const HeaderRight = styled.div<{ isFixed?: boolean }>`
  flex: 1 1 1px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 63px;
  transition: all 0.3s ease-in-out, margin-left 0.7s ease-in-out;
  ${media.laptop(css`
    margin-left: 43px;
    justify-content: flex-start;
  `)};
  ${media.tabletLarge(css`
    margin-left: 35px;
    justify-content: center;
  `)}
  ${(props) =>
    props.isFixed
      ? css`
          ${media.mobile(css`
            justify-content: center;
          `)}
        `
      : ''}
  ${media.mobile(css`
    justify-content: flex-end;
    margin-left: 0;
  `)}
    ${media.mobileLarge(css`
      max-width: 80%;
    `)}
  ${media.mobileSmall(css`
    flex: 0 0 230px;
    justify-content: flex-start;
    margin-left: 41px;
  `)}
`;

const HeaderNav = styled.div<{ isNavigationOpen: boolean; isFixed: boolean }>`
  ${(props) =>
    props.isFixed
      ? css`
          ${media.tabletLarge(css`
            padding: 7px 0 36px;
            position: absolute;
            top: calc(100% - 1px);
            left: 0;
            right: 0;
            background-color: ${colors.black};
            max-height: 0;
            opacity: 0;
            visibility: hidden;
            transition: all 0.4s ease;
            display: flex;
            justify-content: center;
          `)}
          ${media.tabletSmall(css`
            transition: all 0.3s ease;
          `)}
        `
      : ''}
  
  ${media.mobileLarge(css`
    padding: 10px 0 36px;
    position: absolute;
    top: calc(100% - 1px);
    left: 0;
    right: 0;
    background-color: ${colors.black};
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease;
    display: flex;
    justify-content: center;
  `)}

  ${(props) =>
    props.isNavigationOpen
      ? css`
          ${media.tabletLarge(css`
            max-height: 200px;
            opacity: 1;
            visibility: visible;
            z-index: 200;
          `)}
        `
      : ''}
`;

const Nav = styled.nav`
  ${media.laptop(css`
    width: 540px;
  `)}
  ${media.mobileLarge(css`
    max-width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
  `)}
`;

const NavList = styled.ul`
  display: flex;
  margin: 0 -15px;
  ${media.laptop(css`
    margin: 0;
    justify-content: space-between;
  `)}

  ${media.mobileLarge(css`
    flex-wrap: wrap;
    margin: 0 auto;
    flex: 0 0 70%;
    li {
      flex: 0 0 40%;
      &:nth-child(2) {
        order: 2;
      }
      &:nth-child(3) {
        order: 3;
      }
      &:nth-child(4) {
        order: 4;
      }
      &:nth-child(6) {
        order: 2;
      }
      &:nth-child(7) {
        order: 3;
      }
    }
  `)}
`;

const ButtonWrapper = styled.div`
  width: 268px;
  height: 66px;
  button {
    font-weight: bold;
    font-size: 20px;
    line-height: 29px;
    border-radius: 12px;
  }
  ${media.laptop(css`
    max-width: 202px;
    width: 100%;
    height: 50px;
    button {
      font-size: 16px;
      line-height: 23px;
      border-radius: 8px;
    }
  `)}
  ${media.tabletSmall(css`
    button {
      cursor: default;
    }
  `)}
  ${media.mobileLarge(css`
    width: 180px;
    height: 34px;
    button {
      font-size: 14px;
      line-height: 14px;
      box-shadow: none;
    }
  `)};
  ${media.mobileSmall(css`
    width: 130px;
    height: 32px;
    button {
      font-size: 10px;
      line-height: 14px;
    }
  `)}
`;

const HeaderPreorder = styled.div<{ isFixed: boolean }>`
  width: 100%;
  margin-left: 123px;
  align-items: center;
  display: flex;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s linear 0.2s;
  ${media.laptop(css`
    margin-left: 43px;
  `)};
  ${media.tabletSmall(css`
    margin-left: 30px;
  `)};
  ${media.mobile(css`
    margin-left: 0;
    justify-content: center;
  `)}
    ${media.mobileSmall(css`
      width: auto;
    `)}
  ${(props) =>
    props.isFixed
      ? css`
          opacity: 1;
          visibility: visible;
          ${media.tabletLarge(css`
            width: calc(100% - 105px);
            margin-left: 0;
            justify-content: center;
          `)}
          ${media.mobile(css`
            width: 100%;
            margin-left: 0;
          `)}
          ${media.mobileSmall(css`
            width: auto;
          `)}
        `
      : ''}
`;

const PreorderDescription = styled.p`
  max-width: 352px;
  padding-left: 28px;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: ${colors.white};
  ${media.laptop(css`
    max-width: 281px;
    font-size: 14px;
    padding-left: 20px;
  `)}
  ${media.tabletLarge(css`
    max-width: 272px;
  `)}
  ${media.mobile(css`
    display: none;
  `)}
`;
const PreorderCost = styled.span`
  text-decoration: line-through;
  color: ${colors.pink};
  padding-left: 6px;
  ${media.laptop(css`
    padding-left: 4px;
  `)}
  ${media.tabletLarge(css`
    padding-left: 4px;
  `)}
`;

const BurgerMenu = styled.button<{ isFixed?: boolean }>`
  width: 24px;
  height: 100%;
  display: none;
  align-items: center;
  position: absolute;
  right: 39px;
  top: 40px;
  z-index: 110;
  ${(props) =>
    props.isFixed
      ? css`
          ${media.tabletLarge(css`
            display: flex;
            top: 0;
          `)}
        `
      : ''}

  ${media.tabletSmall(css`
    cursor: default;
  `)}
  ${media.mobileMedium(css`
    display: flex;
    justify-content: center;
    top: 0;
    right: 20px;
  `)}
`;

const BurgerInner = styled.span<{ isOpened: boolean }>`
  width: 24px;
  display: block;
  position: relative;
  height: 4px;
  background: ${colors.white};
  transition: all 0.2s ease;
  border-radius: 3px;
  ${media.mobile(css`
    width: 16px;
    height: 2px;
    border-radius: 2px;
  `)}

  &:before {
    content: '';
    height: 4px;
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    top: -8px;
    border-radius: 3px;
    background: ${colors.white};
    transition: all 0.2s ease;
    ${media.mobile(css`
      height: 2px;
      top: -5px;
    `)}
  }
  &:after {
    content: '';
    height: 4px;
    position: absolute;
    display: block;
    left: 0;
    right: 0;
    bottom: -8px;
    border-radius: 3px;
    background: ${colors.white};
    transition: all 0.2s ease;
    ${media.mobile(css`
      height: 2px;
      bottom: -5px;
    `)}
  }
  ${(props) =>
    !props.isOpened
      ? css``
      : css`
          background: ${colors.black};
          &:before {
            backface-visibility: hidden;
            transform: rotate(45deg);
            transition: 0.45s 0.2s;
            top: 0;
          }
          &:after {
            backface-visibility: hidden;
            transform: rotate(-405deg);
            bottom: 0;
            transition: 0.45s 0.2s;
          }
        `}
`;

export default Header;
