import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { SocialItemType } from '../../../Footer.constants';

type Props = {
  socialItem: SocialItemType;
};

function SocialItem({ socialItem }: Props) {
  const { ItemIcon, url } = socialItem;
  return (
    <Component>
      <SocialLink href={url} target="_blank">
        {ItemIcon}
      </SocialLink>
    </Component>
  );
}

const Component = styled.div`
  svg {
    fill: ${colors.white};
    transition: all 0.3s ease-in-out;
    &:hover {
      fill: ${colors.gray100};
    }
  }
  ${media.laptop(css`
    svg {
      height: 16px;
    }
  `)}
`;

const SocialLink = styled.a``;

export default SocialItem;
