export const colors = {
  black: 'black',
  black100: '#101010',
  black200: '#262626',
  black300: '#3b3b3b',
  black400: '#1A1A1A',
  black500: '#0A0A0A',
  black600: '#030307',
  black700: '#333333',
  gray100: '#ccc',
  gray: '#888888',
  grayDark: '#bfbfbf',
  grayLight: '#999999',
  white: '#FFFFFF',
  red: 'red',
  red300: '#fd2828',
  redSecond: '#dd2424',
  red200:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), linear-gradient(257.21deg, #FD2828 0%, #FF1C2F 100%)',
  redInvalid: '#FD2828',
  redNormal:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)), linear-gradient(256.66deg, #FD2828 0%, #FF1C2F 100%)',
  redHover:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.12)), linear-gradient(256.66deg, #FD2828 0%, #FF1C2F 100%)',
  yellow: '#FFD233',
  yellowHover:
    'linear-gradient(0deg, rgba(0, 0, 0, 0.06), rgba(0, 0, 0, 0.06)), #FFD233',
  pink: '#fe9393',
} as const;

/** Source: https://htmlacademy.ru/blog/useful/css/short-14 */
const fallbackFont = [
  '-apple-system',
  "'BlickMacSystemFont'",
  "'Segoe UI'",
  "'Roboto'",
  "'Oxygen'",
  "'Ubuntu'",
  "'Cantarell'",
  "'Fira Sans'",
  "'Droid Sans'",
  "'Helvetica Neue'",
  'sans-serif',
].join(',');

export const fonts = {
  Jost: `'Jost*', ${fallbackFont}`,
};

/**
 * Reference - Screen Resolution Stats Worldwide:
 * https://gs.statcounter.com/screen-resolution-stats
 *
 * 16px - scrollbar width on Windows,
 * some browsers doesn't include scrollbar width when calculate media queries
 */
export const breakpoints = {
  /** iPhone 5/SE */
  mobileSmall: 320,
  /** iPhone 6/7/8/X */
  mobileMedium: 375,
  /** iPhone 6/7/8 Plus */
  mobileLarge: 480,
  mobileLargeX: 640,
  /** iPad 1, 2, Mini and Air */
  tabletSmall: 768,
  tabletLarge: 1024,
  /** 1280 - 16 = 1264 -> 1260 - more beautiful number :) */
  laptop: 1260,
  /** 1536 - 16 = 1520 -> 1500 - more beautiful number :) */
  desktop: 1500,
};

export const containerWidth = 1646;
export const smallContainerWidth = 1380;

export const gutters = {
  desktop: 40,
  tablet: 45,
  mobile: 20,
};
