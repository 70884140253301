import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { SOCIAL_LIST } from '../../Footer.constants';

import SocialItem from './components/SocialItem';

function FooterSocial() {
  return (
    <SocialList>
      {SOCIAL_LIST.map((socialItem, index) => {
        return (
          <SocialItemCell key={index}>
            <SocialItem socialItem={socialItem} />
          </SocialItemCell>
        );
      })}
    </SocialList>
  );
}

const SocialList = styled.div`
  display: flex;
  width: 100%;
  ${media.laptop(css`
    display: flex;
  `)}
  ${media.mobile(css`
    max-width: 100%;
    justify-content: center;
  `)}
`;

const SocialItemCell = styled.div`
  padding: 0 30px 0 0;
  ${media.tablet(css`
    padding: 0 20px 0 0;
  `)}
  ${media.mobile(css`
    padding: 0 10px;
  `)}
`;

export default FooterSocial;
