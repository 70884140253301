import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import TagList from './components/TagList';
import Profile from './components/Profile';
import Preorder from './components/Preorder';
import Picture from '@/components/Picture';
import { EducationItemType } from '@/modules/Home/Home.constants';

type Props = {
  slideItem: EducationItemType;
};

function EducationSlide({ slideItem }: Props) {
  const { background, profile, tags, title } = slideItem;
  const { bgImage, bgImageWebp } = background;
  return (
    <Wrapper>
      <Background>
        {bgImage && bgImageWebp ? (
          <Picture
            // // desktop={{
            // //   src: bgImage.desktop,
            // //   src2x: bgImage.desktop2x,
            // //   webp: bgImageWebp.desktop,
            // //   webp2x: bgImageWebp.desktop2x,
            // // }}
            tabletLarge={{
              src: bgImage.tablet,
              src2x: bgImage.tablet2x,
              webp: bgImageWebp.tablet,
              webp2x: bgImageWebp.tablet2x,
            }}
            mobileLarge={{
              src: bgImage.smallTablet,
              src2x: bgImage.smallTablet2x,
              webp: bgImageWebp.smallTablet,
              webp2x: bgImageWebp.smallTablet2x,
            }}
            mobileSmall={{
              src: bgImage.mobileS,
              src2x: bgImage.mobileS2x,
              webp: bgImageWebp.mobileS,
              webp2x: bgImageWebp.mobileS2x,
            }}
          />
        ) : null}
      </Background>
      <Content>
        <Title>{title}</Title>
        <Profile profile={profile} />
        <TagList tags={tags} />
        <Preorder />
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  height: 545px;
  padding: 48px 72px 0;
  overflow: hidden;
  background: black;
  @media (max-height: 1000px) {
    height: 445px;
    padding: 32px 32px 0;
  }
  @media (max-height: 820px) {
    height: 545px;
    padding: 48px 72px 0;
  }
  @media (max-height: 800px) {
    height: 445px;
    padding: 32px 32px 0;
  }
  @media (max-height: 680px) {
    padding: 32px 32px 0;
    height: 390px;
  }
  ${media.laptop(css`
    padding: 48px 72px 0;
    height: 545px;
    @media (max-height: 1000px) {
      height: 445px;
      padding: 32px 32px 0;
    }
    @media (max-height: 880px) {
      height: 390px;
    }
    @media (max-height: 820px) {
      height: 445px;
    }
    @media (max-height: 680px) {
      height: 390px;
    }
  `)}
  ${media.tablet(css`
    padding: 32px 32px 0;
    height: 340px;
  `)}
  ${media.mobileLarge(css`
    padding: 32px 20px 0;
    height: 144vw;
  `)}
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Content = styled.div`
  position: relative;
`;

const Title = styled.span`
  display: block;
  max-width: 468px;
  margin-bottom: 24px;
  font-size: 28px;
  color: ${colors.white};
  font-weight: bold;
  line-height: 150%;
  //margin-bottom: 32px;
  //font-size: 37.3333px;
  //max-width: 624px;

  @media (max-height: 1000px) {
    max-width: 418px;
    margin-bottom: 16px;
    font-size: 24px;
  }
  @media (max-height: 820px) {
    max-width: 468px;
    margin-bottom: 24px;
    font-size: 28px;
  }
  @media (max-height: 780px) {
    max-width: 418px;
    margin-bottom: 16px;
    font-size: 24px;
  }
  ${media.laptop(css`
    max-width: 468px;
    margin-bottom: 24px;
    font-size: 28px;
    @media (max-height: 1000px) {
      max-width: 418px;
      margin-bottom: 16px;
      font-size: 24px;
    }
  `)}
  ${media.tablet(css`
    max-width: 418px;
    margin-bottom: 16px;
    font-size: 24px;
  `)}
  ${media.mobileLarge(css`
    font-size: 20px;
  `)}
  ${media.mobileSmall(css`
    font-size: 16px;
  `)}
`;

export default EducationSlide;
