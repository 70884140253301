import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { FaqItemType } from '@/modules/Home/Home.constants';

type Props = {
  faqItem: FaqItemType;
};

function FaqItem({ faqItem }: Props) {
  const { title, text } = faqItem;
  return (
    <Item>
      <Title>{title}</Title>
      <Text>{text}</Text>
    </Item>
  );
}

const Item = styled.div`
  color: ${colors.white};
`;
const Title = styled.span`
  display: block;
  font-weight: bold;
  font-size: 20px;
  line-height: 150%;
  margin-bottom: 8px;
  @media (max-height: 900px) {
  font-size: 18px;
  }
  @media (max-height: 760px) {
    font-size: 16px;
  }
  ${media.laptop(css`
    font-size: 18px;
    margin-bottom: 8px;
    @media (max-height: 720px) {
      font-size: 18px;
      margin-bottom: 6px;
    }
  `)}
  ${media.tablet(css`
    @media (max-height: 800px) {
      font-size: 16px;
    }
  `)}
  ${media.mobile(css`
    font-size: 18px;
  `)}
  ${media.mobileSmall(css`
    font-size: 14px;
    margin-bottom: 6px;
  `)}
`;
const Text = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  a {
    color: rgb(234, 244, 255);
    text-decoration: underline;
    text-decoration-color: transparent;
    transition: all 0.3s ease;
    &:hover {
        text-decoration-color: rgb(234, 244, 255);
    }
  }
  @media (max-height: 900px) {
    font-size: 18px;
  }
  @media (max-height: 760px) {
    font-size: 16px;
  }
  color: ${colors.grayDark};
  ${media.laptop(css`
    font-size: 18px;
    @media (max-height: 760px) {
      font-size: 16px;
    }
    @media (max-height: 700px) {
      font-size: 14px;
    }
  `)}
    ${media.tablet(css`
      @media (max-height: 800px) {
        font-size: 16px;
      }
      @media (max-height: 700px) {
        font-size: 14px;
      }
    `)}
  ${media.mobile(css`
    font-size: 18px;
  `)}
  ${media.mobileSmall(css`
    font-size: 14px;
  `)}
`;

export default FaqItem;
