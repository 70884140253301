import React, { useEffect } from 'react';
import { useMedia } from '@tager/web-core';

import ReactFullpage, { FullpageApi } from '@fullpage/react-fullpage';

import Education from '@/modules/Home/components/Education';
import Quiz from '@/modules/Home/components/Quiz';
import Hero from '@/modules/Home/components/Hero';
import Teaser from '@/modules/Home/components/Teaser';
import Structure from '@/modules/Home/components/Structure';
import Ready from '@/modules/Home/components/Ready';
import Faq from '@/modules/Home/components/Faq';
import Footer from '@/components/Layout/components/Footer';
import CourseDate from '@/modules/Home/components/CourseDate';
import styled, { css } from 'styled-components';
import { media } from '@/utils/mixin';

type Props = {
  fullpageApi: FullpageApi;
  state: any;
};
function ReactWrapper({ fullpageApi, state }: Props) {
  const isMobile = useMedia(`(max-width: 767px)`);

  useEffect(() => {
    if (!isMobile || !state.initialized) return;
    setTimeout(() => fullpageApi.destroy('all'), 1000);
  }, [state.initialized, isMobile]);

  return (
    <ReactFullpage.Wrapper>
      <Hero />
      <Quiz />
      <Teaser />
      <Education />
      <Structure />
      <CourseDate />
      <Ready />
      <SectionWrapper className="section">
        <Faq />
        <Footer />
      </SectionWrapper>
    </ReactFullpage.Wrapper>
  );
}

const SectionWrapper = styled.div``;

export default ReactWrapper;
