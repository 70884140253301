import { Nullable } from '@tager/web-core';

export function scrollToElement(
  element: Nullable<HTMLElement>,
  options?: { offset?: number }
): void {
  if (!element) return;

  const offset = options?.offset ?? 0;

  const topPosition =
    element.getBoundingClientRect().top + window.pageYOffset + offset;
  console.log(topPosition);
  window.scrollTo({ top: topPosition, behavior: 'smooth' });
}

export function getParamAsString(
  param: Array<string> | string | undefined
): string {
  return Array.isArray(param) ? param[0] : param ?? '';
}
