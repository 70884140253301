import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

type Props = {
  title: string;
};

function SectionTop({ title }: Props) {
  return (
    <Wrapper>
      <Title>{title}</Title>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  text-align: center;
  margin-bottom: 32px;
  position: relative;
  @media (max-height: 1000px) {
    margin-bottom: 30px;
  }
  @media (max-height: 690px) {
    margin-bottom: 25px;
  }

  ${media.laptop(css`
    margin-bottom: 32px;
  `)}
`;

const Title = styled.span`
  font-weight: bold;
  font-size: 42.6667px;
  line-height: 150%;
  color: ${colors.white};
  @media (max-height: 900px) {
    font-size: 32px;
  }
  ${media.laptop(css`
    font-size: 32px;
  `)}
  
  ${media.mobile(css`
    font-size: 28px;
  `)}
  
  ${media.mobileSmall(css`
    font-size: 24px;
  `)}
`;

export default SectionTop;
