import React from 'react';

import ReactFullpage from '@fullpage/react-fullpage';
import ReactWrapper from './FullPageWrapper';
import { useLayoutContext } from '@/components/Layout/LayoutCtx';

function FullPageComponent() {
  const { setFixed } = useLayoutContext();
  return (
    <ReactFullpage
      anchors={[
        'home',
        'quizz',
        'teaser',
        'examples',
        'team',
        'offer',
        'preorder',
        'faq',
      ]}
      offsetSections={true}
      scrollOverflow={true}
      responsiveWidth={767}
      scrollingSpeed={750}
      fadingEffect={true}
      animateAnchor={true}
      menu={'.menu'}
      onLeave={(origin, destination, direction) => {
        if (origin.index == 0 && direction == 'down') {
          setFixed(true);
        }
        if (origin.index == 1 && direction == 'up') {
          setFixed(false);
        }
        if (destination.index > 5) {
          setFixed(false);
        }
        if (origin.index == 6 && direction == 'up') {
          setFixed(true);
        }
      }}
      render={({ state, fullpageApi }) => {
        return <ReactWrapper state={state} fullpageApi={fullpageApi} />;
      }}
    />
  );
}

export default FullPageComponent;
