import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  tags: Array<{ label: string }>;
};

function TagList({ tags }: Props) {
  return (
    <Component>
      {tags.map((tagItem, index) => {
        return (
          <TagCell key={index}>
            <Tag>{tagItem.label}</Tag>
          </TagCell>
        );
      })}
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  margin: 0 -5px 53px;

  ${media.laptop(css`
    margin: 0 -5px 53px;
    @media (max-height: 820px) {
      margin: 0 -6px 48px;
    }
  `)}
  ${media.tabletSmall(css`
    width: 100%;
    overflow: hidden;
    margin: 0 -5px 33px;
  `)}
  ${media.mobileLarge(css`
    width: 100%;
    overflow: hidden;
    margin: 0 -5px 33px;
  `)}
`;

const Tag = styled.div`
  border: 2px solid ${colors.red300};
  border-radius: 32px;
  font-size: 12px;
  padding: 4px 12px;
  font-weight: 600;
  line-height: 150%;
  color: ${colors.white};
  //padding: 5px 16px;
  //border-radius: 42.6667px;
  //font-size: 16px;
  //border: 2.66667px solid ${colors.red300};
  ${media.tabletSmall(css`
    font-size: 10px;
  `)}
`;

const TagCell = styled.div`
    padding: 0 5px;
  @media (max-height: 1000px) {
    &:last-child {
    display: none;
    }
  }
  ${media.laptop(css`
    padding: 0 5px;
    @media (max-height: 820px) {
      padding: 0 6px;
    }
  `)}
  ${media.tabletSmall(css`
    padding: 0 5px;
    @media (max-height: 1000px) {
      &:last-child {
        display: none;
      }
  `)}
  ${media.mobileLarge(css`
    padding: 0 5px;
  `)}
  &:last-child {
  position: relative;
    &:before {
    position: absolute;
    content: '';
    top: -2px;
    left: 0;
    right: 0;
    bottom: -2px;
     background-image: linear-gradient(90deg, transparent 37.59%, #040407 60.15%);
     z-index: 2;
     ${media.mobile(css`
       background-image: linear-gradient(
         90deg,
         transparent 20.59%,
         #040407 50.15%
       );
       top: -6px;
       bottom: -6px;
     `)}
    }

  }
`;

export default TagList;
