import React from 'react';
import { ModalProps } from '@tager/web-components';
import ModalBox from '@/components/ModalBox';

import PreorderFormContainer from './componenets/PreorderForm/PreorderForm.container';

type Props = ModalProps;

function PreorderModals({ closeModal }: Props) {
  return (
    <ModalBox
      centered
      width={1026}
      widthLaptop={770}
      widthTabletSmall={650}
      onClose={closeModal}
      isClose
      isOnlyMobile
    >
      <PreorderFormContainer onClose={closeModal} />
    </ModalBox>
  );
}

export default PreorderModals;
