import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

import { useModal } from '@tager/web-components';

import ContentContainer from '@/components/ContentContainer';
import Picture from '@/components/Picture';

import { HERO_BACKGROUND } from '../../Home.constants';

import PreorderBlock from './components/PreorderBlock';
import PartnersBlock from './components/PartnersBlock';
import { media } from '@/utils/mixin';
import PreorderModals from '@/components/modals/PreorderModals/PreorderModals';
import { useLayoutContext } from '@/components/Layout/LayoutCtx';

function Hero() {
  const openModal = useModal();
  const { bgImage, bgImageWebp } = HERO_BACKGROUND;
  const { arrayRef } = useLayoutContext();
  const { heroRef } = arrayRef;

  function openContactModal() {
    openModal(PreorderModals, {});
  }

  return (
    <Components className="section" ref={heroRef}>
      <BackgroundImage>
        {bgImage && bgImageWebp ? (
          <Picture
            desktop={{
              src: bgImage.desktop,
              src2x: bgImage.desktop2x,
              webp: bgImageWebp.desktop,
              webp2x: bgImageWebp.desktop2x,
            }}
            tabletLarge={{
              src: bgImage.tablet,
              src2x: bgImage.tablet2x,
              webp: bgImageWebp.tablet,
              webp2x: bgImageWebp.tablet2x,
            }}
            mobileLarge={{
              src: bgImage.smallTablet,
              src2x: bgImage.smallTablet2x,
              webp: bgImageWebp.smallTablet,
              webp2x: bgImageWebp.smallTablet2x,
            }}
            mobileSmall={{
              src: bgImage.mobileS,
              src2x: bgImage.mobileS2x,
              webp: bgImageWebp.mobileS,
              webp2x: bgImageWebp.mobileS2x,
            }}
          />
        ) : null}
      </BackgroundImage>
      <Inner>
        <ContentContainer>
          <InnerContainer>
            <Top>
              <Title>
                <TextChanges>90%</TextChanges> of consulting candidates get a
                market sizing question at their case interview
              </Title>
              <SubTitle>
                Learn from <TextChanges>25+</TextChanges> real case interview
                video examples
              </SubTitle>
              <PreorderBlock onClick={() => openContactModal()} />
            </Top>
            <Bottom>
              <PartnersBlock isHero={true} />
            </Bottom>
          </InnerContainer>
        </ContentContainer>
      </Inner>
    </Components>
  );
}

const Components = styled.div`
  width: 100%;
  flex: 1 1 1px;
  position: relative;
  overflow: hidden;
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  img {
    width: 100%;
    height: 100%;
    @media (min-width: 1024px) and (max-height: 780px) {
      object-fit: cover;
      object-position: top;
    }
  }
  ${media.mobileMedium(css`
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: bottom right;
    }
  `)}
`;

const InnerContainer = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  ${media.mobile(css`
    height: auto;
  `)}
`;

const Inner = styled.div`
  width: 100%;
  ${media.mobile(css`
    margin: 0;
    padding: 154px 0 36px;
    height: auto;
  `)}
  ${media.mobileLarge(css`
    padding: 100px 0 32px;
  `)}
  ${media.mobileSmall(css`
    padding: 80px 0 32px;
  `)}

`;

const Title = styled.h1`
  max-width: 970px;
  margin: 0 0 32px;
  padding: 0;
  font-weight: bold;
  font-size: 56px;
  line-height: 150%;
  color: ${colors.white};

  @media (max-height: 900px) {
    font-size: 52px;
  }
  @media (max-height: 810px) {
    font-size: 46px;
  }
  ${media.laptop(css`
    font-size: 44px;
    margin-bottom: 24px;
  `)}
  ${media.tabletSmall(css`
    font-size: 32px;
  `)}
  ${media.mobileLarge(css`
    font-size: 28px;
    margin-bottom: 44px;
  `)}
  ${media.mobileSmall(css`
    font-size: 24px;
  `)}
`;

const SubTitle = styled.p`
  display: block;
  font-weight: 600;
  font-size: 26px;
  line-height: 150%;
  color: ${colors.white};
  margin-bottom: 64px;

  @media (max-height: 900px) {
    margin-bottom: 45px;
  }
  ${media.laptop(css`
    font-size: 24px;
    margin-bottom: 48px;
  `)}
  ${media.tablet(css`
    font-size: 24px;
    margin-bottom: 95px;
    @media (max-height: 700px) {
      margin-bottom: 45px;
    }
  `)}
  ${media.tabletSmall(css`
    font-size: 18px;
  `)}
  ${media.mobile(css`
    margin-bottom: 95px;
    order: 2;
  `)}
  ${media.mobileMedium(css`
    margin-bottom: 60px;
    font-size: 16px;
    @media (max-height: 760px) {
      margin-bottom: 95px;
    }
  `)}
  ${media.mobileSmall(css`
    font-size: 14px;
    @media (max-height: 760px) {
      margin-bottom: 40px;
    }
  `)}
`;

const TextChanges = styled.span`
  padding: 0 5px;
  color: ${colors.redInvalid};
  ${media.mobileLarge(css`
    padding: 0 2px;
  `)}
`;

const Top = styled.div`
  flex: 1 1 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${media.mobile(css`
    flex: 1 1 auto;
    justify-content: flex-start;
  `)}
`;
const Bottom = styled.div`
  flex: 0 0 80px;
  @media (max-height: 850px) {
    margin-bottom: 20px;
  }
  ${media.laptop(css`
    flex: 0 0 70px;
    @media (max-height: 850px) {
      margin-bottom: 0;
    }
    @media (max-height: 800px) {
      margin-bottom: 20px;
    }
  `)}
  ${media.tablet(css`
    flex: 0 0 80px;
  `)}
  ${media.tablet(css`
    flex: 0 0 120px;
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
  `)}
  ${media.mobile(css`
    flex: 1 1 auto;
  `)}
`;

export default Hero;
