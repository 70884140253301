import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import Button from '@/components/Button';

import { position, quizItemType } from '../../../Home.constants';

import RightMessage from './RightMessage';
import WrongMessage from './WrongMessage';
import Picture from '@/components/Picture';

type Props = {
  sliderItem: quizItemType;
  isAnswer: boolean;
  setAnswer: () => void;
  isRight: boolean;
  setRight: () => void;
  isWrong: boolean;
  setWrong: () => void;
};

function QuizSlide({
  sliderItem,
  setAnswer,
  isAnswer,
  isRight,
  setRight,
  isWrong,
  setWrong,
}: Props) {
  const { question, correct, wrong, options, attribute, position } = sliderItem;
  const [isLast, setLast] = useState(false);

  function getResultHandler(active: boolean) {
    setAnswer();
    if (!active) {
      setWrong();
    } else {
      setRight();
    }
  }

  return (
    <Component>
      <Content>
        <Inner isAnswer={isAnswer}>
          <Title>{question}</Title>
          <AnswerOptions>
            {options.map((itemOption, index) => {
              return (
                <ButtonWrapper key={index}>
                  <ButtonAnswer
                    variant="secondary"
                    onClick={() => getResultHandler(itemOption.active)}
                  >
                    {itemOption.value}
                  </ButtonAnswer>
                </ButtonWrapper>
              );
            })}
          </AnswerOptions>
          {attribute?.left ? (
            <Attribute
              positionDesktop={position?.left.desktop}
              positionLaptop={position?.left.laptop}
              positionTablet={position?.left.tablet}
              positionTabletSmall={position?.left.tabletSmall}
              positionMobile={position?.left.mobile}
              positionMobileMedium={position?.left.mobileMedium}
              positionMobileSmall={position?.left.mobileSmall}
            >
              <Picture
                mobileSmall={{
                  src: attribute.left.bgImage.mobileS,
                  src2x: attribute.left.bgImage.mobileS2x,
                  webp: attribute.left.bgImageWebp.mobileS,
                  webp2x: attribute.left.bgImageWebp.mobileS2x,
                }}
              />
            </Attribute>
          ) : null}

          {attribute?.right ? (
            <Attribute
              positionDesktop={position?.right.desktop}
              positionLaptop={position?.right.laptop}
              positionTablet={position?.right.tablet}
              positionTabletSmall={position?.right.tabletSmall}
              positionMobile={position?.right.mobile}
              positionMobileMedium={position?.right.mobileMedium}
              positionMobileSmall={position?.right.mobileSmall}
            >
              <Picture
                mobileLarge={{
                  src: attribute?.right.bgImage.mobile,
                  src2x: attribute?.right.bgImage.mobile2x,
                  webp: attribute?.right.bgImageWebp.mobile,
                  webp2x: attribute?.right.bgImageWebp.mobile2x,
                }}
                mobileSmall={{
                  src: attribute.right.bgImage.mobileS,
                  src2x: attribute.right.bgImage.mobileS2x,
                  webp: attribute.right.bgImageWebp.mobileS,
                  webp2x: attribute.right.bgImageWebp.mobileS2x,
                }}
              />
            </Attribute>
          ) : null}
        </Inner>
        <WrongMessage wrong={wrong} isWrong={isWrong} />
        <RightMessage correct={correct} isRight={isRight} />
      </Content>
      <ButtonSlider>
        <ButtonNext className="slide-next" />
      </ButtonSlider>
      <ButtonSlider isLeft>
        <ButtonPrev className="slide-prev" />
      </ButtonSlider>
    </Component>
  );
}

const Component = styled.div`
  max-width: 1300px;
  width: 100%;
  margin: 0 auto;
  padding: 85px 0 56px;
  background: ${colors.white};
  box-shadow: 0 10.6667px 64px rgba(255, 255, 255, 0.32);
  border-radius: 32px;
  display: flex;
  justify-content: center;
  position: relative;
  
  @media (max-height: 820px) {
    padding: 60px 0 40px;
    margin: 0 auto 30px;
  }
  @media (max-height: 760px) {
    padding: 40px 0 40px;
    margin: 0 auto 20px;
    min-height: 403px;
  }
  @media (max-height: 690px) {
    margin: 0 auto;
  }
    

  ${media.laptop(css`
    max-width: 970px;
    padding: 64px 0 36px;
    @media (max-height: 760px) {
      padding: 40px 0 36px;
      box-shadow: 0 10.6667px 40px rgba(255, 255, 255, 0.32);
      margin: 15px auto 0;
    }
  `)}
  ${media.tabletSmall(css`
    max-width: 690px;
    padding: 32px 0 25px;
    margin: 0 auto;
    @media (max-height: 760px) {
      padding: 40px 0 40px;
      margin: 0 auto 20px;
      min-height: 100px;
    }
  `)}
  ${media.mobile(css`
    padding: 32px 0 48px;
    min-height: 300px;
  `)}
    ${media.mobileLarge(css`
      min-height: 360px;
    `)}
    ${media.mobileMedium(css`
      min-height: 460px;
    `)}
    ${media.mobileSmall(css`
      padding-bottom: 30px;
    `)}
`;

export const ButtonSlider = styled.div<{ isLeft?: boolean; color?: string }>`
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  display: flex;
  align-items: center;
  &:hover {
    box-shadow: 0 8px 35px rgba(255, 255, 255, 0.25);
  }
  ${(props) =>
    props.isLeft
      ? css`
          right: 0;
          left: 50%;
          border-radius: 0 32px 32px 0;
        `
      : css`
          left: 0;
          border-radius: 32px 0 0 32px;
        `}
  button {
    width: 100%;
    height: 100%;
    z-index: 9;

    ${media.tabletSmall(css`
      cursor: default;
    `)}
  }
`;

const Inner = styled.div<{ isAnswer: boolean }>`
${(props) =>
  props.isAnswer
    ? css`
        opacity: 0;
        visibility: hidden;
      `
    : css`
        opacity: 1;
        visibility: visible;
      `};
  position: relative;
  transition: all 0.4s ease-in-out;
  padding-bottom: 56px;
    @media (max-height: 760px) {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    }
  ${media.laptop(css`
    padding-bottom: 48px;
    @media (max-height: 720px) {
      padding-bottom: 30px;
    }
  `)}
  ${media.tabletSmall(css`
    padding-bottom: 29px;
  `)}
  ${media.mobile(css`
    padding-bottom: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `)}
`;

export const Content = styled.div`
  max-width: 1026px;
  width: 100%;
  position: relative;
  z-index: 2;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s linear .1s;
  ${media.laptop(css`
    max-width: 770px;
  `)}
  ${media.tabletSmall(css`
    max-width: 594px;
  `)}
    ${media.mobile(css`
      max-width: 100%;
      padding: 0 16px;
    `)}
`;

const Title = styled.p`
  font-weight: bold;
  font-size: 64px;
  line-height: 150%;
  color: ${colors.black400};
  margin-bottom: 92px;
  text-align: center;
  @media (max-height: 720px) {
    font-size: 56px;
    margin-bottom: 80px;
  }
  span {
    color: ${colors.red300};
  }
    @media (max-height: 760px) {
    font-size: 48px;
    margin-bottom: 44px;
    }

  ${media.laptop(css`
    font-size: 48px;
    margin-bottom: 69px;
    @media (max-height: 760px) {
      font-size: 44px;
      margin-bottom: 45px;
    }
    @media (max-height: 720px) {
      margin-bottom: 30px;
    }
  `)}

  ${media.tabletSmall(css`
    font-size: 36px;
    margin-bottom: 73px;
  `)}
  
   ${media.mobile(css`
     font-size: 26px;
     margin-bottom: auto;
   `)}
    ${media.mobileMedium(css`
      font-size: 20px;
      margin-bottom: auto;
    `)}
  ${media.mobileSmall(css`
    font-size: 18px;
  `)}
`;

const AnswerOptions = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  z-index: 5;
`;

const ButtonWrapper = styled.div`
  width: 173px;
  height: 79px;
  margin: 0 32px;
  
  button {
    font-weight: bold;
    font-size: 32px;
    line-height: 46px;
    color: ${colors.black400};
    border-radius: 26.6667px;
    transition: all 0.3s ease-in-out;

  }

  ${media.laptop(css`
    width: 130px;
    height: 59px;
    margin: 0 24px;
    button {
      font-size: 24px;
      line-height: 35px;
      border-radius: 20px;
    }
  `)}
  ${media.tabletSmall(css`
    width: 104px;
    height: 53px;
    margin: 0 15px;
    button {
      font-size: 20px;
      line-height: 29px;
    }
  `)}
    ${media.tabletSmall(css`
      width: 69px;
      height: 41px;
      margin: 0 8px;
      button {
        font-size: 12px;
        line-height: 17px;
        border-radius: 16px;
      }
    `)}
`;

export const Attribute = styled.div<{
  positionDesktop?: position;
  positionLaptop?: position;
  positionTablet?: position;
  positionTabletSmall?: position;
  positionMobile?: position;
  positionMobileMedium?: position;
  positionMobileSmall?: position;
}>`
  ${(props) => css`
    position: absolute;
    top: ${props.positionDesktop ? props.positionDesktop.top : '40px'};
    left: ${props.positionDesktop ? props.positionDesktop.left : '-340px'};
    right: ${props.positionDesktop ? props.positionDesktop.right : '40px'};
    bottom: ${props.positionDesktop ? props.positionDesktop.bottom : '-340px'};
    z-index: ${props.positionDesktop?.zIndex
      ? props.positionDesktop.zIndex
      : '1'};
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s linear 0.4s;
    img {
      width: ${props.positionDesktop ? props.positionDesktop.width : '647'}px;
      height: ${props.positionDesktop ? props.positionDesktop.height : '647'}px;
      position: relative;
      z-index: 4;
    }
    ${media.laptop(css`
      top: ${props.positionLaptop ? props.positionLaptop.top : '40px'};
      left: ${props.positionLaptop ? props.positionLaptop.left : '-340px'};
      right: ${props.positionLaptop ? props.positionLaptop.right : '40px'};
      bottom: ${props.positionLaptop ? props.positionLaptop.bottom : '-340px'};
      z-index: ${props.positionLaptop?.zIndex
        ? props.positionLaptop.zIndex
        : '1'};
      img {
        width: ${props.positionLaptop ? props.positionLaptop.width : '647'}px;
        height: ${props.positionLaptop ? props.positionLaptop.height : '647'}px;
      }
    `)}
    ${media.tablet(css`
      top: ${props.positionTablet ? props.positionTablet.top : '40px'};
      left: ${props.positionTablet ? props.positionTablet.left : '-340px'};
      right: ${props.positionTablet ? props.positionTablet.right : '40px'};
      bottom: ${props.positionTablet ? props.positionTablet.bottom : '-340px'};
      z-index: ${props.positionTablet?.zIndex
        ? props.positionTablet.zIndex
        : '1'};
      img {
        width: ${props.positionTablet ? props.positionTablet.width : '647'}px;
        height: ${props.positionTablet ? props.positionTablet.height : '647'}px;
      }
    `)};
    ${media.tabletSmall(css`
      top: ${props.positionTabletSmall
        ? props.positionTabletSmall.top
        : '40px'};
      left: ${props.positionTabletSmall
        ? props.positionTabletSmall.left
        : '-340px'};
      right: ${props.positionTabletSmall
        ? props.positionTabletSmall.right
        : '40px'};
      bottom: ${props.positionTabletSmall
        ? props.positionTabletSmall.bottom
        : '-340px'};
      z-index: ${props.positionTabletSmall?.zIndex
        ? props.positionTabletSmall.zIndex
        : '1'};
      img {
        width: ${props.positionTabletSmall
          ? props.positionTabletSmall.width
          : '647'}px;
        height: ${props.positionTabletSmall
          ? props.positionTabletSmall.height
          : '647'}px;
      }
    `)};
    ${media.mobile(css`
      top: ${props.positionMobile ? props.positionMobile.top : '40px'};
      left: ${props.positionMobile ? props.positionMobile.left : '-340px'};
      right: ${props.positionMobile ? props.positionMobile.right : '40px'};
      bottom: ${props.positionMobile ? props.positionMobile.bottom : '-340px'};
      z-index: ${props.positionMobile?.zIndex
        ? props.positionMobile.zIndex
        : '1'};
      img {
        width: ${props.positionMobile ? props.positionMobile.width : '647'}px;
        height: ${props.positionMobile ? props.positionMobile.height : '647'}px;
      }
    `)};
    ${media.mobileMedium(css`
      top: ${props.positionMobileMedium
        ? props.positionMobileMedium.top
        : '40px'};
      left: ${props.positionMobileMedium
        ? props.positionMobileMedium.left
        : '-340px'};
      right: ${props.positionMobileMedium
        ? props.positionMobileMedium.right
        : '40px'};
      bottom: ${props.positionMobileMedium
        ? props.positionMobileMedium.bottom
        : '-340px'};
      z-index: ${props.positionMobileMedium?.zIndex
        ? props.positionMobileMedium.zIndex
        : '1'};
      img {
        width: ${props.positionMobileMedium
          ? props.positionMobileMedium.width
          : '647'}px;
        height: ${props.positionMobileMedium
          ? props.positionMobileMedium.height
          : '647'}px;
      }
    `)};
    ${media.mobileSmall(css`
      top: ${props.positionMobileSmall
        ? props.positionMobileSmall.top
        : '40px'};
      left: ${props.positionMobileSmall
        ? props.positionMobileSmall.left
        : '-340px'};
      right: ${props.positionMobileSmall
        ? props.positionMobileSmall.right
        : '40px'};
      bottom: ${props.positionMobileSmall
        ? props.positionMobileSmall.bottom
        : '-340px'};
      z-index: ${props.positionMobileSmall?.zIndex
        ? props.positionMobileSmall.zIndex
        : '1'};
      img {
        width: ${props.positionMobileSmall
          ? props.positionMobileSmall.width
          : '647'}px;
        height: ${props.positionMobileSmall
          ? props.positionMobileSmall.height
          : '647'}px;
      }
    `)};
  `}
`;

const ButtonAnswer = styled(Button)``;
const ButtonNext = styled(Button)``;
const ButtonPrev = styled(Button)``;
export default QuizSlide;
