import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { ReactComponent as LogoFooter } from '@/assets/svg/logo-footer.svg';

function FooterLogo() {
  return (
    <LogoWrapper>
      <LogoFooter />
    </LogoWrapper>
  );
}

const LogoWrapper = styled.a`
  margin-bottom: 11px;
  display: flex;
  justify-content: center;
  ${media.laptop(css`
    margin-bottom: 8px;
    svg {
      width: 70px;
      height: 32px;
    }
  `)}
  ${media.tabletSmall(css`
    margin-bottom: 12px;
  `)}
  ${media.mobile(css`
    margin-top: 24px;
  `)}
  ${media.mobileSmall(css`
    margin-bottom: 6px;
  `)}
`;

export default FooterLogo;
