import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function CourseLauchBlock() {
  return (
    <Wrapper>
      <Title>Course lauch date </Title>
      <Date>
        <DateItem>
          <Text>09</Text>
        </DateItem>
        <DateItem>
          <Text>30</Text>
        </DateItem>
        <DateItem>
          <Text>20</Text>
        </DateItem>
      </Date>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const Title = styled.span`
  display: block;
  font-weight: bold;
  font-size: 32px;
  line-height: 150%;
  text-align: center;
  color: ${colors.grayDark};
  margin-bottom: 21px;
  ${media.laptop(css`
    font-size: 24px;
    margin-bottom: 16px;
  `)}
  ${media.mobile(css`
    font-size: 16px;
  `)}
  ${media.mobileSmall(css`
    font-size: 14px;
  `)}
`;

const Date = styled.div`
  display: flex;
  justify-content: center;
  max-width: 570px;
  width: 100%;
  margin: 0 auto;
  padding: 28px 9px;
  background: ${colors.white};
  border-radius: 32px;
  box-shadow: 0 0 85.3333px rgba(255, 255, 255, 0.06);

  @media (max-height: 800px) {
    max-width: 407px;
    padding: 21px 4px;
    border-radius: 24px;
  }

  ${media.laptop(css`
    max-width: 407px;
    padding: 21px 4px;

    box-shadow: 0 0 64px rgba(255, 255, 255, 0.06);
  `)}
  ${media.mobileMedium(css`
    max-width: 280px;
    padding: 12px 7px;
  `)}
`;

const DateItem = styled.div`
  width: 150px;
  height: 128px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)),
    linear-gradient(266.25deg, #fd2828 0%, #ff1c2f 100%);
  box-shadow: 0 5.33333px 32px rgba(255, 28, 47, 0.5);
  border-radius: 32px;
  font-weight: bold;
  font-size: 64px;
  line-height: 150%;
  margin: 0 17.5px;
  color: ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-height: 800px) {
    width: 112px;
    height: 96px;
    border-radius: 24px;
    font-size: 48px;
    margin: 0 8px;
  }
  ${media.laptop(css`
    width: 112px;
    height: 96px;
    border-radius: 24px;
    font-size: 48px;
    margin: 0 8px;
  `)}
  ${media.mobile(css`
    width: 76px;
    height: 60px;
    border-radius: 16px;
    font-size: 24px;
    margin: 0 6px;
  `)}
`;

const Text = styled.span``;

export default CourseLauchBlock;
