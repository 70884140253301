import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { ModalProps } from '@tager/web-components';
import { ReactComponent as CloseIcon } from '@/assets/svg/close-popup-white.svg';

type Props = ModalProps;

function TeaserModals({ closeModal }: Props) {
  return (
    <Inner>
      <Wrapper>
        <CloseBtn onClick={closeModal}>
          <CloseIcon />
        </CloseBtn>
        <iframe
          src="//www.youtube.com/embed/MdCZS15UCkw"
          frameBorder="0"
          allow="accelerometer; fullscreen"
          allowFullScreen
        ></iframe>
      </Wrapper>
    </Inner>
  );
}

const Inner = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 1000;
  ${media.tabletSmall(css`
    height: auto;
    margin: auto 0;
    top: 50%;
    transform: translateY(-50%);
  `)}
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  ${media.tabletSmall(css`
    padding-bottom: 60%;
    height: auto;
  `)}
  iframe {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 60px;
  right: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  transition: 0.3s ease;
  z-index: 100;
  ${media.mobileMedium(css`
    top: 0px;
    right: 0px;
  `)}

  svg {
    width: 34px;
    height: 34px;
    transition: all 0.3s linear;
    ${media.laptop(css`
      width: 26px;
      height: 26px;
    `)}
    ${media.tabletSmall(css`
      width: 24px;
      height: 24px;
    `)}
    ${media.mobile(css`
      width: 16px;
      height: 16px;
    `)}
    ${media.mobileSmall(css`
      width: 12px;
      height: 12px;
    `)}
  }

  &:hover {
    svg {
      fill: ${colors.redInvalid};
    }
  }
`;

export default TeaserModals;
