import React from 'react';
import styled, { css } from 'styled-components';
import { useModal } from '@tager/web-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { ReactComponent as PlayIcon } from '@/assets/svg/play.svg';

import { useLayoutContext } from '@/components/Layout/LayoutCtx';
import TeaserModals from '@/components/modals/TeaserModals';
import SectionTop from '@/components/SectionTop';
import Picture from '@/components/Picture';
import Button from '@/components/Button';

import { TEASER_BACKGROUND } from '@/modules/Home/Home.constants';

function Teaser() {
  const openModal = useModal();

  const { bgImage, bgImageWebp } = TEASER_BACKGROUND;

  function openTeaserModal() {
    openModal(TeaserModals, {});
  }

  const { arrayRef } = useLayoutContext();
  const { teaserRef } = arrayRef;

  return (
    <Wrapper className="section" ref={teaserRef}>
      <Inner>
        <SectionTop title="What to Expect" />
        <Content>
          <ImageWrapper>
            {bgImage && bgImageWebp ? (
              <Picture
                desktop={{
                  src: bgImage.desktop,
                  src2x: bgImage.desktop2x,
                  webp: bgImageWebp.desktop,
                  webp2x: bgImageWebp.desktop2x,
                }}
                tabletLarge={{
                  src: bgImage.tablet,
                  src2x: bgImage.tablet2x,
                  webp: bgImageWebp.tablet,
                  webp2x: bgImageWebp.tablet2x,
                }}
                mobileLarge={{
                  src: bgImage.smallTablet,
                  src2x: bgImage.smallTablet2x,
                  webp: bgImageWebp.smallTablet,
                  webp2x: bgImageWebp.smallTablet2x,
                }}
                mobileSmall={{
                  src: bgImage.mobileS,
                  src2x: bgImage.mobileS2x,
                  webp: bgImageWebp.mobileS,
                  webp2x: bgImageWebp.mobileS2x,
                }}
              />
            ) : null}
            <ButtonWrapper onClick={openTeaserModal}>
              <Button>
                <PlayIcon />
              </Button>
            </ButtonWrapper>
          </ImageWrapper>
        </Content>
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  flex: 1 1 auto;
  background: linear-gradient(180deg, #030307 0%, #000000 100%);
  .fp-tableCell {
    vertical-align: bottom;
  }
`;

const Inner = styled.div`
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1300px;
  margin: 0 auto;
  @media (max-height: 1060px) {
  }
  @media (max-height: 1000px) {
  }
  @media (max-height: 960px) {
    max-width: 1100px;
  }
  @media (max-height: 860px) {
    max-width: 1000px;
  }
  @media (max-height: 800px) {
    max-width: 950px;
  }
  @media (max-height: 760px) {
    max-width: 850px;
  }
  @media (max-height: 690px) {
    max-width: 780px;
  }

  ${media.laptop(css`
    max-width: 67.4%;
  @media (max-height: 800px) {
  } 
  @media (max-height: 760px) {
    max-width: 850px;
  }
  @media (max-height: 690px) {
    max-width: 780px;
  `)}
  ${media.tabletSmall(css`
    max-width: 690px;
  `)}
  ${media.mobile(css`
    display: block;
    max-width: 87.5%;
    height: auto;
    padding: 80px 0;
  `)}

  ${media.mobileMedium(css`
    //padding: 80px 0 86px;
  `)}
`;

const Content = styled.div`
  width: 100%;
  padding-bottom: 55.9%;
  position: relative;

  ${media.laptop(css`
    padding-bottom: 56.2%;
  `)}

  ${media.mobile(css`
    padding-bottom: 60%;
  `)}
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    height: 128px;
    background: rgba(36, 36, 36, 0.75);
    backdrop-filter: blur(16px);
    border-radius: 100px;
    transition: all 0.3s ease;
    svg {
      width: 42.67px;
      height: 42.67px;
      position: relative;
      right: -5px;
    }
  }
  ${media.laptop(css`
    button {
      width: 96px;
      height: 96px;
      svg {
        width: 32px;
        height: 32px;
        right: -3px;
      }
    }
  `)}
  ${media.tabletSmall(css`
    cursor: default;
    button {
      cursor: default;
    }
  `)}
  ${media.mobile(css`
    button {
      width: 48px;
      height: 48px;
      border-radius: 225px;
      svg {
        width: 18px;
        height: 18px;
        right: -2px;
      }
    }
  `)}
    &:hover {
    button {
      background-color: ${colors.redInvalid};
      opacity: 0.8;
    }
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 32px;
  overflow: hidden;
  ${media.laptop(css`
    border-radius: 24px;
  `)}
  img {
    width: 100%;
    height: 100%;
  }
`;

export default Teaser;
