import { request } from '@tager/web-core';

export type PreorderFormPayload = {
  name: string;
  email: string;
};

export function sendContactForm(
  payload: PreorderFormPayload
): Promise<PreorderFormPayload> {
  return request.post({
    path: '/leads/preorder',
    body: payload,
  });
}
