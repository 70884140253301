import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Button from '@/components/Button';

type Props = {
  isRight: boolean;
  correct?: string;
};
function RightMessage({ isRight, correct }: Props) {
  return (
    <Wrapper isRight={isRight}>
      <Top>
        <Title>Yep! You’re right! 🎉</Title>
        <Text>{correct}</Text>
      </Top>
      <ButtonWrapper>
        <ButtonNext variant="secondary" className="slide-next">
          Next question please <span>😎</span>
        </ButtonNext>
      </ButtonWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ isRight: boolean }>`
  transition: all 0.4s ease-in-out;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${(props) =>
    props.isRight
      ? css`
          opacity: 1;
          visibility: visible;
        `
      : css`
          opacity: 0;
          visibility: hidden;
        `}
  

  ${media.mobile(css`
    margin: 0 20px;
    justify-content: flex-start;
  `)}
  ${media.mobileSmall(css`
    margin: 0 16px;
  `)}
`;

const Top = styled.div`
  color: ${colors.black400};
  margin-bottom: auto;
  @media (max-height: 720px) {
    margin-bottom: auto;
  }
  ${media.laptop(css`
    margin-bottom: auto;
  `)}
  ${media.tabletSmall(css`
    margin-bottom: auto;
  `)}
    ${media.mobile(css`
      margin-bottom: auto;
      flex: 1 1 1px;
      display: flex;
      flex-direction: column;
    `)}
`;

const Title = styled.span`
  display: block;
  font-weight: bold;
  font-size: 64px;
  line-height: 150%;
  margin-bottom: 43px;
  text-align: center;
  @media (max-height: 1050px) {
    font-size: 58px;
    margin-bottom: 25px;
  }
  @media (max-height: 760px) {
    font-size: 40px;
    margin-bottom: 10px;
  }
  ${media.laptop(css`
    line-height: 120%;
    font-size: 36px;
    margin-bottom: 10px;
    @media (max-height: 820px) {
      font-size: 38px;
      margin-bottom: 10px;
    }
  `)}
  ${media.tabletSmall(css`
    line-height: 120%;
    font-size: 36px;
    margin-bottom: 12px;
  `)}
   ${media.mobile(css`
     font-size: 24px;
     flex: 0 0 28px;
   `)}
   ${media.mobileSmall(css`
     font-size: 18px;
     flex: 0 0 21px;
   `)}
  

`;
const Text = styled.p`
  font-weight: 600;
  font-size: 26px;
  line-height: 150%;
  @media (max-height: 1000px) {
    font-size: 26px;
  }
  @media (max-height: 760px) {
    font-size: 22px;
    text-align: center;
  }
  ${media.laptop(css`
    font-size: 22px;
    text-align: center;
    @media (max-height: 860px) {
      font-size: 20px;
    }
  `)}
  ${media.tabletSmall(css`
    font-size: 18px;
  `)}
  ${media.mobile(css`
    font-size: 16px;
    flex: 1 1 1px;
    display: flex;
    align-items: center;
  `)}
  ${media.mobileMedium(css`
    font-size: 16px;
  `)}
    ${media.mobileSmall(css`
      font-size: 15px;
    `)}

`;

const ButtonWrapper = styled.div`
  width: 502px;
  height: 72px;
  margin: 0 auto;

  ${media.laptop(css`
    width: 358px;
    height: 59px;
  `)}
  ${media.tabletSmall(css`
    width: 315px;
    height: 53px;
  `)}
  @media (max-width: 800px) {
    width: 204px;
    height: 42px;
  }
  ${media.mobile(css`
    width: 204px;
    height: 42px;
    flex: 0 0 42px;
  `)}
  ${media.mobileSmall(css`
    margin-bottom: 10px;
  `)}
`;

const ButtonNext = styled(Button)`
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 32px;
  line-height: 46px;
  color: ${colors.black400};
  border-radius: 133.333px;
  transition: all 0.3s ease-in-out;

  span {
    padding-left: 13px;
  }
  @media (max-height: 720px) {
    font-size: 28px;
  }
  ${media.laptop(css`
    font-size: 24px;
    line-height: 35px;
    border-radius: 100px;
    span {
      padding-left: 10px;
    }
  `)}
  @media (max-width: 800px) {
    font-size: 16px;
    line-height: 22px;
    span {
      padding-left: 6px;
    }
  }
  ${media.mobile(css`
    font-size: 16px;
    line-height: 22px;
    span {
      padding-left: 6px;
    }
  `)}
  ${media.mobileSmall(css`
    font-size: 12px;
    line-height: 17px;
  `)}
`;
export default RightMessage;
