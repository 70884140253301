import { createContextHookWithProvider } from '@tager/web-core';
import { RefObject } from 'react';

export type LayoutContext = {
  arrayRef: {
    heroRef: RefObject<HTMLDivElement>;
    questionsRef: RefObject<HTMLDivElement>;
    teaserRef: RefObject<HTMLDivElement>;
    answersRef: RefObject<HTMLDivElement>;
    teamRef: RefObject<HTMLDivElement>;
    statementsRef: RefObject<HTMLDivElement>;
    preorderRef: RefObject<HTMLDivElement>;
  };
  isFixed: boolean;
  setFixed: (isFixed: boolean) => void;
};

const [useCtx, ctxProvider] = createContextHookWithProvider<LayoutContext>(
  'HeroContext'
);

export const useLayoutContext = useCtx;
export const LayoutProvider = ctxProvider;
