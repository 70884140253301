import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { Preloader } from '@tager/web-components';

import Header from './components/Header';
import { LayoutProvider } from './LayoutCtx';

type Props = {
  children?: React.ReactNode;
  isPreloaderHidden?: boolean;
};

function Layout({ children, isPreloaderHidden }: Props) {
  const [isFixed, setFixed] = useState(false);
  const heroRef = useRef<HTMLDivElement>(null);
  const questionsRef = useRef<HTMLDivElement>(null);
  const teaserRef = useRef<HTMLDivElement>(null);
  const answersRef = useRef<HTMLDivElement>(null);
  const teamRef = useRef<HTMLDivElement>(null);
  const statementsRef = useRef<HTMLDivElement>(null);
  const preorderRef = useRef<HTMLDivElement>(null);

  function handleNavigationFixed(isFixed: boolean) {
    setFixed(isFixed);
  }

  return (
    <Container>
      <LayoutProvider
        value={{
          arrayRef: {
            heroRef: heroRef,
            questionsRef: questionsRef,
            teaserRef: teaserRef,
            answersRef: answersRef,
            teamRef: teamRef,
            statementsRef: statementsRef,
            preorderRef: preorderRef,
          },
          isFixed,
          setFixed: handleNavigationFixed,
        }}
      >
        <StyledPreloader />

        <Header />
        <Main>{children}</Main>
      </LayoutProvider>
    </Container>
  );
}

const Container = styled.div`
  background-color: ${colors.black};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;

const StyledPreloader = styled(Preloader)`
  &[data-preloader-overlay] {
    background-color: black;
  }

  [data-preloader-item] {
    background-color: rgb(253, 40, 40);
  }

  ${media.tabletSmall(css`
    display: none;
  `)}
`;

export default Layout;
