import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import ContentContainer from '@/components/ContentContainer';

import { FAQ_LIST_ITEM } from '../../Home.constants';

import FaqItem from './components/FaqItem';

function Faq() {
  return (
    <Wrapper>
      <Inner>
        <ContentContainer>
          <Title>FAQ</Title>
          <Content>
            <List>
              {FAQ_LIST_ITEM.map((faqItem, index) => {
                return (
                  <ItemCell key={index}>
                    <FaqItem faqItem={faqItem} />
                  </ItemCell>
                );
              })}
            </List>
          </Content>
        </ContentContainer>
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  flex: 1 1 auto;
  position: relative;
`;

const Inner = styled.div`
  background: linear-gradient(180deg, #030307 0%, #000000 100%);
  display: flex;
  align-items: center;
  height: 100vh;
  padding-top: 50px;
  ${media.tabletSmall(css`
    height: auto;
    padding: 100px 0 50px;
  `)}
  ${media.mobileLarge(css`
    padding: 80px 0;
  `)}
`;

const Title = styled.h2`
  font-size: 96px;
  line-height: 150%;
  color: ${colors.white};
  text-align: center;
  margin-bottom: 44px;
  @media (max-height: 820px) {
    margin-bottom: 30px;
  }
  @media (max-height: 880px) {
  font-size: 80px;
  }
  @media (max-height: 740px) {
    font-size: 70px;
  }
  @media (max-height: 710px) {
    font-size: 60px;
  }
  ${media.laptop(css`
    font-size: 72px;
    @media (max-height: 820px) {
      margin-bottom: 20px;
    }
    @media (max-height: 720px) {
      font-size: 60px;
    }
  `)}
    ${media.tablet(css`
      @media (max-height: 760px) {
        font-size: 54px;
        margin-bottom: 32px;
      }
      @media (max-height: 650px) {
        font-size: 48px;
        margin-bottom: 32px;
      }
    `)}
  ${media.tabletSmall(css`
    font-size: 54px;
    margin-bottom: 32px;
  `)}
    ${media.mobile(css`
      font-size: 32px;
      margin-bottom: 24px;
    `)}
    ${media.mobileSmall(css`
      font-size: 21px;
    `)}
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: -24px -61.5px;
  max-height: 650px;
  height: 100%;
  @media (max-height: 850px) {
  max-height: 620px;
  }
  @media (max-height: 760px) {
    max-height: 500px;
  }
  ${media.laptop(css`
    max-height: 550px;
    margin: -10px -51px;
    @media (max-height: 720px) {
      max-height: 500px;
    }
  `)}
  ${media.tablet(css`
    max-height: 650px;
    margin: -24px -20px;
    @media (max-height: 700px) {
      max-height: 500px;
    }
  `)}
  ${media.tabletSmall(css`
    flex-wrap: nowrap;
    margin: -16px 0;
    max-height: 100%;
  `)}
  ${media.mobile(css`
    margin: -15px 0;
  `)}
  ${media.mobileSmall(css`
    margin: -10px 0;
  `)}
`;

const ItemCell = styled.div`
  flex: 0 0 50%;
  max-width: 50%;
  padding: 24px 61.5px;
  @media (max-height: 820px) {
    padding: 16px 61.5px;
  }
  ${media.laptop(css`
    padding: 10px 50px;
  `)}
  ${media.tablet(css`
    padding: 15px 20px;
    @media (max-height: 650px) {
      padding: 12px 20px;
    }
  `)}
  ${media.tabletSmall(css`
    flex: 0 0 100%;
    max-width: 100%;
    padding: 16px 0;
  `)}
    ${media.mobile(css`
      padding: 15px 0;
    `)}
  ${media.mobileSmall(css`
    padding: 10px 0;
  `)}
`;

export default Faq;
