export type RootMenuItemType = {
  label: string;
  number: number;
  link: string;
};

export const HEADER_MENU_LIST: Array<RootMenuItemType> = [
  {
    label: 'Home',
    number: 0,
    link: '#home',
  },
  {
    label: 'Quizz',
    number: 1,
    link: '#quizz',
  },
  {
    label: 'Teaser',
    number: 2,
    link: '#teaser',
  },
  {
    label: 'Examples',
    number: 3,
    link: '#examples',
  },
  {
    label: 'Team',
    number: 4,
    link: '#team',
  },
  {
    label: 'Offer',
    number: 5,
    link: '#offer',
  },
  {
    label: 'Preorder',
    number: 6,
    link: '#preorder',
  },
];
