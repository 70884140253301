import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import Picture from '@/components/Picture';
import ContentContainer from '@/components/ContentContainer';

import { STRUCTURE_BACKGROUND } from '../../Home.constants';
import { useLayoutContext } from '@/components/Layout/LayoutCtx';

function Structure() {
  const { bgImage, bgImageWebp } = STRUCTURE_BACKGROUND;
  const { arrayRef } = useLayoutContext();
  const { teamRef } = arrayRef;

  return (
    <Wrapper className="section" ref={teamRef}>
      <ContentContainer>
        <Inner>
          <ImageWrapper>
            <ButtonWrapper>
              <StructureButton>Created by ex-MBB consultants</StructureButton>
            </ButtonWrapper>
            <BackgroundImage>
              {bgImage && bgImageWebp ? (
                <Picture
                  desktop={{
                    src: bgImage.desktop,
                    src2x: bgImage.desktop2x,
                    webp: bgImageWebp.desktop,
                    webp2x: bgImageWebp.desktop2x,
                  }}
                  tabletLarge={{
                    src: bgImage.tablet,
                    src2x: bgImage.tablet2x,
                    webp: bgImageWebp.tablet,
                    webp2x: bgImageWebp.tablet2x,
                  }}
                  mobileLarge={{
                    src: bgImage.smallTablet,
                    src2x: bgImage.smallTablet2x,
                    webp: bgImageWebp.smallTablet,
                    webp2x: bgImageWebp.smallTablet2x,
                  }}
                  mobileSmall={{
                    src: bgImage.mobileS,
                    src2x: bgImage.mobileS2x,
                    webp: bgImageWebp.mobileS,
                    webp2x: bgImageWebp.mobileS2x,
                  }}
                />
              ) : null}
            </BackgroundImage>
          </ImageWrapper>
        </Inner>
      </ContentContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  flex: 1 1 auto;
  background: ${colors.black600};

  .fp-tableCell {
    vertical-align: bottom;
  }
  ${media.mobile(css`
    padding: 80px 0;
  `)}
`;
const Inner = styled.div`
  height: calc(100vh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  ${media.mobile(css`
    height: auto;
    display: block;
  `)}
`;
const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  padding-top: 43%;
  border: 1.33333px solid rgba(255, 255, 255, 0.3);
  border-radius: 32px;
  overflow: hidden;
  @media (max-height: 900px) {
    padding-top: 35%;
    max-width: 70%;
  }
  @media (max-height: 720px) {
    max-width: 60%;
  }
    @media (max-height: 680px) {
    max-width: 60%;
    padding-top: 30%;
  }
  ${media.laptop(css`
    border-radius: 24px;
    @media (max-height: 900px) {
      padding-top: 40%;
      max-width: 80%;
    }
    @media (max-height: 720px) {
      max-width: 65%;
    }
    @media (max-height: 680px) {
      padding-top: 38%;
    }
  `)}
  ${media.tabletSmall(css`
    max-width: 100%;
    width: 100%;
    padding-top: 60%;
    border: 0;
    margin: 0 auto;
    @media (max-height: 720px) {
      max-width: 100%;
      margin: 0 auto;
    }
  `)}
  ${media.mobileMedium(css`
    padding-top: 91.5%;
  `)}
  ${media.mobileSmall(css`
    border-right: 0;
    overflow: visible;
  `)}
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  ${media.mobileSmall(css`
    left: -20px;
    right: -20px;
  `)}
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 6;
  background: ${colors.white};
  border-radius: 73.3333px;
  width: 744px;
  height: 128px;
  @media (max-height: 900px) {
    width: 558px;
    height: 96px;
  }

  ${media.laptop(css`
    width: 558px;
    height: 96px;
    border-radius: 55px;
    @media (max-height: 750px) {
      width: 460px;
      height: 84px;
    }
  `)}
  ${media.mobile(css`
    width: 280px;
    height: 60px;
  `)}
`;

const StructureButton = styled.div`
  font-weight: bold;
  font-size: 42.6667px;
  line-height: 150%;
  color: ${colors.black700};
  @media (max-height: 900px) {
    font-size: 32px;
  }
  ${media.laptop(css`
    font-size: 32px;
    @media (max-height: 750px) {
      font-size: 24px;
    }
  `)}
  ${media.mobile(css`
    font-size: 16px;
  `)}
`;

export default Structure;
