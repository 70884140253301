import React, { useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import Swiper from 'swiper';

import { media } from '@/utils/mixin';

import { useLayoutContext } from '@/components/Layout/LayoutCtx';
import { PREORDER_DESCRIPTION } from '@/modules/Home/Home.constants';

import PreorderSlide from './components/Slide/PreorderSlide';
import FirstSlide from './components/Slide/FirstSlide';
import { colors } from '@/constants/theme';

function CourseDate() {
  const containerRef = useRef<HTMLDivElement>(null);
  const { arrayRef } = useLayoutContext();
  const { statementsRef } = arrayRef;

  useEffect(() => {
    if (containerRef.current) {
      const swiper = new Swiper(containerRef.current, {
        slidesPerView: 1,
        speed: 600,
        pagination: {
          el: '.swiper-date-pagination',
          type: 'bullets',
          clickable: true,
        },
      });
      return () => swiper.destroy(true, true);
    }
  }, []);

  return (
    <Wrapper className="section" ref={statementsRef}>
      <Inner>
        <Container className="swiper-container" ref={containerRef}>
          <List className="swiper-wrapper">
            {PREORDER_DESCRIPTION.map((preorderItem, index) => {
              if (preorderItem.isFirstSlide) {
                return (
                  <CardCell key={index} className="swiper-slide">
                    <FirstSlide />
                  </CardCell>
                );
              } else {
                return (
                  <CardCell key={index} className="swiper-slide">
                    <PreorderSlide preorderItem={preorderItem} />
                  </CardCell>
                );
              }
            })}
          </List>
        </Container>
        <SliderPreorderPagination className="swiper-date-pagination" />
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  background: ${colors.black600};
  width: 100%;
  flex: 1 1 1px;
  ${media.mobile(css`
    padding: 80px 0;
  `)}
`;

const Inner = styled.div`
  height: calc(100% - 100px);
  width: 100vw;
  position: relative;
  ${media.mobile(css`
    height: auto;
  `)}
`;

const Container = styled.div`
  height: 100%;
`;

const List = styled.div`
  display: flex;
  align-items: center;
`;

const CardCell = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${media.mobile(css`
    margin: 50px auto;
  `)}
  // ${media.mobileMedium(css`
    //   margin: 40px auto 80px;
    //
  `)}
  //   ${media.mobileMedium(css`
    //     margin: 40px auto 120px;
    //
  `)}
`;

const SliderPreorderPagination = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 60px;
  height: 28px;
  z-index: 7;
  @media (max-height: 970px) {
    bottom: 80px;
  }
  @media (max-height: 940px) {
     bottom: 40px;
  }
  @media (max-height: 820px) {
     bottom: 20px;
  }
  @media ( max-height: 700px ) {
    bottom: 0;
  }
  .swiper-pagination-bullet {
    width: 18px;
    height: 18px;
    background: ${colors.white};
    border-radius: 100px;
    opacity: 1;
    transition: all 0.3s linear;
    margin: 0 13px;

    &-active {
      width: 28px;
      height: 28px;
      border: 5px solid ${colors.white};
      background: transparent;
      //transform: rotate(360deg);
    }
    &:hover {
    opacity: 0.6;
    }
  }
    
  ${media.laptop(css`
    bottom: 120px !important;
    height: 24px;

    @media (max-height: 850px) {
      bottom: 70px !important;
    }
    @media (max-height: 780px) {
      bottom: 50px !important;
    }
    @media (max-height: 730px) {
      bottom: 0 !important;
    }

    .swiper-pagination-bullet {
      width: 14px;
      height: 14px;
      border-radius: 10px;
      margin: 0 10px;
      &-active {
        width: 24px;
        height: 24px;
        border: 4px solid ${colors.white};
        border-radius: 12px;
      }
    }
  `)}
  ${media.tablet(css`
    bottom: 40px !important;
    @media (max-height: 700px) {
      bottom: 20px !important;
    }
  `)}

  ${media.tabletSmall(css`
    bottom: 0 !important;

    .swiper-pagination-bullet {
      cursor: default;
    }
  `)}
  ${media.mobile(css`
    bottom: 60px !important;
    z-index: 1;
  `)}
  
  ${media.mobileLarge(css`
    bottom: 0px !important;
  `)}
  
  ${media.mobileSmall(css`
    bottom: 80px !important;
    z-index: 1;
    height: 14px;
    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      margin: 0 10px;

      &-active {
        width: 14px;
        height: 14px;
        border: 3px solid ${colors.white};
        border-radius: 10px;
      }
    }
  `)}
`;

export default CourseDate;
