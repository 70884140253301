import React, { RefObject } from 'react';
import styled, { css } from 'styled-components';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import Button, { ButtonLink } from '@/components/Button';
import { scrollToElement } from '@/utils/common';
import { ButtonSlider } from '@/modules/Home/components/Quiz/components/QuizSlide';

type Props = {
  teaserRef: RefObject<HTMLDivElement>;
};

function LastSlide({ teaserRef }: Props) {
  return (
    <Wrapper>
      <ContentLastSlider>
        <Title>
          Learn how to solve these and other real case interview questions
          structurally and constistently.
        </Title>
        <Text>
          Our full video course will boost your chances of successfully passing
          case interviews and receiving a job offer.
        </Text>
        <ButtonWrapper>
          <ButtonLink
            href="#teaser"
            variant="primary-second"
            onClick={() => {
              scrollToElement(teaserRef.current, { offset: 0 });
            }}
          >
            Watch our teaser
          </ButtonLink>
        </ButtonWrapper>
      </ContentLastSlider>
      <ButtonSlider>
        <Button className="slide-next"></Button>
      </ButtonSlider>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  min-height: 560px;
  height: 100%;
  @media (max-height: 820px) {
    min-height: 519px;
    height: 100%;
    margin-bottom: 30px;
  }
  @media (max-height: 760px) {
    min-height: 403px;
    margin-bottom: 10px;
  }
  @media (max-height: 690px) {
    margin-bottom: 0;
  }
  ${media.laptop(css`
    min-height: 420px;
    width: 100%;
    @media (max-height: 760px) {
      align-items: center;
      min-height: 403px;
      margin: 15px auto 0;
    }
  `)}
  ${media.tabletSmall(css`
    max-height: 308px;
    min-height: 308px;
    @media (max-height: 900px) {
      min-height: 331px;
      max-height: 331px;
      margin-bottom: 15px;
    }
  `)}
    ${media.mobile(css`
      min-height: 300px;
    `)}
  ${media.mobileMedium(css`
    min-height: 460px;
    margin: 0 0 20px 0;
    @media (min-height: 780px) {
      margin-bottom: 0;
    }
  `)}
`;

export const ContentLastSlider = styled.div`
  font-weight: bold;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  z-index: 2;
`;
const Title = styled.span`
display: block;
  font-size: 64px;
  line-height: 150%;
  margin-bottom: 32px;
  color:${colors.white};
  @media (max-height: 690px) {
    margin-bottom: 20px;
    font-size: 54px;
  }
  
  ${media.laptop(css`
    font-size: 48px;
    margin-bottom: 24px;
  `)}
  ${media.tabletSmall(css`
    font-size: 36px;
    margin-bottom: 32px;
    max-width: 77.5%;
  `)}
  ${media.mobile(css`
    font-size: 26px;
  `)}
  ${media.mobileMedium(css`
    font-size: 24px;
  `)}
    ${media.mobileSmall(css`
      font-size: 21px;
    `)}
`;

const Text = styled.span`
  max-width: 1034px;
  font-size: 26px;
  line-height: 150%;
  color:${colors.grayDark};
  margin-bottom: 64px;
  @media (max-height: 690px) {
    margin-bottom: 56px;
  }
  ${media.laptop(css`
    font-size: 20px;
    margin-bottom: 48px;
    max-width: 770px;
  `)}
  ${media.tabletSmall(css`
    font-size: 18px;
    max-width: 77.5%;
  `)}
  ${media.mobile(css`
    font-size: 16px;
  `)}
  ${media.mobileSmall(css`
    font-size: 14px;
  `)}
`;

const ButtonWrapper = styled.div`
  width: 263px;
  height: 67px;

  a {
    font-weight: bold;
    font-size: 21.3333px;
    line-height: 31px;
    color: ${colors.white};
    border-radius: 10.6667px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  ${media.laptop(css`
    width: 197px;
    height: 50px;

    a {
      font-size: 16px;
      line-height: 23px;
      border-radius: 8px;
    }
  `)} ${media.mobileMedium(css`
    width: 100%;
    height: 52px;
    a {
      font-size: 14px;
      line-height: 20px;
    }
  `)};
  ${media.tabletSmall(css`
    max-width: 77.5%;
  `)}
`;

export default LastSlide;
