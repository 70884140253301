import React from 'react';
import styled, { css } from 'styled-components';
import CourseLauchBlock from './components/CourseLauchBlock';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function FirstSlide() {
  return (
    <Component>
      <Title>
        Complete pre-order and receive a <span>90%</span> discount for{' '}
        <span>25</span> fully explained <span>real case</span> interview market
        sizing video examples.
      </Title>
      <CourseLauchBlock />
    </Component>
  );
}

const Component = styled.div`
  max-width: 1300px;
  ${media.laptop(css`
    max-width: 970px;
  `)}
  ${media.tabletSmall(css`
    max-width: 690px;
  `)}
  ${media.mobile(css`
    margin: 0 20px;
  `)}
`;

const Title = styled.p`
  margin: 0 auto 64px;
  font-weight: bold;
  font-size: 64px;
  line-height: 150%;
  text-align: center;

  color: ${colors.white};
  span {
    color: ${colors.redInvalid};
  }
  @media (max-height: 820px) {
    font-size: 60px;
    margin-bottom: 48px;
  }
  @media (max-height: 745px) {
    font-size: 56px;
  }
  @media (max-height: 700px) {
    font-size: 48px;
    margin-bottom: 32px;
  }
  ${media.laptop(css`
    font-size: 48px;
    margin-bottom: 48px;
  `)}
  ${media.tablet(css`
    font-size: 42px;
    margin-bottom: 48px;
  `)}
  ${media.tabletSmall(css`
    font-size: 36px;
    margin-bottom: 64px;
  `)}
    ${media.mobile(css`
      font-size: 21px;
      margin-bottom: 72px;
    `)}
`;

export default FirstSlide;
