import React from 'react';
import { Page } from '@tager/web-components';

import Layout from '@/components/Layout';
import Home from '@/modules/Home';

import openGraphImageUrl from '@/assets/images/open-graph/opengraph.png';

function HomePage() {
  const pageProps = {
    title: 'Market Sizing – Consulting Case Interview Preparation',
    description:
      '90% of consulting candidates get a market sizing question at their case interview. Learn from 25+ real case interview video examples. Preorder now to get access to the entire course content for $9.99. Preorder now to get access to the entire course content with 90% discount',
    openGraphImage: openGraphImageUrl,
  };

  return (
    <Page {...pageProps}>
      <Layout isPreloaderHidden={false}>
        <Home />
      </Layout>
    </Page>
  );
}

export default HomePage;
