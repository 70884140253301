import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

type Props = {
  className?: string;
  error?: string;
  children: React.ReactNode;
};

function FormControl({ className, error, children }: Props) {
  return (
    <div className={className}>
      {children}
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
    </div>
  );
}

const ErrorMessage = styled.span`
  position: absolute;
  display: block;
  bottom: -30px;
  left: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: ${colors.redInvalid};
  margin-top: 8px;
  @media (max-height: 820px) {
    margin-top: 6px;
    font-size: 16px;
    bottom: -25px;
  }
  
  ${media.laptop(css`
    @media (max-height: 720px) {
      margin-top: 4px;
      font-size: 14px;
      bottom: -22px;
    }
  `)}

  ${media.mobile(css`
    margin-top: 4px;
    font-size: 14px;
    bottom: -22px;
  `)}
  ${media.mobileSmall(css`
    font-size: 12px;
    bottom: -20px;
    margin-top: 2px;
  `)}
`;

export default FormControl;
