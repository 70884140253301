import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function FooterEmail() {
  return (
    <EmailWrapper>
      <EmailLink href="mailto:support@themarketsizing.com">
        support@themarketsizing.com
      </EmailLink>
    </EmailWrapper>
  );
}

const EmailWrapper = styled.div`
  margin-bottom: 16px;
  ${media.mobile(css`
    text-align: center;
  `)}
`;

const EmailLink = styled.a`
  font-weight: 600;
  font-size: 24px;
  line-height: 150%;
  color: ${colors.white};
  ${media.laptop(css`
    font-size: 18px;
  `)}
  ${media.tabletSmall(css`
    font-size: 16px;
  `)}
    ${media.mobile(css`
      font-size: 14px;
    `)}
    ${media.mobileSmall(css`
      font-size: 12px;
    `)}
  &:hover {
    opacity: 0.7;
  }
`;

export default FooterEmail;
