import React from 'react';
//Hero
import heroUrl from '@/assets/images/hero/bg.jpg';
import heroUrl2x from '@/assets/images/hero/bg@2x.jpg';
import heroUrlWebp from '@/assets/images/hero/bg.webp';
import heroUrlWebp2x from '@/assets/images/hero/bg@2x.webp';
import heroUrl_laptop from '@/assets/images/hero/bg_laptop.jpg';
import heroUrl_laptop2x from '@/assets/images/hero/bg_laptop@2x.jpg';
import heroUrlWebp_laptop from '@/assets/images/hero/bg_laptop.webp';
import heroUrlWebp_laptop2x from '@/assets/images/hero/bg_laptop@2x.webp';
import heroUrl_smallTablet from '@/assets/images/hero/bg_tablet-small.jpg';
import heroUrl_smallTablet2x from '@/assets/images/hero/bg_tablet-small@2x.jpg';
import heroUrlWebp_smallTablet from '@/assets/images/hero/bg_tablet-small.webp';
import heroUrlWebp_smallTablet2x from '@/assets/images/hero/bg_tablet-small@2x.webp';
import heroUrl_mobileS from '@/assets/images/hero/bg_mobile.jpg';
import heroUrl_mobileS2x from '@/assets/images/hero/bg_mobile@2x.jpg';
import heroUrlWebp_mobileS from '@/assets/images/hero/bg_mobile.webp';
import heroUrlWebp_mobileS2x from '@/assets/images/hero/bg_mobile@2x.webp';

//PartnersLogo
import { ReactComponent as LogoBCG } from '@/assets/svg/partners-logo/bcg-logo.svg';
import { ReactComponent as LogoMcKinsey } from '@/assets/svg/partners-logo/mcKinsey-logo.svg';
import { ReactComponent as LogoBain } from '@/assets/svg/partners-logo/bain-logo.svg';
import { ReactComponent as LogoBerger } from '@/assets/svg/partners-logo/berger-logo.svg';
import { ReactComponent as LogoKearney } from '@/assets/svg/partners-logo/kearney-logo.svg';
import { ReactComponent as LogoOliver } from '@/assets/svg/partners-logo/oliver-logo.svg';

//Quiz
import quizUrl from '@/assets/images/quiz/quiz_1.png';
import quizUrl2x from '@/assets/images/quiz/quiz_1@2x.png';
import quizUrlWebp from '@/assets/images/quiz/quiz_1.webp';
import quizUrlWebp2x from '@/assets/images/quiz/quiz_1@2x.webp';
import quizUrl_laptop from '@/assets/images/quiz/quiz_1_laptop.png';
import quizUrl_laptop2x from '@/assets/images/quiz/quiz_1_laptop@2x.png';
import quizUrlWebp_laptop from '@/assets/images/quiz/quiz_1_laptop.webp';
import quizUrlWebp_laptop2x from '@/assets/images/quiz/quiz_1_laptop@2x.webp';
import quizUrl_smallTablet from '@/assets/images/quiz/quiz_1_mobile.png';
import quizUrl_smallTablet2x from '@/assets/images/quiz/quiz_1_mobile@2x.png';
import quizUrlWebp_smallTablet from '@/assets/images/quiz/quiz_1_mobile.webp';
import quizUrlWebp_smallTablet2x from '@/assets/images/quiz/quiz_1_mobile@2x.webp';
import quizUrl_mobileS from '@/assets/images/quiz/quiz_1_mobile-s.png';
import quizUrl_mobileS2x from '@/assets/images/quiz/quiz_1_mobile-s@2x.png';
import quizUrlWebp_mobileS from '@/assets/images/quiz/quiz_1_mobile-s.webp';
import quizUrlWebp_mobileS2x from '@/assets/images/quiz/quiz_1_mobile-s@2x.webp';
//2
import quiz2Url from '@/assets/images/quiz/quiz-2/quiz_2.png';
import quiz2Url2x from '@/assets/images/quiz/quiz-2/quiz_2@2x.png';
import quiz2UrlWebp from '@/assets/images/quiz/quiz-2/quiz_2.webp';
import quiz2UrlWebp2x from '@/assets/images/quiz/quiz-2/quiz_2@2x.webp';
import quiz2Url_laptop from '@/assets/images/quiz/quiz-2/quiz_2_laptop.png';
import quiz2Url_laptop2x from '@/assets/images/quiz/quiz-2/quiz_2_laptop@2x.png';
import quiz2UrlWebp_laptop from '@/assets/images/quiz/quiz-2/quiz_2_laptop.webp';
import quiz2UrlWebp_laptop2x from '@/assets/images/quiz/quiz-2/quiz_2_laptop@2x.webp';
import quiz2Url_smallTablet from '@/assets/images/quiz/quiz-2/quiz_2_mobile.png';
import quiz2Url_smallTablet2x from '@/assets/images/quiz/quiz-2/quiz_2_mobile@2x.png';
import quiz2UrlWebp_smallTablet from '@/assets/images/quiz/quiz-2/quiz_2_mobile.webp';
import quiz2UrlWebp_smallTablet2x from '@/assets/images/quiz/quiz-2/quiz_2_mobile@2x.webp';
import quiz2Url_mobileS from '@/assets/images/quiz/quiz-2/quiz_2_mobileS.png';
import quiz2Url_mobileS2x from '@/assets/images/quiz/quiz-2/quiz_2_mobileS@2x.png';
import quiz2UrlWebp_mobileS from '@/assets/images/quiz/quiz-2/quiz_2_mobileS.webp';
import quiz2UrlWebp_mobileS2x from '@/assets/images/quiz/quiz-2/quiz_2_mobileS@2x.webp';
//3
import quiz3Url from '@/assets/images/quiz/quiz-3/quiz_3.png';
import quiz3Url2x from '@/assets/images/quiz/quiz-3/quiz_3@2x.png';
import quiz3UrlWebp from '@/assets/images/quiz/quiz-3/quiz_3.webp';
import quiz3UrlWebp2x from '@/assets/images/quiz/quiz-3/quiz_3@2x.webp';
import quiz3Url_laptop from '@/assets/images/quiz/quiz-3/quiz_3_laptop.png';
import quiz3Url_laptop2x from '@/assets/images/quiz/quiz-3/quiz_3_laptop@2x.png';
import quiz3UrlWebp_laptop from '@/assets/images/quiz/quiz-3/quiz_3_laptop.webp';
import quiz3UrlWebp_laptop2x from '@/assets/images/quiz/quiz-3/quiz_3_laptop@2x.webp';
import quiz3Url_smallTablet from '@/assets/images/quiz/quiz-3/quiz_3_mobile.png';
import quiz3Url_smallTablet2x from '@/assets/images/quiz/quiz-3/quiz_3_mobile@2x.png';
import quiz3UrlWebp_smallTablet from '@/assets/images/quiz/quiz-3/quiz_3_mobile.webp';
import quiz3UrlWebp_smallTablet2x from '@/assets/images/quiz/quiz-3/quiz_3_mobile@2x.webp';
import quiz3Url_mobileS from '@/assets/images/quiz/quiz-3/quiz_3_mobileS.png';
import quiz3Url_mobileS2x from '@/assets/images/quiz/quiz-3/quiz_3_mobileS@2x.png';
import quiz3UrlWebp_mobileS from '@/assets/images/quiz/quiz-3/quiz_3_mobileS.webp';
import quiz3UrlWebp_mobileS2x from '@/assets/images/quiz/quiz-3/quiz_3_mobileS@2x.webp';
//4
import quiz4Url from '@/assets/images/quiz/quiz-4/quiz_4.png';
import quiz4Url2x from '@/assets/images/quiz/quiz-4/quiz_4@2x.png';
import quiz4UrlWebp from '@/assets/images/quiz/quiz-4/quiz_4.webp';
import quiz4UrlWebp2x from '@/assets/images/quiz/quiz-4/quiz_4@2x.webp';
import quiz4Url_laptop from '@/assets/images/quiz/quiz-4/quiz_4_laptop.png';
import quiz4Url_laptop2x from '@/assets/images/quiz/quiz-4/quiz_4_laptop@2x.png';
import quiz4UrlWebp_laptop from '@/assets/images/quiz/quiz-4/quiz_4_laptop.webp';
import quiz4UrlWebp_laptop2x from '@/assets/images/quiz/quiz-4/quiz_4_laptop@2x.webp';
import quiz4Url_smallTablet from '@/assets/images/quiz/quiz-4/quiz_4_mobile.png';
import quiz4Url_smallTablet2x from '@/assets/images/quiz/quiz-4/quiz_4_mobile@2x.png';
import quiz4UrlWebp_smallTablet from '@/assets/images/quiz/quiz-4/quiz_4_mobile.webp';
import quiz4UrlWebp_smallTablet2x from '@/assets/images/quiz/quiz-4/quiz_4_mobile@2x.webp';
import quiz4Url_mobileS from '@/assets/images/quiz/quiz-4/quiz_4_mobileS.png';
import quiz4Url_mobileS2x from '@/assets/images/quiz/quiz-4/quiz_4_mobileS@2x.png';
import quiz4UrlWebp_mobileS from '@/assets/images/quiz/quiz-4/quiz_4_mobileS.webp';
import quiz4UrlWebp_mobileS2x from '@/assets/images/quiz/quiz-4/quiz_4_mobileS@2x.webp';
//5
import quiz5Url from '@/assets/images/quiz/quiz-5/quiz_5.png';
import quiz5Url2x from '@/assets/images/quiz/quiz-5/quiz_5@2x.png';
import quiz5UrlWebp from '@/assets/images/quiz/quiz-5/quiz_5.webp';
import quiz5UrlWebp2x from '@/assets/images/quiz/quiz-5/quiz_5@2x.webp';
import quiz5Url_laptop from '@/assets/images/quiz/quiz-5/quiz_5_laptop.png';
import quiz5Url_laptop2x from '@/assets/images/quiz/quiz-5/quiz_5_laptop@2x.png';
import quiz5UrlWebp_laptop from '@/assets/images/quiz/quiz-5/quiz_5_laptop.webp';
import quiz5UrlWebp_laptop2x from '@/assets/images/quiz/quiz-5/quiz_5_laptop@2x.webp';
import quiz5Url_smallTablet from '@/assets/images/quiz/quiz-5/quiz_5_mobile.png';
import quiz5Url_smallTablet2x from '@/assets/images/quiz/quiz-5/quiz_5_mobile@2x.png';
import quiz5UrlWebp_smallTablet from '@/assets/images/quiz/quiz-5/quiz_5_mobile.webp';
import quiz5UrlWebp_smallTablet2x from '@/assets/images/quiz/quiz-5/quiz_5_mobile@2x.webp';
import quiz5Url_mobileS from '@/assets/images/quiz/quiz-5/quiz_5_mobileS.png';
import quiz5Url_mobileS2x from '@/assets/images/quiz/quiz-5/quiz_5_mobileS@2x.png';
import quiz5UrlWebp_mobileS from '@/assets/images/quiz/quiz-5/quiz_5_mobileS.webp';
import quiz5UrlWebp_mobileS2x from '@/assets/images/quiz/quiz-5/quiz_5_mobileS@2x.webp';
//attribute 1
import quiz1LeftUrl_mobileS from '@/assets/images/quiz/quiz-1/tennis_racket.png';
import quiz1LeftUrl_mobileS2x from '@/assets/images/quiz/quiz-1/tennis_racket@2x.png';
import quiz1LeftUrlWebp_mobileS from '@/assets/images/quiz/quiz-1/tennis_racket.webp';
import quiz1LeftUrlWebp_mobileS2x from '@/assets/images/quiz/quiz-1/tennis_racket@2x.webp';
import quiz1RightUrl_mobileS from '@/assets/images/quiz/quiz-1/tennis_ball.png';
import quiz1RightUrl_mobileS2x from '@/assets/images/quiz/quiz-1/tennis_ball@2x.png';
import quiz1RightUrlWebp_mobileS from '@/assets/images/quiz/quiz-1/tennis_ball.webp';
import quiz1RightUrlWebp_mobileS2x from '@/assets/images/quiz/quiz-1/tennis_ball@2x.webp';
//attribute 2
import quiz2LeftUrl_mobileS from '@/assets/images/quiz/quiz-2/kindle.png';
import quiz2LeftUrl_mobileS2x from '@/assets/images/quiz/quiz-2/kindle@2x.png';
import quiz2LeftUrlWebp_mobileS from '@/assets/images/quiz/quiz-2/kindle.webp';
import quiz2LeftUrlWebp_mobileS2x from '@/assets/images/quiz/quiz-2/kindle@2x.webp';
import quiz2RightUrl_mobileS from '@/assets/images/quiz/quiz-2/trees.png';
import quiz2RightUrl_mobileS2x from '@/assets/images/quiz/quiz-2/trees@2x.png';
import quiz2RightUrlWebp_mobileS from '@/assets/images/quiz/quiz-2/trees.webp';
import quiz2RightUrlWebp_mobileS2x from '@/assets/images/quiz/quiz-2/trees@2x.webp';
//attribute 3
import quiz3LeftUrl_mobileS from '@/assets/images/quiz/quiz-3/hairbrush.png';
import quiz3LeftUrl_mobileS2x from '@/assets/images/quiz/quiz-3/hairbrush@2x.png';
import quiz3LeftUrlWebp_mobileS from '@/assets/images/quiz/quiz-3/hairbrush.webp';
import quiz3LeftUrlWebp_mobileS2x from '@/assets/images/quiz/quiz-3/hairbrush@2x.webp';
import quiz3RightUrl_mobile from '@/assets/images/quiz/quiz-3/scissors_02.png';
import quiz3RightUrl_mobile2x from '@/assets/images/quiz/quiz-3/scissors_02@2x.png';
import quiz3RightUrlWebp_mobile from '@/assets/images/quiz/quiz-3/scissors_02.webp';
import quiz3RightUrlWebp_mobile2x from '@/assets/images/quiz/quiz-3/scissors_02@2x.webp';
import quiz3RightUrl_mobileS from '@/assets/images/quiz/quiz-3/scissors_mobile.png';
import quiz3RightUrl_mobileS2x from '@/assets/images/quiz/quiz-3/scissors_mobile@2x.png';
import quiz3RightUrlWebp_mobileS from '@/assets/images/quiz/quiz-3/scissors_mobile.webp';
import quiz3RightUrlWebp_mobileS2x from '@/assets/images/quiz/quiz-3/scissors_mobile@2x.webp';
//attribute 4
import quiz4LeftUrl_mobileS from '@/assets/images/quiz/quiz-4/starbacks.png';
import quiz4LeftUrl_mobileS2x from '@/assets/images/quiz/quiz-4/starbacks@2x.png';
import quiz4LeftUrlWebp_mobileS from '@/assets/images/quiz/quiz-4/starbacks.webp';
import quiz4LeftUrlWebp_mobileS2x from '@/assets/images/quiz/quiz-4/starbacks@2x.webp';
import quiz4RightUrl_mobileS from '@/assets/images/quiz/quiz-4/cake.png';
import quiz4RightUrl_mobileS2x from '@/assets/images/quiz/quiz-4/cake@2x.png';
import quiz4RightUrlWebp_mobileS from '@/assets/images/quiz/quiz-4/cake.webp';
import quiz4RightUrlWebp_mobileS2x from '@/assets/images/quiz/quiz-4/cake@2x.webp';
//attribute 5
import quiz5LeftUrl_mobileS from '@/assets/images/quiz/quiz-5/iphones.png';
import quiz5LeftUrl_mobileS2x from '@/assets/images/quiz/quiz-5/iphones@2x.png';
import quiz5LeftUrlWebp_mobileS from '@/assets/images/quiz/quiz-5/iphones.webp';
import quiz5LeftUrlWebp_mobileS2x from '@/assets/images/quiz/quiz-5/iphones@2x.webp';
import quiz5RightUrl_mobileS from '@/assets/images/quiz/quiz-5/empire.png';
import quiz5RightUrl_mobileS2x from '@/assets/images/quiz/quiz-5/empire@2x.png';
import quiz5RightUrlWebp_mobileS from '@/assets/images/quiz/quiz-5/empire.webp';
import quiz5RightUrlWebp_mobileS2x from '@/assets/images/quiz/quiz-5/empire@2x.webp';
//Structure
import structureUrl from '@/assets/images/structure/structure_small-tablet.png';
import structureUrl2x from '@/assets/images/structure/structure_small-tablet@2x.png';
import structureUrlWebp from '@/assets/images/structure/structure_small-tablet.webp';
import structureUrlWebp2x from '@/assets/images/structure/structure_small-tablet@2x.webp';
import structureUrl_laptop from '@/assets/images/structure/structure_small-tablet.png';
import structureUrl_laptop2x from '@/assets/images/structure/structure_small-tablet@2x.png';
import structureUrlWebp_laptop from '@/assets/images/structure/structure_small-tablet.webp';
import structureUrlWebp_laptop2x from '@/assets/images/structure/structure_small-tablet@2x.webp';
import structureUrl_smallTablet from '@/assets/images/structure/structure_mobile.png';
import structureUrl_smallTablet2x from '@/assets/images/structure/structure_mobile@2x.png';
import structureUrlWebp_smallTablet from '@/assets/images/structure/structure_mobile.webp';
import structureUrlWebp_smallTablet2x from '@/assets/images/structure/structure_mobile@2x.webp';
import structureUrl_mobileS from '@/assets/images/structure/structure_mobileS.png';
import structureUrl_mobileS2x from '@/assets/images/structure/structure_mobileS@2x.png';
import structureUrlWebp_mobileS from '@/assets/images/structure/structure_mobileS.webp';
import structureUrlWebp_mobileS2x from '@/assets/images/structure/structure_mobileS@2x.webp';

//Education
import educationUrl from '@/assets/images/education/bg.png';
import educationUrl2x from '@/assets/images/education/bg@2x.png';
import educationUrlWebp from '@/assets/images/education/bg.webp';
import educationUrlWebp2x from '@/assets/images/education/bg@2x.webp';
import educationUrl_laptop from '@/assets/images/education/bg_laptop.png';
import educationUrl_laptop2x from '@/assets/images/education/bg_laptop@2x.png';
import educationUrlWebp_laptop from '@/assets/images/education/bg_laptop.webp';
import educationUrlWebp_laptop2x from '@/assets/images/education/bg_laptop@2x.webp';
import educationUrl_smallTablet from '@/assets/images/education/bg_mobile.png';
import educationUrl_smallTablet2x from '@/assets/images/education/bg_mobile@2x.png';
import educationUrlWebp_smallTablet from '@/assets/images/education/bg_mobile.webp';
import educationUrlWebp_smallTablet2x from '@/assets/images/education/bg_mobile@2x.webp';
import educationUrl_mobileS from '@/assets/images/education/bg_mobileS.png';
import educationUrl_mobileS2x from '@/assets/images/education/slide-bg/slide_1_mobileS@2x.png';
import educationUrlWebp_mobileS from '@/assets/images/education/bg_mobileS.webp';
import educationUrlWebp_mobileS2x from '@/assets/images/education/bg_mobileS@2x.webp';

import avatarUrl from '@/assets/images/education/avatar/avatar_1.png';
import avatarUrl2x from '@/assets/images/education/avatar/avatar_1@2x.png';
import avatarUrlWebp from '@/assets/images/education/avatar/avatar_1.webp';
import avatarUrlWebp2x from '@/assets/images/education/avatar/avatar_1@2x.webp';
import avatarUrl_laptop from '@/assets/images/education/avatar/avatar_1_laptop.png';
import avatarUrl_laptop2x from '@/assets/images/education/avatar/avatar_1_laptop@2x.png';
import avatarUrlWebp_laptop from '@/assets/images/education/avatar/avatar_1_laptop.webp';
import avatarUrlWebp_laptop2x from '@/assets/images/education/avatar/avatar_1_laptop@2x.webp';
import avatarUrl_smallTablet from '@/assets/images/education/avatar/avatar_1_mobile.png';
import avatarUrl_smallTablet2x from '@/assets/images/education/avatar/avatar_1_mobile@2x.png';
import avatarUrlWebp_smallTablet from '@/assets/images/education/avatar/avatar_1_mobile.webp';
import avatarUrlWebp_smallTablet2x from '@/assets/images/education/avatar/avatar_1_mobile@2x.webp';
import avatarUrl_mobileS from '@/assets/images/education/avatar/avatar_1_mobileS.png';
import avatarUrl_mobileS2x from '@/assets/images/education/avatar/avatar_1_mobileS@2x.png';
import avatarUrlWebp_mobileS from '@/assets/images/education/avatar/avatar_1_mobileS.webp';
import avatarUrlWebp_mobileS2x from '@/assets/images/education/avatar/avatar_1_mobileS@2x.webp';
// Slide1
import slide_1Url from '@/assets/images/education/slide-bg/slide_1.png';
import slide_1Url2x from '@/assets/images/education/slide-bg/slide_1@2x.png';
import slide_1UrlWebp from '@/assets/images/education/slide-bg/slide_1.webp';
import slide_1UrlWebp2x from '@/assets/images/education/slide-bg/slide_1@2x.webp';
import slide_1Url_laptop from '@/assets/images/education/slide-bg/slide_1_laptop.png';
import slide_1Url_laptop2x from '@/assets/images/education/slide-bg/slide_1_laptop@2x.png';
import slide_1UrlWebp_laptop from '@/assets/images/education/slide-bg/slide_1_laptop.webp';
import slide_1UrlWebp_laptop2x from '@/assets/images/education/slide-bg/slide_1_laptop@2x.webp';
import slide_1Url_smallTablet from '@/assets/images/education/slide-bg/slide_1_mobile.png';
import slide_1Url_smallTablet2x from '@/assets/images/education/slide-bg/slide_1_mobile@2x.png';
import slide_1UrlWebp_smallTablet from '@/assets/images/education/slide-bg/slide_1_mobile.webp';
import slide_1UrlWebp_smallTablet2x from '@/assets/images/education/slide-bg/slide_1_mobile@2x.webp';
import slide_1Url_mobileS from '@/assets/images/education/slide-bg/slide_1_mobileS.png';
import slide_1Url_mobileS2x from '@/assets/images/education/slide-bg/slide_1_mobileS@2x.png';
import slide_1UrlWebp_mobileS from '@/assets/images/education/slide-bg/slide_1_mobileS.webp';
import slide_1UrlWebp_mobileS2x from '@/assets/images/education/slide-bg/slide_1_mobileS@2x.webp';
//slide2
import slide_2Url from '@/assets/images/education/slide-bg/slide_2.png';
import slide_2Url2x from '@/assets/images/education/slide-bg/slide_2@2x.png';
import slide_2UrlWebp from '@/assets/images/education/slide-bg/slide_2.webp';
import slide_2UrlWebp2x from '@/assets/images/education/slide-bg/slide_2@2x.webp';
import slide_2Url_laptop from '@/assets/images/education/slide-bg/slide_2_laptop.png';
import slide_2Url_laptop2x from '@/assets/images/education/slide-bg/slide_2_laptop@2x.png';
import slide_2UrlWebp_laptop from '@/assets/images/education/slide-bg/slide_2_laptop.webp';
import slide_2UrlWebp_laptop2x from '@/assets/images/education/slide-bg/slide_2_laptop@2x.webp';
import slide_2Url_smallTablet from '@/assets/images/education/slide-bg/slide_2_mobile.png';
import slide_2Url_smallTablet2x from '@/assets/images/education/slide-bg/slide_2_mobile@2x.png';
import slide_2UrlWebp_smallTablet from '@/assets/images/education/slide-bg/slide_2_mobile.webp';
import slide_2UrlWebp_smallTablet2x from '@/assets/images/education/slide-bg/slide_2_mobile@2x.webp';
import slide_2Url_mobileS from '@/assets/images/education/slide-bg/slide_2_mobileS.png';
import slide_2Url_mobileS2x from '@/assets/images/education/slide-bg/slide_2_mobileS@2x.png';
import slide_2UrlWebp_mobileS from '@/assets/images/education/slide-bg/slide_2_mobileS.webp';
import slide_2UrlWebp_mobileS2x from '@/assets/images/education/slide-bg/slide_2_mobileS@2x.webp';
//slide3
import slide_3Url from '@/assets/images/education/slide-bg/slide_3.png';
import slide_3Url2x from '@/assets/images/education/slide-bg/slide_3@2x.png';
import slide_3UrlWebp from '@/assets/images/education/slide-bg/slide_3.webp';
import slide_3UrlWebp2x from '@/assets/images/education/slide-bg/slide_3@2x.webp';
import slide_3Url_laptop from '@/assets/images/education/slide-bg/slide_3_laptop.png';
import slide_3Url_laptop2x from '@/assets/images/education/slide-bg/slide_3_laptop@2x.png';
import slide_3UrlWebp_laptop from '@/assets/images/education/slide-bg/slide_3_laptop.webp';
import slide_3UrlWebp_laptop2x from '@/assets/images/education/slide-bg/slide_3_laptop@2x.webp';
import slide_3Url_smallTablet from '@/assets/images/education/slide-bg/slide_3_mobile.png';
import slide_3Url_smallTablet2x from '@/assets/images/education/slide-bg/slide_3_mobile@2x.png';
import slide_3UrlWebp_smallTablet from '@/assets/images/education/slide-bg/slide_3_mobile.webp';
import slide_3UrlWebp_smallTablet2x from '@/assets/images/education/slide-bg/slide_3_mobile@2x.webp';
import slide_3Url_mobileS from '@/assets/images/education/slide-bg/slide_3_mobileS.png';
import slide_3Url_mobileS2x from '@/assets/images/education/slide-bg/slide_3_mobileS@2x.png';
import slide_3UrlWebp_mobileS from '@/assets/images/education/slide-bg/slide_3_mobileS.webp';
import slide_3UrlWebp_mobileS2x from '@/assets/images/education/slide-bg/slide_3_mobileS@2x.webp';
//slide4
import slide_4Url from '@/assets/images/education/slide-bg/slide_4.png';
import slide_4Url2x from '@/assets/images/education/slide-bg/slide_4@2x.png';
import slide_4UrlWebp from '@/assets/images/education/slide-bg/slide_4.webp';
import slide_4UrlWebp2x from '@/assets/images/education/slide-bg/slide_4@2x.webp';
import slide_4Url_laptop from '@/assets/images/education/slide-bg/slide_4_laptop.png';
import slide_4Url_laptop2x from '@/assets/images/education/slide-bg/slide_4_laptop@2x.png';
import slide_4UrlWebp_laptop from '@/assets/images/education/slide-bg/slide_4_laptop.webp';
import slide_4UrlWebp_laptop2x from '@/assets/images/education/slide-bg/slide_4_laptop@2x.webp';
import slide_4Url_smallTablet from '@/assets/images/education/slide-bg/slide_4_mobile.png';
import slide_4Url_smallTablet2x from '@/assets/images/education/slide-bg/slide_4_mobile@2x.png';
import slide_4UrlWebp_smallTablet from '@/assets/images/education/slide-bg/slide_4_mobile.webp';
import slide_4UrlWebp_smallTablet2x from '@/assets/images/education/slide-bg/slide_4_mobile@2x.webp';
import slide_4Url_mobileS from '@/assets/images/education/slide-bg/slide_4_mobileS.png';
import slide_4Url_mobileS2x from '@/assets/images/education/slide-bg/slide_4_mobileS@2x.png';
import slide_4UrlWebp_mobileS from '@/assets/images/education/slide-bg/slide_4_mobileS.webp';
import slide_4UrlWebp_mobileS2x from '@/assets/images/education/slide-bg/slide_4_mobileS@2x.webp';
//slide5
import slide_5Url from '@/assets/images/education/slide-bg/slide_5.png';
import slide_5Url2x from '@/assets/images/education/slide-bg/slide_5@2x.png';
import slide_5UrlWebp from '@/assets/images/education/slide-bg/slide_5.webp';
import slide_5UrlWebp2x from '@/assets/images/education/slide-bg/slide_5@2x.webp';
import slide_5Url_laptop from '@/assets/images/education/slide-bg/slide_5_laptop.png';
import slide_5Url_laptop2x from '@/assets/images/education/slide-bg/slide_5_laptop@2x.png';
import slide_5UrlWebp_laptop from '@/assets/images/education/slide-bg/slide_5_laptop.webp';
import slide_5UrlWebp_laptop2x from '@/assets/images/education/slide-bg/slide_5_laptop@2x.webp';
import slide_5Url_smallTablet from '@/assets/images/education/slide-bg/slide_5_mobile.png';
import slide_5Url_smallTablet2x from '@/assets/images/education/slide-bg/slide_5_mobile@2x.png';
import slide_5UrlWebp_smallTablet from '@/assets/images/education/slide-bg/slide_5_mobile.webp';
import slide_5UrlWebp_smallTablet2x from '@/assets/images/education/slide-bg/slide_5_mobile@2x.webp';
import slide_5Url_mobileS from '@/assets/images/education/slide-bg/slide_5_mobileS.png';
import slide_5Url_mobileS2x from '@/assets/images/education/slide-bg/slide_5_mobileS@2x.png';
import slide_5UrlWebp_mobileS from '@/assets/images/education/slide-bg/slide_5_mobileS.webp';
import slide_5UrlWebp_mobileS2x from '@/assets/images/education/slide-bg/slide_5_mobileS@2x.webp';

// Teaser
import teaserUrl from '@/assets/images/teaser/teaser.jpg';
import teaserUrl2x from '@/assets/images/teaser/teaser@2x.jpg';
import teaserUrlWebp from '@/assets/images/teaser/teaser.webp';
import teaserUrlWebp2x from '@/assets/images/teaser/teaser@2x.webp';
import teaserUrl_laptop from '@/assets/images/teaser/teaser_laptop.jpg';
import teaserUrl_laptop2x from '@/assets/images/teaser/teaser_laptop@2x.jpg';
import teaserUrlWebp_laptop from '@/assets/images/teaser/teaser_laptop.webp';
import teaserUrlWebp_laptop2x from '@/assets/images/teaser/teaser_laptop@2x.webp';
import teaserUrl_smallTablet from '@/assets/images/teaser/teaser_mobile.jpg';
import teaserUrl_smallTablet2x from '@/assets/images/teaser/teaser_mobile@2x.jpg';
import teaserUrlWebp_smallTablet from '@/assets/images/teaser/teaser_mobile.webp';
import teaserUrlWebp_smallTablet2x from '@/assets/images/teaser/teaser_mobile@2x.webp';
import teaserUrl_mobileS from '@/assets/images/teaser/teaser_mobileS.jpg';
import teaserUrl_mobileS2x from '@/assets/images/teaser/teaser_mobileS@2x.png';
import teaserUrlWebp_mobileS from '@/assets/images/teaser/teaser_mobileS.webp';
import teaserUrlWebp_mobileS2x from '@/assets/images/teaser/teaser_mobileS@2x.webp';

export type BackgroundType = {
  bgImage?: {
    mobileS: string;
    mobileS2x: string;
    smallTablet: string;
    smallTablet2x: string;
    tablet: string;
    tablet2x: string;
    desktop?: string;
    desktop2x?: string;
  };
  bgImageWebp?: {
    mobileS: string;
    mobileS2x: string;
    smallTablet: string;
    smallTablet2x: string;
    tablet: string;
    tablet2x: string;
    desktop?: string;
    desktop2x?: string;
  };
};

export const HERO_BACKGROUND: BackgroundType = {
  bgImage: {
    mobileS: heroUrl_mobileS,
    mobileS2x: heroUrl_mobileS2x,
    tablet: heroUrl_laptop,
    tablet2x: heroUrl_laptop2x,
    smallTablet: heroUrl_smallTablet,
    smallTablet2x: heroUrl_smallTablet2x,
    desktop: heroUrl,
    desktop2x: heroUrl2x,
  },
  bgImageWebp: {
    mobileS: heroUrlWebp_mobileS,
    mobileS2x: heroUrlWebp_mobileS2x,
    tablet: heroUrlWebp_laptop,
    tablet2x: heroUrlWebp_laptop2x,
    smallTablet: heroUrlWebp_smallTablet,
    smallTablet2x: heroUrlWebp_smallTablet2x,
    desktop: heroUrlWebp,
    desktop2x: heroUrlWebp2x,
  },
};

export type PartnersItemType = {
  logo: React.ReactNode;
};

export const PARTNERS_LIST_LOGO: Array<PartnersItemType> = [
  {
    logo: <LogoMcKinsey />,
  },
  {
    logo: <LogoBCG />,
  },
  {
    logo: <LogoBain />,
  },
  {
    logo: <LogoBerger />,
  },
  {
    logo: <LogoKearney />,
  },
  {
    logo: <LogoOliver />,
  },
];

export type FaqItemType = {
  title: string;
  text: React.ReactNode;
};

export const FAQ_LIST_ITEM: Array<FaqItemType> = [
  {
    title: 'Who is this course created for?',
    text:
      'The course was created for university students and early career individuals, looking to kickstart their career in management consulting. Without being able to nail a market sizing/estimations questions, the chances of successfully passing a consulting interview are very low.',
  },
  {
    title: 'What is provided in the course?',
    text:
      'Our course consists of 25 video-lessons (400+ minutes of content), which comprehensively cover the methodology and techniques required to successfully sole a market sizing/estimations case during an interview. All of it is based on examples from real case interviews at MBB',
  },
  {
    title: 'What does the 90% discount for pre-order mean?',
    text:
      'This means that if you complete the pre-order form, you will automatically get a link to purchase the full course on the day of release at a price of $9.99 instead of $99.99. We will let you know when the course is out (expected release date: 31/08/2020).',
  },
  {
    title: 'Do I have to pay for anything now?',
    text:
      'No. You don’t even need to submit your card details at this stage. You will have a chance to purchase for the course at a discounted price ($9.99) only after the entire course contents are out (all 25 videos).',
  },
  {
    title: 'Is this course only suitable for future consultants?',
    text:
      'No, this course could greatly help anyone planning to interview at a tech or other company for the position of a product manager or business analyst. These jobs require similar skills and hence often have market sizing/estimations questions at the interview.',
  },
  {
    title: 'Do you offer private coaching for consulting interview?',
    text: (
      <>
        Yes, but at this stage only on the individual basis. If you would like
        to be coached by one of our course creators (ex-McKinsey and BCG
        employees), drop us a line at{' '}
        <a href="mailto:support@themarketsizing.com">
          support@themarketsizing.com
        </a>{' '}
        and we will get in touch.
      </>
    ),
  },
];

type quizOptionType = {
  value: string;
  active: boolean;
};
export type quizBackgroundType = {
  bgImage: {
    mobileS: string;
    mobileS2x: string;
    mobile: string;
    mobile2x: string;
    smallTablet: string;
    smallTablet2x: string;
    tablet: string;
    tablet2x: string;
  };
  bgImageWebp: {
    mobileS: string;
    mobileS2x: string;
    mobile: string;
    mobile2x: string;
    smallTablet: string;
    smallTablet2x: string;
    tablet: string;
    tablet2x: string;
  };
};

export type position = {
  width: number;
  height: number;
  top: string;
  left: string;
  right: string;
  bottom: string;
  zIndex?: string;
};
export type positionAttribute = {
  left: {
    desktop: position;
    laptop: position;
    tablet: position;
    tabletSmall: position;
    mobile: position;
    mobileMedium: position;
    mobileSmall: position;
  };
  right: {
    desktop: position;
    laptop: position;
    tablet: position;
    tabletSmall: position;
    mobile: position;
    mobileMedium: position;
    mobileSmall: position;
  };
};

export type quizItemType = {
  question?: React.ReactNode;
  options: Array<quizOptionType>;
  attribute?: {
    left: {
      bgImage: {
        mobileS: string;
        mobileS2x: string;
      };
      bgImageWebp: {
        mobileS: string;
        mobileS2x: string;
      };
    };
    right: {
      bgImage: {
        mobile?: string;
        mobile2x?: string;
        mobileS: string;
        mobileS2x: string;
      };
      bgImageWebp: {
        mobile?: string;
        mobile2x?: string;
        mobileS: string;
        mobileS2x: string;
      };
    };
  };
  position?: positionAttribute;
  description?: string;
  isFirstSlide?: boolean;
  isLastSlide?: boolean;
  correct?: string;
  wrong?: string;
};
const QUIZ_FIRST_SLIDE = {
  isFirstSlide: true,
  options: [],
  description:
    'During an interview you will have to solve market sizing and estimations questions, making assumptions and performing calculations on the spot. Here are some examples taken from real case interviews.',
};
const QUIZ_LAST_SLIDE = {
  isLastSlide: true,
  options: [],
};
const QUIZ_ITEM_1 = {
  question: (
    <>
      What is the <span>market size</span> of tennis rackets in the USA (USD)?
    </>
  ),
  options: [
    {
      value: '200M',
      active: true,
    },
    {
      value: '2B',
      active: false,
    },
    {
      value: '20B',
      active: false,
    },
  ],
  attribute: {
    left: {
      bgImage: {
        mobileS: quiz1LeftUrl_mobileS,
        mobileS2x: quiz1LeftUrl_mobileS2x,
      },
      bgImageWebp: {
        mobileS: quiz1LeftUrlWebp_mobileS,
        mobileS2x: quiz1LeftUrlWebp_mobileS2x,
      },
    },
    right: {
      bgImage: {
        mobileS: quiz1RightUrl_mobileS,
        mobileS2x: quiz1RightUrl_mobileS2x,
      },
      bgImageWebp: {
        mobileS: quiz1RightUrlWebp_mobileS,
        mobileS2x: quiz1RightUrlWebp_mobileS2x,
      },
    },
  },
  position: {
    right: {
      desktop: {
        width: 148,
        height: 164,
        top: 'auto',
        left: 'auto',
        bottom: '-40px',
        right: '-100px',
      },
      laptop: {
        width: 148,
        height: 164,
        top: 'auto',
        left: 'auto',
        bottom: '-40px',
        right: '-70px',
      },
      tablet: {
        width: 106,
        height: 117,
        top: 'auto',
        left: 'auto',
        bottom: '0px',
        right: '-40px',
      },
      tabletSmall: {
        width: 106,
        height: 117,
        top: 'auto',
        left: 'auto',
        bottom: '-20px',
        right: '-20px',
      },
      mobile: {
        width: 106,
        height: 117,
        top: 'auto',
        left: 'auto',
        bottom: '-40px',
        right: '0px',
      },
      mobileMedium: {
        width: 102,
        height: 113,
        top: 'auto',
        left: 'auto',
        bottom: '110px',
        right: '10px',
      },
      mobileSmall: {
        width: 102,
        height: 113,
        top: 'auto',
        left: 'auto',
        bottom: '140px',
        right: '-10px',
      },
    },
    left: {
      desktop: {
        width: 647,
        height: 647,
        top: '40px',
        left: '-350px',
        bottom: 'auto',
        right: 'auto',
      },
      laptop: {
        width: 485,
        height: 485,
        top: '35px',
        left: '-250px',
        bottom: 'auto',
        right: 'auto',
      },
      tablet: {
        width: 356,
        height: 356,
        top: '90px',
        left: '-160px',
        bottom: 'auto',
        right: 'auto',
      },
      tabletSmall: {
        width: 356,
        height: 356,
        top: '40px',
        left: '-140px',
        bottom: 'auto',
        right: 'auto',
      },
      mobile: {
        width: 356,
        height: 356,
        top: '40px',
        left: '-100px',
        bottom: 'auto',
        right: 'auto',
      },
      mobileMedium: {
        width: 403,
        height: 403,
        top: '60px',
        left: '-60px',
        bottom: 'auto',
        right: '-40px',
      },
      mobileSmall: {
        width: 403,
        height: 403,
        top: '60px',
        left: '-80px',
        bottom: 'auto',
        right: '-80px',
      },
    },
  },
  correct:
    'In fact, the market size of the tennis rackets in the US accounts for nearly 40% of the global market! We won’t even try to guess how you did it, but if we had to do this during an interview, we would try to find the number of existing tennis rackets in the US and then, using the replacement rate, calculate how many new rackets are purchase in the US yearly. All that is left is to multiply this number by an approximate average price of the racket.',
  wrong:
    'To get to the right number, try to estimate the number of existing tennis rackets in the US and then, using the replacement rate, calculate how many new rackets are purchase in the US yearly. All that is left is to multiply this number by an approximate average price of the racket. ',
};
const QUIZ_BACKGROUND_ITEM_1 = {
  bgImage: {
    mobileS: quizUrl_mobileS,
    mobileS2x: quizUrl_mobileS2x,
    mobile: quizUrl_smallTablet,
    mobile2x: quizUrl_smallTablet2x,
    smallTablet: quizUrl_laptop,
    smallTablet2x: quizUrl_laptop2x,
    tablet: quizUrl,
    tablet2x: quizUrl2x,
  },
  bgImageWebp: {
    mobileS: quizUrlWebp_mobileS,
    mobileS2x: quizUrlWebp_mobileS2x,
    mobile: quizUrlWebp_smallTablet,
    mobile2x: quizUrlWebp_smallTablet2x,
    smallTablet: quizUrlWebp_laptop,
    smallTablet2x: quizUrlWebp_laptop2x,
    tablet: quizUrlWebp,
    tablet2x: quizUrlWebp2x,
  },
};

const QUIZ_ITEM_2 = {
  question: (
    <>
      <span>How many</span> trees were saved by the invention of Kindle?
    </>
  ),
  options: [
    {
      value: '100K',
      active: false,
    },
    {
      value: '1M',
      active: false,
    },
    {
      value: '10M',
      active: true,
    },
  ],
  attribute: {
    left: {
      bgImage: {
        mobileS: quiz2LeftUrl_mobileS,
        mobileS2x: quiz2LeftUrl_mobileS2x,
      },
      bgImageWebp: {
        mobileS: quiz2LeftUrlWebp_mobileS,
        mobileS2x: quiz2LeftUrlWebp_mobileS2x,
      },
    },
    right: {
      bgImage: {
        mobileS: quiz2RightUrl_mobileS,
        mobileS2x: quiz2RightUrl_mobileS2x,
      },
      bgImageWebp: {
        mobileS: quiz2RightUrlWebp_mobileS,
        mobileS2x: quiz2RightUrlWebp_mobileS2x,
      },
    },
  },
  position: {
    right: {
      desktop: {
        width: 800,
        height: 600,
        top: 'auto',
        left: 'auto',
        bottom: '-290px',
        right: '-445px',
      },
      laptop: {
        width: 600,
        height: 500,
        top: 'auto',
        left: 'auto',
        bottom: '-280px',
        right: '-350px',
      },
      tablet: {
        width: 600,
        height: 500,
        top: 'auto',
        left: 'auto',
        bottom: '-280px',
        right: '-350px',
      },
      tabletSmall: {
        width: 500,
        height: 400,
        top: 'auto',
        left: 'auto',
        bottom: '-200px',
        right: '-250px',
      },
      mobile: {
        width: 450,
        height: 350,
        top: 'auto',
        left: 'auto',
        bottom: '-200px',
        right: '-250px',
      },
      mobileMedium: {
        width: 500,
        height: 350,
        top: 'auto',
        left: '80px',
        bottom: '50px',
        right: '-230px',
      },
      mobileSmall: {
        width: 500,
        height: 400,
        top: 'auto',
        left: '90px',
        bottom: '40px',
        right: '-250px',
      },
    },
    left: {
      desktop: {
        width: 441,
        height: 432,
        top: '160px',
        left: '-320px',
        bottom: 'auto',
        right: 'auto',
      },
      laptop: {
        width: 331,
        height: 324,
        top: '120px',
        left: '-240px',
        bottom: 'auto',
        right: 'auto',
      },
      tablet: {
        width: 331,
        height: 324,
        top: '120px',
        left: '-200px',
        bottom: 'auto',
        right: 'auto',
      },
      tabletSmall: {
        width: 250,
        height: 250,
        top: '155px',
        left: '-140px',
        bottom: 'auto',
        right: 'auto',
      },
      mobile: {
        width: 225,
        height: 220,
        top: '120px',
        left: '-110px',
        bottom: 'auto',
        right: 'auto',
      },
      mobileMedium: {
        width: 260,
        height: 235,
        top: '70px',
        left: '-80px',
        bottom: 'auto',
        right: '-80px',
      },
      mobileSmall: {
        width: 260,
        height: 235,
        top: '80px',
        left: '-80px',
        bottom: 'auto',
        right: '-80px',
      },
    },
  },
  correct:
    'This may seem like a somewhat confusing/complicated questions, so well done! In fact, in our view, it all comes down to the simple ratio: the total number of pages read in Kindle per year divided by the number of pages that comes out of a single tree. Getting to both of these numbers involves a fair deal of assumptions, but that’s what makes this problem so much fun to solve!',
  wrong:
    'Yeah, it is a slightly complex problem, but don’t worry, in fact, one of the simplest ways to solve this problem is to use a simple ratio between the total number of pages read in Kindle per year divided and the number of pages that comes out of a single tree. Getting to both of these numbers involves a fair deal of assumptions, but that’s what makes this problem so much fun to solve!',
};
const QUIZ_BACKGROUND_ITEM_2 = {
  bgImage: {
    mobileS: quiz2Url_mobileS,
    mobileS2x: quiz2Url_mobileS2x,
    mobile: quiz2Url_smallTablet,
    mobile2x: quiz2Url_smallTablet2x,
    smallTablet: quiz2Url_laptop,
    smallTablet2x: quiz2Url_laptop2x,
    tablet: quiz2Url,
    tablet2x: quiz2Url2x,
  },
  bgImageWebp: {
    mobileS: quiz2UrlWebp_mobileS,
    mobileS2x: quiz2UrlWebp_mobileS2x,
    mobile: quiz2UrlWebp_smallTablet,
    mobile2x: quiz2UrlWebp_smallTablet2x,
    smallTablet: quiz2UrlWebp_laptop,
    smallTablet2x: quiz2UrlWebp_laptop2x,
    tablet: quiz2UrlWebp,
    tablet2x: quiz2UrlWebp2x,
  },
};

const QUIZ_ITEM_3 = {
  question: (
    <>
      <span>How many</span> barbers are there in London?
    </>
  ),
  options: [
    {
      value: '2000',
      active: false,
    },
    {
      value: '20000',
      active: true,
    },
    {
      value: '200000',
      active: false,
    },
  ],
  attribute: {
    left: {
      bgImage: {
        mobileS: quiz3LeftUrl_mobileS,
        mobileS2x: quiz3LeftUrl_mobileS2x,
      },
      bgImageWebp: {
        mobileS: quiz3LeftUrlWebp_mobileS,
        mobileS2x: quiz3LeftUrlWebp_mobileS2x,
      },
    },
    right: {
      bgImage: {
        mobile: quiz3RightUrl_mobile,
        mobile2x: quiz3RightUrl_mobile2x,
        mobileS: quiz3RightUrl_mobileS,
        mobileS2x: quiz3RightUrl_mobileS2x,
      },
      bgImageWebp: {
        mobile: quiz3RightUrlWebp_mobile,
        mobile2x: quiz3RightUrlWebp_mobile2x,
        mobileS: quiz3RightUrlWebp_mobileS,
        mobileS2x: quiz3RightUrlWebp_mobileS2x,
      },
    },
  },
  position: {
    right: {
      desktop: {
        width: 550,
        height: 550,
        top: 'auto',
        left: 'auto',
        bottom: '-190px',
        right: '-400px',
      },
      laptop: {
        width: 450,
        height: 450,
        top: 'auto',
        left: 'auto',
        bottom: '-170px',
        right: '-300px',
      },
      tablet: {
        width: 400,
        height: 400,
        top: 'auto',
        left: 'auto',
        bottom: '-130px',
        right: '-240px',
      },
      tabletSmall: {
        width: 350,
        height: 350,
        top: 'auto',
        left: 'auto',
        bottom: '-150px',
        right: '-200px',
      },
      mobile: {
        width: 350,
        height: 350,
        top: 'auto',
        left: 'auto',
        bottom: '-150px',
        right: '-200px',
      },
      mobileMedium: {
        width: 270,
        height: 300,
        top: 'auto',
        left: 'auto',
        bottom: '30px',
        right: '-40px',
      },
      mobileSmall: {
        width: 210,
        height: 300,
        top: 'auto',
        left: 'auto',
        bottom: '50px',
        right: '-40px',
      },
    },
    left: {
      desktop: {
        width: 496,
        height: 458,
        top: '158px',
        left: '-324px',
        bottom: 'auto',
        right: 'auto',
      },
      laptop: {
        width: 372,
        height: 344,
        top: '115px',
        left: '-235px',
        bottom: 'auto',
        right: 'auto',
      },
      tablet: {
        width: 330,
        height: 320,
        top: '90px',
        left: '-180px',
        bottom: 'auto',
        right: 'auto',
      },
      tabletSmall: {
        width: 280,
        height: 280,
        top: '90px',
        left: '-115px',
        bottom: 'auto',
        right: 'auto',
      },
      mobile: {
        width: 280,
        height: 280,
        top: '65px',
        left: '-115px',
        bottom: 'auto',
        right: 'auto',
      },
      mobileMedium: {
        width: 230,
        height: 230,
        top: '130px',
        left: '-100px',
        bottom: 'auto',
        right: 'auto',
      },
      mobileSmall: {
        width: 195,
        height: 195,
        top: '132px',
        left: '-90px',
        bottom: 'auto',
        right: '-80px',
      },
    },
  },
  correct:
    'There are definitely multiple ways you could do this. Our favourite approach is to estimate the number of haircuts and/or other grooming services to be fulfilled over some time period and to divide this number by the number of services one barber is capable of performing over the same time period. ',
  wrong:
    'To get to the right number, try estimating the number of haircuts and/or other grooming services to be fulfilled over some time period and to divide this number by the number of services one barber is capable of performing over the same time period. What number do you get this time?',
};
const QUIZ_BACKGROUND_ITEM_3 = {
  bgImage: {
    mobileS: quiz3Url_mobileS,
    mobileS2x: quiz3Url_mobileS2x,
    mobile: quiz3Url_smallTablet,
    mobile2x: quiz3Url_smallTablet2x,
    smallTablet: quiz3Url_laptop,
    smallTablet2x: quiz3Url_laptop2x,
    tablet: quiz3Url,
    tablet2x: quiz3Url2x,
  },
  bgImageWebp: {
    mobileS: quiz3UrlWebp_mobileS,
    mobileS2x: quiz3UrlWebp_mobileS2x,
    mobile: quiz3UrlWebp_smallTablet,
    mobile2x: quiz3UrlWebp_smallTablet2x,
    smallTablet: quiz3UrlWebp_laptop,
    smallTablet2x: quiz3UrlWebp_laptop2x,
    tablet: quiz3UrlWebp,
    tablet2x: quiz3UrlWebp2x,
  },
};

const QUIZ_ITEM_4 = {
  question: (
    <>
      What is the <span>revenue</span> of a typical Starbucks store?
    </>
  ),
  options: [
    {
      value: '$100K',
      active: false,
    },
    {
      value: '$1M',
      active: true,
    },
    {
      value: '$10M',
      active: false,
    },
  ],
  attribute: {
    left: {
      bgImage: {
        mobileS: quiz4LeftUrl_mobileS,
        mobileS2x: quiz4LeftUrl_mobileS2x,
      },
      bgImageWebp: {
        mobileS: quiz4LeftUrlWebp_mobileS,
        mobileS2x: quiz4LeftUrlWebp_mobileS2x,
      },
    },
    right: {
      bgImage: {
        mobileS: quiz4RightUrl_mobileS,
        mobileS2x: quiz4RightUrl_mobileS2x,
      },
      bgImageWebp: {
        mobileS: quiz4RightUrlWebp_mobileS,
        mobileS2x: quiz4RightUrlWebp_mobileS2x,
      },
    },
  },
  position: {
    right: {
      desktop: {
        width: 362,
        height: 233,
        top: 'auto',
        left: 'auto',
        bottom: '-120px',
        right: '-240px',
      },
      laptop: {
        width: 272,
        height: 175,
        top: 'auto',
        left: 'auto',
        bottom: '-90px',
        right: '-220px',
      },
      tablet: {
        width: 272,
        height: 175,
        top: 'auto',
        left: 'auto',
        bottom: '-90px',
        right: '-120px',
      },
      tabletSmall: {
        width: 200,
        height: 150,
        top: 'auto',
        left: 'auto',
        bottom: '-75px',
        right: '-78px',
      },
      mobile: {
        width: 200,
        height: 150,
        top: 'auto',
        left: 'auto',
        bottom: '-75px',
        right: '-50px',
      },
      mobileMedium: {
        width: 180,
        height: 120,
        top: 'auto',
        left: 'auto',
        bottom: '70px',
        right: '90px',
      },
      mobileSmall: {
        width: 180,
        height: 120,
        top: 'auto',
        left: 'auto',
        bottom: '90px',
        right: '70px',
      },
    },
    left: {
      desktop: {
        width: 425,
        height: 460,
        top: '125px',
        left: '-230px',
        bottom: 'auto',
        right: 'auto',
      },
      laptop: {
        width: 350,
        height: 355,
        top: '80px',
        left: '-210px',
        bottom: 'auto',
        right: 'auto',
      },
      tablet: {
        width: 350,
        height: 355,
        top: '80px',
        left: '-140px',
        bottom: 'auto',
        right: 'auto',
      },
      tabletSmall: {
        width: 210,
        height: 225,
        top: '115px',
        left: '-80px',
        bottom: 'auto',
        right: 'auto',
      },
      mobile: {
        width: 210,
        height: 225,
        top: '80px',
        left: '-60px',
        bottom: 'auto',
        right: 'auto',
        zIndex: 5,
      },
      mobileMedium: {
        width: 230,
        height: 230,
        top: '90px',
        left: '50px',
        bottom: 'auto',
        right: '-80px',
        zIndex: '5',
      },
      mobileSmall: {
        width: 230,
        height: 230,
        top: '90px',
        left: '50px',
        bottom: 'auto',
        right: '0px',
        zIndex: '5',
      },
    },
  },
  correct:
    'Clearly, you are a regular visitor to coffee shops! Since we only care about revenues, the simplest way to get to the final number is to multiply the number of purchases made in a year by the average value ($) of a single purchase, keeping in mind that people often get more than just a coffee at Starbucks.',
  wrong:
    'Might be a good idea to pay a visit to your local coffee shop. The simplest way to get to the answer is to multiply the number of purchases made in a year by the average value of a single purchase. The tricky bit is to arrive at a good estimate of the number of yearly purchases. Also, keep in mind that people often get more than just a coffee at Starbucks.',
};
const QUIZ_BACKGROUND_ITEM_4 = {
  bgImage: {
    mobileS: quiz4Url_mobileS,
    mobileS2x: quiz4Url_mobileS2x,
    mobile: quiz4Url_smallTablet,
    mobile2x: quiz4Url_smallTablet2x,
    smallTablet: quiz4Url_laptop,
    smallTablet2x: quiz4Url_laptop2x,
    tablet: quiz4Url,
    tablet2x: quiz4Url2x,
  },
  bgImageWebp: {
    mobileS: quiz4UrlWebp_mobileS,
    mobileS2x: quiz4UrlWebp_mobileS2x,
    mobile: quiz4UrlWebp_smallTablet,
    mobile2x: quiz4UrlWebp_smallTablet2x,
    smallTablet: quiz4UrlWebp_laptop,
    smallTablet2x: quiz4UrlWebp_laptop2x,
    tablet: quiz4UrlWebp,
    tablet2x: quiz4UrlWebp2x,
  },
};

const QUIZ_ITEM_5 = {
  question: (
    <>
      How many iPhones <span>can fit</span> into the Empire State Building?
    </>
  ),
  options: [
    {
      value: '100M',
      active: false,
    },
    {
      value: '1B',
      active: false,
    },
    {
      value: '10B',
      active: true,
    },
  ],
  attribute: {
    left: {
      bgImage: {
        mobileS: quiz5LeftUrl_mobileS,
        mobileS2x: quiz5LeftUrl_mobileS2x,
      },
      bgImageWebp: {
        mobileS: quiz5LeftUrlWebp_mobileS,
        mobileS2x: quiz5LeftUrlWebp_mobileS2x,
      },
    },
    right: {
      bgImage: {
        mobileS: quiz5RightUrl_mobileS,
        mobileS2x: quiz5RightUrl_mobileS2x,
      },
      bgImageWebp: {
        mobileS: quiz5RightUrlWebp_mobileS,
        mobileS2x: quiz5RightUrlWebp_mobileS2x,
      },
    },
  },
  position: {
    right: {
      desktop: {
        width: 1000,
        height: 2000,
        top: 'auto',
        left: 'auto',
        bottom: '-4000px',
        right: '-500px',
      },
      laptop: {
        width: 358,
        height: 358,
        top: 'auto',
        left: 'auto',
        bottom: '-90px',
        right: '-10000px',
      },
      tablet: {
        width: 272,
        height: 175,
        top: 'auto',
        left: 'auto',
        bottom: '-90px',
        right: '-10000px',
      },
      tabletSmall: {
        width: 200,
        height: 150,
        top: 'auto',
        left: 'auto',
        bottom: '-75px',
        right: '-10000px',
      },
      mobile: {
        width: 200,
        height: 150,
        top: 'auto',
        left: 'auto',
        bottom: '-75px',
        right: '-10000px',
      },
      mobileMedium: {
        width: 180,
        height: 120,
        top: 'auto',
        left: 'auto',
        bottom: '70px',
        right: '-10000px',
      },
      mobileSmall: {
        width: 180,
        height: 120,
        top: 'auto',
        left: 'auto',
        bottom: '90px',
        right: '-10000px',
      },
    },
    left: {
      desktop: {
        width: 477,
        height: 477,
        top: '125px',
        left: '-340px',
        bottom: 'auto',
        right: 'auto',
      },
      laptop: {
        width: 358,
        height: 358,
        top: '100px',
        left: '-245px',
        bottom: 'auto',
        right: 'auto',
      },
      tablet: {
        width: 330,
        height: 320,
        top: '115px',
        left: '-180px',
        bottom: 'auto',
        right: 'auto',
      },
      tabletSmall: {
        width: 233,
        height: 233,
        top: '135px',
        left: '-115px',
        bottom: 'auto',
        right: 'auto',
      },
      mobile: {
        width: 210,
        height: 225,
        top: '130px',
        left: '-80px',
        bottom: 'auto',
        right: 'auto',
      },
      mobileMedium: {
        width: 250,
        height: 250,
        top: '75px',
        left: '25px',
        bottom: 'auto',
        right: '-80px',
      },
      mobileSmall: {
        width: 250,
        height: 250,
        top: '90px',
        left: '0px',
        bottom: 'auto',
        right: '-80px',
      },
    },
  },
  correct:
    'This, in fact, is a simple-ish problem if you just realise that there is a finite amount of space in the building, which could be filled with the objects of a certain volume. Well done for bearing in mind that not all of the space can be used, as there is walls/furniture and that iPhones do not perfectly fill in all the gaps. Also well done on remembering the dimensions of the ESB!',
  wrong:
    'This, in fact, is a simple-ish problem if you just realise that there is a finite amount of space in the building, which could be filled with the objects of a certain volume. Just use your imagination to come up with reasonable dimensions of the ESB and bear in mind that not all of the space could be used, as there is walls/furniture, and that iPhones do not perfectly fill in all the gaps, i.e. assume some packing factor.',
};
const QUIZ_BACKGROUND_ITEM_5 = {
  bgImage: {
    mobileS: quiz5Url_mobileS,
    mobileS2x: quiz5Url_mobileS2x,
    mobile: quiz5Url_smallTablet,
    mobile2x: quiz5Url_smallTablet2x,
    smallTablet: quiz5Url_laptop,
    smallTablet2x: quiz5Url_laptop2x,
    tablet: quiz5Url,
    tablet2x: quiz5Url2x,
  },
  bgImageWebp: {
    mobileS: quiz5UrlWebp_mobileS,
    mobileS2x: quiz5UrlWebp_mobileS2x,
    mobile: quiz5UrlWebp_smallTablet,
    mobile2x: quiz5UrlWebp_smallTablet2x,
    smallTablet: quiz5UrlWebp_laptop,
    smallTablet2x: quiz5UrlWebp_laptop2x,
    tablet: quiz5UrlWebp,
    tablet2x: quiz5UrlWebp2x,
  },
};
export const QUIZ_LIST: Array<quizItemType> = [
  QUIZ_FIRST_SLIDE,
  QUIZ_ITEM_1,
  QUIZ_ITEM_2,
  QUIZ_ITEM_3,
  QUIZ_ITEM_4,
  QUIZ_ITEM_5,
  QUIZ_LAST_SLIDE,
];

export const QUIZ_BACKGROUND_LIST: Array<quizBackgroundType> = [
  QUIZ_BACKGROUND_ITEM_1,
  QUIZ_BACKGROUND_ITEM_1,
  QUIZ_BACKGROUND_ITEM_2,
  QUIZ_BACKGROUND_ITEM_3,
  QUIZ_BACKGROUND_ITEM_4,
  QUIZ_BACKGROUND_ITEM_5,
  QUIZ_BACKGROUND_ITEM_5,
];

export const STRUCTURE_BACKGROUND: BackgroundType = {
  bgImage: {
    mobileS: structureUrl_mobileS,
    mobileS2x: structureUrl_mobileS2x,
    tablet: structureUrl_laptop,
    tablet2x: structureUrl_laptop2x,
    smallTablet: structureUrl_smallTablet,
    smallTablet2x: structureUrl_smallTablet2x,
    desktop: structureUrl,
    desktop2x: structureUrl2x,
  },
  bgImageWebp: {
    mobileS: structureUrlWebp_mobileS,
    mobileS2x: structureUrlWebp_mobileS2x,
    tablet: structureUrlWebp_laptop,
    tablet2x: structureUrlWebp_laptop2x,
    smallTablet: structureUrlWebp_smallTablet,
    smallTablet2x: structureUrlWebp_smallTablet2x,
    desktop: structureUrlWebp,
    desktop2x: structureUrlWebp2x,
  },
};

export type EducationItemType = {
  background: BackgroundType;
  title: string;
  profile: {
    avatar: BackgroundType;
    name: string;
    position: string;
  };
  tags: Array<{ label: string }>;
};

const EDUCATION_ITEM_1 = {
  background: {
    bgImage: {
      mobileS: slide_1Url_mobileS,
      mobileS2x: slide_1Url_mobileS2x,
      tablet: slide_1Url_laptop,
      tablet2x: slide_1Url_laptop2x,
      smallTablet: slide_1Url_smallTablet,
      smallTablet2x: slide_1Url_smallTablet2x,
      desktop: slide_1Url,
      desktop2x: slide_1Url2x,
    },
    bgImageWebp: {
      mobileS: slide_1UrlWebp_mobileS,
      mobileS2x: slide_1UrlWebp_mobileS2x,
      tablet: slide_1UrlWebp_laptop,
      tablet2x: slide_1UrlWebp_laptop2x,
      smallTablet: slide_1UrlWebp_smallTablet,
      smallTablet2x: slide_1UrlWebp_smallTablet2x,
      desktop: slide_1UrlWebp,
      desktop2x: slide_1UrlWebp2x,
    },
  },
  title: 'What is the market size of tennis rackets in the USA?',
  profile: {
    avatar: {
      bgImage: {
        mobileS: avatarUrl_mobileS,
        mobileS2x: avatarUrl_mobileS2x,
        tablet: avatarUrl_laptop,
        tablet2x: avatarUrl_laptop2x,
        smallTablet: avatarUrl_smallTablet,
        smallTablet2x: avatarUrl_smallTablet2x,
        desktop: avatarUrl,
        desktop2x: avatarUrl2x,
      },
      bgImageWebp: {
        mobileS: avatarUrlWebp_mobileS,
        mobileS2x: avatarUrlWebp_mobileS2x,
        tablet: avatarUrlWebp_laptop,
        tablet2x: avatarUrlWebp_laptop2x,
        smallTablet: avatarUrlWebp_smallTablet,
        smallTablet2x: avatarUrlWebp_smallTablet2x,
        desktop: avatarUrlWebp,
        desktop2x: avatarUrlWebp2x,
      },
    },
    name: 'Bill Pitman',
    position: 'Award winning coach',
  },
  tags: [
    {
      label: '#marketsizing',
    },
    {
      label: '#BCG',
    },
    {
      label: '#Kearney',
    },
    {
      label: '#Somelongkeyword',
    },
  ],
};
const EDUCATION_ITEM_2 = {
  background: {
    bgImage: {
      mobileS: slide_2Url_mobileS,
      mobileS2x: slide_2Url_mobileS2x,
      tablet: slide_2Url_laptop,
      tablet2x: slide_2Url_laptop2x,
      smallTablet: slide_2Url_smallTablet,
      smallTablet2x: slide_2Url_smallTablet2x,
      desktop: slide_2Url,
      desktop2x: slide_2Url2x,
    },
    bgImageWebp: {
      mobileS: slide_2UrlWebp_mobileS,
      mobileS2x: slide_2UrlWebp_mobileS2x,
      tablet: slide_2UrlWebp_laptop,
      tablet2x: slide_2UrlWebp_laptop2x,
      smallTablet: slide_2UrlWebp_smallTablet,
      smallTablet2x: slide_2UrlWebp_smallTablet2x,
      desktop: slide_2UrlWebp,
      desktop2x: slide_2UrlWebp2x,
    },
  },
  title: 'How many trees were saved last year by the invention of Kindle?',
  profile: {
    avatar: {
      bgImage: {
        mobileS: avatarUrl_mobileS,
        mobileS2x: avatarUrl_mobileS2x,
        tablet: avatarUrl_laptop,
        tablet2x: avatarUrl_laptop2x,
        smallTablet: avatarUrl_smallTablet,
        smallTablet2x: avatarUrl_smallTablet2x,
        desktop: avatarUrl,
        desktop2x: avatarUrl2x,
      },
      bgImageWebp: {
        mobileS: avatarUrlWebp_mobileS,
        mobileS2x: avatarUrlWebp_mobileS2x,
        tablet: avatarUrlWebp_laptop,
        tablet2x: avatarUrlWebp_laptop2x,
        smallTablet: avatarUrlWebp_smallTablet,
        smallTablet2x: avatarUrlWebp_smallTablet2x,
        desktop: avatarUrlWebp,
        desktop2x: avatarUrlWebp2x,
      },
    },
    name: 'Bill Pitman',
    position: 'Award winning coach',
  },
  tags: [
    {
      label: '#marketsizing',
    },
    {
      label: '#BCG',
    },
    {
      label: '#Kearney',
    },
    {
      label: '#Somelongkeyword',
    },
  ],
};
const EDUCATION_ITEM_3 = {
  background: {
    bgImage: {
      mobileS: slide_3Url_mobileS,
      mobileS2x: slide_3Url_mobileS2x,
      tablet: slide_3Url_laptop,
      tablet2x: slide_3Url_laptop2x,
      smallTablet: slide_3Url_smallTablet,
      smallTablet2x: slide_3Url_smallTablet2x,
      desktop: slide_3Url,
      desktop2x: slide_3Url2x,
    },
    bgImageWebp: {
      mobileS: slide_3UrlWebp_mobileS,
      mobileS2x: slide_3UrlWebp_mobileS2x,
      tablet: slide_3UrlWebp_laptop,
      tablet2x: slide_3UrlWebp_laptop2x,
      smallTablet: slide_3UrlWebp_smallTablet,
      smallTablet2x: slide_3UrlWebp_smallTablet2x,
      desktop: slide_3UrlWebp,
      desktop2x: slide_3UrlWebp2x,
    },
  },
  title: 'How many barbers are there in London?',
  profile: {
    avatar: {
      bgImage: {
        mobileS: avatarUrl_mobileS,
        mobileS2x: avatarUrl_mobileS2x,
        tablet: avatarUrl_laptop,
        tablet2x: avatarUrl_laptop2x,
        smallTablet: avatarUrl_smallTablet,
        smallTablet2x: avatarUrl_smallTablet2x,
        desktop: avatarUrl,
        desktop2x: avatarUrl2x,
      },
      bgImageWebp: {
        mobileS: avatarUrlWebp_mobileS,
        mobileS2x: avatarUrlWebp_mobileS2x,
        tablet: avatarUrlWebp_laptop,
        tablet2x: avatarUrlWebp_laptop2x,
        smallTablet: avatarUrlWebp_smallTablet,
        smallTablet2x: avatarUrlWebp_smallTablet2x,
        desktop: avatarUrlWebp,
        desktop2x: avatarUrlWebp2x,
      },
    },
    name: 'Bill Pitman',
    position: 'Award winning coach',
  },
  tags: [
    {
      label: '#marketsizing',
    },
    {
      label: '#BCG',
    },
    {
      label: '#Kearney',
    },
    {
      label: '#Somelongkeyword',
    },
  ],
};
const EDUCATION_ITEM_4 = {
  background: {
    bgImage: {
      mobileS: slide_4Url_mobileS,
      mobileS2x: slide_4Url_mobileS2x,
      tablet: slide_4Url_laptop,
      tablet2x: slide_4Url_laptop2x,
      smallTablet: slide_4Url_smallTablet,
      smallTablet2x: slide_4Url_smallTablet2x,
      desktop: slide_4Url,
      desktop2x: slide_4Url2x,
    },
    bgImageWebp: {
      mobileS: slide_4UrlWebp_mobileS,
      mobileS2x: slide_4UrlWebp_mobileS2x,
      tablet: slide_4UrlWebp_laptop,
      tablet2x: slide_4UrlWebp_laptop2x,
      smallTablet: slide_4UrlWebp_smallTablet,
      smallTablet2x: slide_4UrlWebp_smallTablet2x,
      desktop: slide_4UrlWebp,
      desktop2x: slide_4UrlWebp2x,
    },
  },
  title: 'How many iPhones can fit into the Empire State Building?',
  profile: {
    avatar: {
      bgImage: {
        mobileS: avatarUrl_mobileS,
        mobileS2x: avatarUrl_mobileS2x,
        tablet: avatarUrl_laptop,
        tablet2x: avatarUrl_laptop2x,
        smallTablet: avatarUrl_smallTablet,
        smallTablet2x: avatarUrl_smallTablet2x,
        desktop: avatarUrl,
        desktop2x: avatarUrl2x,
      },
      bgImageWebp: {
        mobileS: avatarUrlWebp_mobileS,
        mobileS2x: avatarUrlWebp_mobileS2x,
        tablet: avatarUrlWebp_laptop,
        tablet2x: avatarUrlWebp_laptop2x,
        smallTablet: avatarUrlWebp_smallTablet,
        smallTablet2x: avatarUrlWebp_smallTablet2x,
        desktop: avatarUrlWebp,
        desktop2x: avatarUrlWebp2x,
      },
    },
    name: 'Bill Pitman',
    position: 'Award winning coach',
  },
  tags: [
    {
      label: '#marketsizing',
    },
    {
      label: '#BCG',
    },
    {
      label: '#Kearney',
    },
    {
      label: '#Somelongkeyword',
    },
  ],
};
const EDUCATION_ITEM_5 = {
  background: {
    bgImage: {
      mobileS: slide_5Url_mobileS,
      mobileS2x: slide_5Url_mobileS2x,
      tablet: slide_5Url_laptop,
      tablet2x: slide_5Url_laptop2x,
      smallTablet: slide_5Url_smallTablet,
      smallTablet2x: slide_5Url_smallTablet2x,
      desktop: slide_5Url,
      desktop2x: slide_5Url2x,
    },
    bgImageWebp: {
      mobileS: slide_5UrlWebp_mobileS,
      mobileS2x: slide_5UrlWebp_mobileS2x,
      tablet: slide_5UrlWebp_laptop,
      tablet2x: slide_5UrlWebp_laptop2x,
      smallTablet: slide_5UrlWebp_smallTablet,
      smallTablet2x: slide_5UrlWebp_smallTablet2x,
      desktop: slide_5UrlWebp,
      desktop2x: slide_5UrlWebp2x,
    },
  },
  title: 'What is the revenue of a typical Starbucks store?',
  profile: {
    avatar: {
      bgImage: {
        mobileS: avatarUrl_mobileS,
        mobileS2x: avatarUrl_mobileS2x,
        tablet: avatarUrl_laptop,
        tablet2x: avatarUrl_laptop2x,
        smallTablet: avatarUrl_smallTablet,
        smallTablet2x: avatarUrl_smallTablet2x,
        desktop: avatarUrl,
        desktop2x: avatarUrl2x,
      },
      bgImageWebp: {
        mobileS: avatarUrlWebp_mobileS,
        mobileS2x: avatarUrlWebp_mobileS2x,
        tablet: avatarUrlWebp_laptop,
        tablet2x: avatarUrlWebp_laptop2x,
        smallTablet: avatarUrlWebp_smallTablet,
        smallTablet2x: avatarUrlWebp_smallTablet2x,
        desktop: avatarUrlWebp,
        desktop2x: avatarUrlWebp2x,
      },
    },
    name: 'Bill Pitman',
    position: 'Award winning coach',
  },
  tags: [
    {
      label: '#marketsizing',
    },
    {
      label: '#BCG',
    },
    {
      label: '#Kearney',
    },
    {
      label: '#Somelongkeyword',
    },
  ],
};
export const EDUCATION_BACKGROUND: BackgroundType = {
  bgImage: {
    mobileS: educationUrl_mobileS,
    mobileS2x: educationUrl_mobileS2x,
    tablet: educationUrl_laptop,
    tablet2x: educationUrl_laptop2x,
    smallTablet: educationUrl_smallTablet,
    smallTablet2x: educationUrl_smallTablet2x,
    desktop: educationUrl,
    desktop2x: educationUrl2x,
  },
  bgImageWebp: {
    mobileS: educationUrlWebp_mobileS,
    mobileS2x: educationUrlWebp_mobileS2x,
    tablet: educationUrlWebp_laptop,
    tablet2x: educationUrlWebp_laptop2x,
    smallTablet: educationUrlWebp_smallTablet,
    smallTablet2x: educationUrlWebp_smallTablet2x,
    desktop: educationUrlWebp,
    desktop2x: educationUrlWebp2x,
  },
};

export const EDUCATION_LIST: Array<EducationItemType> = [
  EDUCATION_ITEM_1,
  EDUCATION_ITEM_2,
  EDUCATION_ITEM_3,
  EDUCATION_ITEM_4,
  EDUCATION_ITEM_5,
];

export const TEASER_BACKGROUND: BackgroundType = {
  bgImage: {
    mobileS: teaserUrl_mobileS,
    mobileS2x: teaserUrl_mobileS2x,
    tablet: teaserUrl_laptop,
    tablet2x: teaserUrl_laptop2x,
    smallTablet: teaserUrl_smallTablet,
    smallTablet2x: teaserUrl_smallTablet2x,
    desktop: teaserUrl,
    desktop2x: teaserUrl2x,
  },
  bgImageWebp: {
    mobileS: teaserUrlWebp_mobileS,
    mobileS2x: teaserUrlWebp_mobileS2x,
    tablet: teaserUrlWebp_laptop,
    tablet2x: teaserUrlWebp_laptop2x,
    smallTablet: teaserUrlWebp_smallTablet,
    smallTablet2x: teaserUrlWebp_smallTablet2x,
    desktop: teaserUrlWebp,
    desktop2x: teaserUrlWebp2x,
  },
};

export type PreorderListType = {
  isFirstSlide?: boolean;
  value?: React.ReactNode;
  isLastSlide?: boolean;
};

export const PREORDER_DESCRIPTION = [
  {
    isFirstSlide: true,
  },
  {
    value: (
      <>
        ‒ Average salary of an MBB consultant in year 3 is <span>~$150k</span>{' '}
        per year
      </>
    ),
  },
  {
    value: (
      <>
        ‒ Our course will cost you only <span>$9.99</span> (with a 90% discount)
      </>
    ),
  },
  {
    value: (
      <>
        ‒ In case you did not realise, this converts to a yearly ROI of{' '}
        <span>1,500,000.00%</span>
      </>
    ),
  },
  {
    value: (
      <>
        ‒ This could be the best deal of <span>YOUR LIFE</span>
      </>
    ),
    isLastSlide: true,
  },
];
