import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

function PreorderFormTop() {
  return (
    <Wrapper>
      <Title>
        Preorder before{' '}
        <TitleChange color={colors.red300}>08/30/2020</TitleChange> to get
        access to the entire course content for $9.99{' '}
        <TitleChange color="#FE9393" line>
          $99.99
        </TitleChange>
      </Title>
      <SubTitle>
        No pre-payment required. Course release date 15/12/2020
      </SubTitle>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const Title = styled.span`
  display: block;
  font-weight: bold;
  font-size: 32px;
  line-height: 150%;
  margin-bottom: 32px;
  color: ${colors.black400};
  text-align: center;
  @media (max-height: 820px) {
    font-size: 30px;
    margin:0 auto 15px;
    max-width: 90%;
    }
  @media (max-height: 700px) {
    font-size: 26px;
    margin-bottom: 15px;
  }
  ${media.laptop(css`
    font-size: 24px;
    margin-bottom: 24px;
    @media (max-height: 820px) {
      font-size: 24px;
      margin-bottom: 24px;
    }
    @media (max-height: 700px) {
      font-size: 20px;
      margin-bottom: 15px;
    }
  `)}
  ${media.tabletSmall(css`
    font-size: 20px;
  `)}
  ${media.mobile(css`
    font-size: 16px;
  `)}
`;

const SubTitle = styled.p`
  font-weight: 600;
  font-size: 26px;
  line-height: 150%;
  color: #bfbfbf;
  margin-bottom: 42px;
  text-align: center;
  @media (max-height: 820px) {
    font-size: 30px;
    margin-bottom: 30px;
  }
  @media (max-height: 760px) {
    font-size: 30px;
    margin-bottom: 15px;
  }
  @media (max-height: 700px) {
    font-size: 26px;
    max-width: 90%;
    margin: 0 auto 15px;
  }
  ${media.laptop(css`
    font-size: 20px;
    margin-bottom: 32px;
    @media (max-height: 820px) {
      font-size: 20px;
      margin-bottom: 32px;
    }
    @media (max-height: 760px) {
      font-size: 20px;
      margin-bottom: 30px;
    }
    @media (max-height: 700px) {
      font-size: 18px;
      margin-bottom: 20px;
    }
  `)}
  ${media.tabletSmall(css`
    font-size: 18px;
    margin-bottom: 24px;
  `)}
    ${media.mobile(css`
      font-size: 14px;
    `)}
`;

const TitleChange = styled.span<{ color?: string; line?: boolean }>`
  color: ${(props) => (props.color ? props.color : 'white')};
  text-decoration: ${(props) => (props.line ? 'line-through' : 'none')};
  text-decoration-color: ${(props) => (props.line ? props.color : 'none')};
`;

export default PreorderFormTop;
