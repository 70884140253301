import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

import { RootMenuItemType } from '../Header.constants';
import { media } from '@/utils/mixin';

import { LayoutContext } from '@/components/Layout/LayoutCtx';
import { scrollToElement } from '@/utils/common';

type Props = {
  menuItem: RootMenuItemType;
  SectionScroll: LayoutContext;
  setNavigationOpen: () => void;
};

function NavBlock({ menuItem, SectionScroll, setNavigationOpen }: Props) {
  const arrayRef = Object.values(SectionScroll.arrayRef);
  const { label, number, link } = menuItem;

  return (
    <NavItem data-menuanchor={link.substr(1)}>
      <ItemLink
        onClick={() => {
          scrollToElement(arrayRef[number].current, { offset: 0 });
          setNavigationOpen();
        }}
        href={link}
      >
        {label}
      </ItemLink>
    </NavItem>
  );
}

const NavItem = styled.li`
  padding: 0 15px;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: ${colors.white};
  transition: all 0.15s ease;
  cursor: pointer;
  ${media.laptop(css`
    font-size: 16px;
    padding: 0;
  `)}
  ${media.mobileLarge(css`
    line-height: 200%;
  `)}
  &:hover {
    color: ${colors.grayDark};
  }
`;

const ItemLink = styled.a`
  transition: all 0.15s ease-in-out;
`;

export default NavBlock;
