import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';

import Button from '@/components/Button';
import { media } from '@/utils/mixin';

type Props = {
  onClick: () => void;
  isCenter?: boolean;
};

function PreorderBlock({ onClick, isCenter }: Props) {
  return (
    <Wrapper isCenter={isCenter}>
      <Content>
        <Left>
          <ButtonWrapper>
            <Button onClick={onClick} variant="primary-second">
              Preorder for Free
            </Button>
          </ButtonWrapper>
        </Left>
        <Right>
          <Text>
            Preorder now to get access to the entire course content for $9.99{' '}
            <TextChanges>$99.99</TextChanges>
          </Text>
        </Right>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ isCenter?: boolean }>`
  position: relative;
  max-width: 798px;

  ${media.laptop(css`
    max-width: 626px;
  `)}
  ${media.tabletSmall(css`
    max-width: 526px;
  `)}
   ${media.mobile(css`
     margin-bottom: 48px;
   `)}
    ${media.mobileSmall(css`
      margin-bottom: 30px;
    `)}
   
   ${media.mobileLarge(css`
     max-width: 60vw;
   `)}
   
   ${(props) =>
     props.isCenter
       ? css`
           margin: 0 auto 183px;
         `
       : ''}
`;

const Content = styled.div`
  background: ${colors.white};
  box-shadow: 0 10.6667px 64px rgba(255, 255, 255, 0.32);
  border-radius: 26px;
  display: flex;
  justify-content: space-between;
  padding: 16px 30px 16px 16px;
  ${media.laptop(css`
    padding: 12px 20px 12px 12px;
    border-radius: 20px;
    box-shadow: 0 8px 48px rgba(255, 255, 255, 0.32);
  `)}
  ${media.mobileLarge(css`
    flex-direction: column;
    justify-content: flex-start;
    padding: 12px;
  `)}
`;
const Left = styled.div`
  flex: 1 1 1px;
`;
const Right = styled.div`
  flex: 0 0 370px;
  max-width: 370px;
  display: flex;
  align-items: center;
  ${media.laptop(css`
    flex: 0 0 300px;
    max-width: 300px;
  `)}
  ${media.tabletSmall(css`
    flex: 0 0 254px;
    max-width: 254px;
  `)}
  ${media.mobileLarge(css`
    flex: 1 0 auto;
    text-align: center;
  `)}
`;

const Text = styled.p`
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: ${colors.black400};
  ${media.laptop(css`
    font-size: 16px;
  `)}
  ${media.tabletSmall(css`
    font-size: 14px;
  `)}
  ${media.mobileLarge(css`
    line-height: 120%;
  `)}
    ${media.mobileSmall(css`
      font-size: 12px;
    `)}
`;
const TextChanges = styled.span`
  padding: 0 5px;
  color: ${colors.redInvalid};
  text-decoration: line-through;
  opacity: 0.6;
`;

const ButtonWrapper = styled.div`
  width: 360px;
  height: 85px;
  button {
    border-radius: 16px;
    color: ${colors.white};
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;

  }
  ${media.laptop(css`
    width: 270px;
    height: 64px;
    button {
      font-size: 18px;
      line-height: 26px;
      border-radius: 12px;
    }
  `)}
    ${media.tabletSmall(css`
      width: 220px;
      height: 64px;
      button {
        cursor: default;
      }
    `)}
  ${media.mobileLarge(css`
    margin-bottom: 12px;
    height: 40px;
    width: 100%;
    button {
      font-size: 14px;
      line-height: 20px;
      border-radius: 10px;
    }
  `)}
    ${media.mobileSmall(css`
      button {
        font-size: 14px;
        line-height: 20px;
        border-radius: 8px;
      }
    `)}
`;

export default PreorderBlock;
