import React from 'react';
import styled, { css } from 'styled-components';
import { PartnersItemType } from '@/modules/Home/Home.constants';
import { media } from '@/utils/mixin';

type Props = {
  partnersItem: PartnersItemType;
};

function PartnersItem({ partnersItem }: Props) {
  return <Item>{partnersItem.logo}</Item>;
}

const Item = styled.div`
  svg {
    width: auto;
  }
  ${media.laptop(css`
    svg {
      max-height: 36px;
      max-width: 272px;
      width: 100%;
      height: 100%;
    }
    &:nth-child(4) {
      svg {
        max-width: 115px;
        max-height: 24px;
      }
    }
    &:nth-child(5) {
      svg {
        max-width: 183px;
        max-height: 22px;
      }
    }
  `)}
  ${media.mobileLarge(css``)}
`;

export default PartnersItem;
