import React, { useState } from 'react';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

// import { convertRequestErrorToMap } from '@tager/web-core';
import { convertRequestErrorToMap, validators } from '@tager/web-core';
import { sendContactForm } from '@/services/requests';

import PreorderForm, { PreorderFormValues } from './PreorderForm';

type Props = {
  rowMarginBottom?: number;
  isStatic?: boolean;
  onClose?: () => void;
  isReadyBlock?: boolean;
};

function validate(
  values: PreorderFormValues
): FormikErrors<PreorderFormValues> {
  const errors: FormikErrors<PreorderFormValues> = {};

  if (validators.required(values.name)) {
    errors.name = 'Please enter your name';
  }

  if (validators.required(values.email)) {
    errors.email = 'Please enter your email address';
  } else if (validators.email(values.email)) {
    errors.email = 'Wrong format';
  }

  return errors;
}

function PreorderFormContainer({
  rowMarginBottom,
  onClose,
  isStatic,
  isReadyBlock,
}: Props) {
  const [isSentSuccess, setSentSuccess] = useState(false);

  function handleSubmit(
    values: PreorderFormValues,
    formikHelpers: FormikHelpers<PreorderFormValues>
  ) {
    console.log(values);

    sendContactForm(values)
      .then((response) => {
        formikHelpers.resetForm();
        setSentSuccess(true);
      })
      .catch((error) => {
        const errorMap = convertRequestErrorToMap(error) as FormikErrors<
          PreorderFormValues
        >;
        formikHelpers.setErrors(errorMap);
      })
      .finally(() => {
        formikHelpers.setSubmitting(false);
      });
  }

  return (
    <Formik<PreorderFormValues>
      onSubmit={handleSubmit}
      initialValues={{ name: '', email: '' }}
      validate={validate}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {(formProps) => (
        <PreorderForm
          {...formProps}
          isSentSuccess={isSentSuccess}
          rowMarginBottom={32}
          isStatic={isStatic}
          onClose={onClose}
          isReadyBlock={isReadyBlock}
        />
      )}
    </Formik>
  );
}

export default PreorderFormContainer;
