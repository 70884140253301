import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { ModalContainer } from '@/components/ModalBox';
import PreorderFormContainer from '@/components/modals/PreorderModals/componenets/PreorderForm/PreorderForm.container';
import { useLayoutContext } from '@/components/Layout/LayoutCtx';

function Ready() {
  const { arrayRef } = useLayoutContext();
  const { preorderRef } = arrayRef;

  return (
    <Wrapper className="section" ref={preorderRef}>
      <ReadyContainer>
        <Top>
          <Title>Ready to go?</Title>
          <Text>
            Enter your email and be the first to get full access to the video
            course on the release day with a 90% discount for $9.99{' '}
            <TextChange>$99.99</TextChange> only. You will get the best market
            sizing self-study tool and techniques out there.
          </Text>
        </Top>
        <Content>
          <ModalContainer width={800} widthLaptop={696} isReadyBlock>
            <PreorderFormContainer isStatic isReadyBlock />
          </ModalContainer>
        </Content>
      </ReadyContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  flex: 1 1 auto;
  background: ${colors.black600};
  .fp-tableCell {
    vertical-align: bottom;
  }
  ${media.mobile(css`
    padding: 80px 20px;
  `)}
`;

const ReadyContainer = styled.div`
  max-width: 1053px;
  margin: 0 auto;
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-height: 750px) {
    height: 100vh;
    justify-content: flex-end;
    padding-bottom: 10px;
  }
  ${media.laptop(css`
    max-width: 790px;
    @media (max-height: 750px) {
      height: 100vh;
      padding-bottom: 30px;
    }
    @media (max-height: 700px) {
      height: calc(100vh - 80px);
      justify-content: center;
      padding-bottom: 0px;
    }
  `)}
  ${media.tabletSmall(css`
    max-width: 690px;
  `)}
    ${media.mobile(css`
      height: auto;
    `)}
`;

const Top = styled.div`
  font-weight: bold;
  line-height: 150%;
  margin-bottom: 64px;
  color: ${colors.white};
  text-align: center;
  @media (max-height: 1050px) {
    margin-bottom: 20px;
  }
  @media (max-height: 710px) {
    margin-bottom: 15px;
  }
  ${media.laptop(css`
    margin-bottom: 48px;
    @media (max-height: 850px) {
      margin-bottom: 20px;
    }
    @media (max-height: 700px) {
      margin-bottom: 15px;
    }
  `)}
  ${media.tabletSmall(css`
    @media (max-height: 700px) {
      margin-bottom: 10px;
    }
  `)}
  ${media.mobile(css`
    margin-bottom: 32px;
  `)}
`;

const Title = styled.span`
  display: block;
  font-size: 96px;
  line-height: 150%;
  margin-bottom: 43px;
  @media (max-height: 1050px) {
    margin-bottom: 10px;
  }
  @media (max-height: 880px) {
  font-size: 80px;
  }
  @media (max-height: 740px) {
    font-size: 70px;
  }
  @media (max-height: 710px) {
    font-size: 60px;
  }
  ${media.laptop(css`
    font-size: 72px;
    margin-bottom: 32px;
    @media (max-height: 850px) {
      margin-bottom: 15px;
      font-size: 65px;
    }
    @media (max-height: 700px) {
      margin-bottom: 10px;
      font-size: 56px;
    }
  `)}
  ${media.tabletSmall(css`
    font-size: 54px;
    line-height: 100%;
    @media (max-height: 700px) {
      font-size: 48px;
    }
  `)}
  ${media.mobile(css`
    font-size: 32px;
    margin-bottom: 24px;
  `)}
  ${media.mobileSmall(css`
    font-size: 21px;
  `)}
`;

const Text = styled.p`
  font-size: 26.6667px;
  line-height: 150%;
  color: #bfbfbf;
  @media (max-height: 880px) {
    font-size: 22px;
  }
  @media (max-height: 740px) {
    font-size: 18px;
  }
  ${media.laptop(css`
    font-size: 20px;
    @media (max-height: 800px) {
      font-size: 18px;
    }
  `)};

  ${media.mobile(css`
    font-size: 16px;
  `)}
  ${media.mobileSmall(css`
    font-size: 14px;
    text-align: left;
  `)}
`;

const TextChange = styled.span`
  text-decoration: line-through;
  color: ${colors.pink};
`;

const Content = styled.div`
  ${media.mobile(css`
    margin: 0 -20px;
  `)}
`;

export default Ready;
