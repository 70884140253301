import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import FullPage from '@/modules/Home/components/FullPage';

function Home() {
  return (
    <Wrapper>
      <FullPage />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${media.tabletSmall(css`
    & > div {
      width: 100%;
    }
  `)}
`;

export default Home;
