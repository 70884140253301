import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';

import { PreorderListType } from '@/modules/Home/Home.constants';
import CourseLaunchText from './components/CourseLaunchText';
import PartnersBlock from '@/modules/Home/components/Hero/components/PartnersBlock';

type Props = {
  preorderItem: PreorderListType;
};

function PreorderSlide({ preorderItem }: Props) {
  return (
    <Component>
      <Content>
        <CourseLaunchText text={preorderItem.value} />
      </Content>
      {preorderItem.isLastSlide ? <PartnersBlock isHero={false} /> : null}
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${media.tablet(css`
    padding: 0 45px;
  `)}
  ${media.tabletSmall(css`
    padding: 0 40px;
  `)}
    ${media.mobile(css`
      padding: 0 20px;
    `)}
`;
const Content = styled.div`
  max-width: 1300px;
  & + div {
    margin-top: 180px;
    @media (max-height: 750px) {
      margin-top: 140px;
    }
    @media (max-height: 700px) {
      margin-top: 110px;
    }
  }
  ${media.laptop(css`
    max-width: 970px;
    & + div {
      margin-top: 130px;
      @media (max-height: 700px) {
        margin-top: 100px;
      }
    }
  `)}
  ${media.tabletSmall(css`
    max-width: 690px;
    & + div {
      margin-top: 40px;
    }
  `)}
  ${media.mobile(css`
    & + div {
      margin-top: 90px;
    }
  `)}
`;

export default PreorderSlide;
