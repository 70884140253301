import styled, { css } from 'styled-components';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

function getBorderColor(
  props: { invalid?: boolean; active?: boolean } = {}
): string {
  return props.invalid ? colors.red : 'rgba(166, 166, 166, 0.5)';
}

export const Input = styled.input<{
  withPrefix?: boolean;
  invalid?: boolean;
}>`
  width: 100%;
  min-height: 96px;
  box-sizing: border-box;
  border-radius: 12px;
  border: 1px solid ${(props) =>
    props.invalid ? colors.red : 'rgba(166, 166, 166, 0.5)'};
  font-weight: 600;
  font-size: 26px;
  line-height: 150%;
  padding: 0 42px;
  background-color: transparent;
  transition: all 0.4s ease-in-out;
  position: relative;
  @media (max-height: 940px) {
    min-height: 80px;
  }
  @media (max-height: 890px) {
   font-size: 24px;
  }
  @media (max-height: 820px) {
    min-height: 65px;
  }
  ${(props) =>
    props.withPrefix
      ? css`
          padding-left: 45px;
        `
      : ''};
  color: ${(props) => (props.invalid ? colors.red : '#1A1A1A')};

  ${media.laptop(css`
    min-height: 72px;
    font-size: 20px;
    padding: 0 32px;
    @media (max-height: 720px) {
      min-height: 60px;
    }
  `)}
  ${media.tabletSmall(css`
    min-height: 72px;
    font-size: 18px;
    padding: 0 32px;
    @media (max-height: 700px) {
      min-height: 60px;
    }
  `)}
    ${media.mobile(css`
      min-height: 54px;
      font-size: 14px;
      padding: 0 12px;
    `)}

  &::placeholder {
    color: ${(props) => (props.invalid ? colors.red : '#1A1A1A')};
    transition: all 0.4s ease-in-out;
  }

  &:focus {
    border: 1px solid #a6a6a6;
    &::placeholder {
      opacity: 0;
      visibility: hidden;
    }
  }
`;

export const prefixCss = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 16px;
  pointer-events: none;
`;

export const Prefix = styled.svg`
  ${prefixCss};
`;
