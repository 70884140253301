import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { NavbarItemType } from '../../../Footer.constants';
import { LayoutContext } from '@/components/Layout/LayoutCtx';
import { scrollToElement } from '@/utils/common';

type Props = {
  navbarItem: NavbarItemType;
  SectionScroll: LayoutContext;
};

function FooterNavItem({ navbarItem, SectionScroll }: Props) {
  const arrayRef = Object.values(SectionScroll.arrayRef);

  const { label, link, number } = navbarItem;

  return (
    <Item data-menuanchor={link.substr(1)}>
      <ItemLink
        onClick={() => {
          scrollToElement(arrayRef[number].current, { offset: 0 });
        }}
        href={link}
      >
        {label}
      </ItemLink>
    </Item>
  );
}

const Item = styled.div`
  color: ${colors.white};
  font-weight: 600;
  font-size: 26px;
  line-height: 150%;
  transition: all 0.4s ease-in-out;
  ${media.laptop(css`
    font-size: 20px;
  `)}
  ${media.tabletSmall(css`
    font-size: 16px;
  `)}
  ${media.mobile(css`
    font-size: 14px;
  `)}
  ${media.mobileLarge(css`
    font-size: 12px;
    line-height: 200%;
  `)}
  &:hover {
    opacity: 0.7;
  }
`;
const ItemLink = styled.a``;

export default FooterNavItem;
