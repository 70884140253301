import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Swiper from 'swiper';

import { breakpoints, colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { useMedia } from '@tager/web-core';
import { useLayoutContext } from '@/components/Layout/LayoutCtx';
import { ReactComponent as SlideArrow } from '@/assets/svg/slide-arrow.svg';
import Picture from '@/components/Picture';
import {
  EDUCATION_BACKGROUND,
  EDUCATION_LIST,
} from '@/modules/Home/Home.constants';

import SectionTop from '@/components/SectionTop';
import EducationSlide from './components/EducationSlide';
import ListInclude from './components/ListInclude';

function Education() {
  const isDesktop = useMedia(
    `(min-width: ${breakpoints.laptop}px) and (max-height: 850px)`
  );

  const [isHidden, setHidden] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { bgImage, bgImageWebp } = EDUCATION_BACKGROUND;

  const { arrayRef } = useLayoutContext();
  const { answersRef } = arrayRef;

  useEffect(() => {
    if (isDesktop) {
      setHidden(true);
    } else setHidden(false);
  }, [isDesktop]);

  useEffect(() => {
    if (containerRef.current) {
      const swiper = new Swiper(containerRef.current, {
        slidesPerView: 1,
        speed: 900,
        autoplay: {
          delay: 3200,
        },
        navigation: {
          nextEl: '.slider-next',
          prevEl: '.slider-prev',
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: true,
        },
        breakpoints: {
          1024: {
            speed: 800,
          },
        },
      });
      return () => swiper.destroy(true, true);
    }
  }, []);

  return (
    <Wrapper className="section" ref={answersRef}>
      {/*<Background>*/}
      {/*  {bgImage && bgImageWebp ? (*/}
      {/*    <Picture*/}
      {/*      // desktop={{*/}
      {/*      //   src: bgImage.desktop,*/}
      {/*      //   src2x: bgImage.desktop2x,*/}
      {/*      //   webp: bgImageWebp.desktop,*/}
      {/*      //   webp2x: bgImageWebp.desktop2x,*/}
      {/*      // }}*/}
      {/*      tabletLarge={{*/}
      {/*        src: bgImage.tablet,*/}
      {/*        src2x: bgImage.tablet2x,*/}
      {/*        webp: bgImageWebp.tablet,*/}
      {/*        webp2x: bgImageWebp.tablet2x,*/}
      {/*      }}*/}
      {/*      tabletSmall={{*/}
      {/*        src: bgImage.smallTablet,*/}
      {/*        src2x: bgImage.smallTablet2x,*/}
      {/*        webp: bgImageWebp.smallTablet,*/}
      {/*        webp2x: bgImageWebp.smallTablet2x,*/}
      {/*      }}*/}
      {/*      mobileSmall={{*/}
      {/*        src: bgImage.mobileS,*/}
      {/*        src2x: bgImage.mobileS2x,*/}
      {/*        webp: bgImageWebp.mobileS,*/}
      {/*        webp2x: bgImageWebp.mobileS2x,*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  ) : null}*/}
      {/*</Background>*/}
      <Inner>
        <Top>
          <SectionTop title="What You’ll Learn" />
          <SliderWrapper>
            <Container className="swiper-container" ref={containerRef}>
              <List className="swiper-wrapper">
                {EDUCATION_LIST.map((slideItem, index) => (
                  <CardCell key={index} className="swiper-slide">
                    <EducationSlide slideItem={slideItem} />
                  </CardCell>
                ))}
              </List>
            </Container>
            <SliderEducationPagination className="swiper-pagination" />
            <ButtonWrapper>
              <ButtonSlider next className="slider-next">
                <SlideArrow />
              </ButtonSlider>
            </ButtonWrapper>
            <ButtonWrapper left>
              <ButtonSlider className="slider-prev">
                <SlideArrow />
              </ButtonSlider>
            </ButtonWrapper>
          </SliderWrapper>
        </Top>
        <Bottom>
          <SectionTop title="What’s Included" />
          <ListInclude />
        </Bottom>
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  flex: 1 1 auto;
  position: relative;
  background-color: ${colors.black};
  ${media.mobile(css`
    padding: 80px 0;
  `)}
  .fp-tableCell {
    vertical-align: bottom;
  }
`;

const Inner = styled.div`
  height: calc(100vh - 100px);
  display: flex;
  flex-direction: column;
  @media (max-height: 870px) {
    height: 100vh;
  }
  @media (max-height: 850px) {
    height: calc(100vh - 100px);
  }
  @media (max-height: 800px) {
    height: 100vh;
  }
  ${media.laptop(css`
    @media (max-height: 850px) {
      height: 100vh;
    }
    @media (max-height: 800px) {
      height: calc(100vh - 100px);
  `)}
  ${media.tablet(css`
    height: 100vh;
    @media (max-height: 750px) {
      height: calc(100vh - 100px);
    }
  `)}
  ${media.mobile(css`
    height: auto;
    display: block;
  `)}
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const SliderWrapper = styled.div`
  position: relative;
  max-width: 970px;
  padding-bottom: 80px;
  width: 100%;
  margin: 0 auto;
  @media (max-height: 1000px) {
    max-width:750px;
  }
  @media (max-height: 820px) {
    max-width: 970px;
  }
  @media (max-height: 800px) {
    max-width:750px;
  }
  @media (max-height: 680px) {
    max-width: 690px;
  }
  ${media.laptop(css`
    max-width: 970px;
    padding-bottom: 80px;
    @media (max-height: 1000px) {
      max-width: 750px;
    }
    @media (max-height: 880px) {
      max-width: 690px;
      padding-bottom: 40px;
    }
    @media (max-height: 820px) {
      max-width: 750px;
      padding-bottom: 80px;
    }
    @media (max-height: 680px) {
      max-width: 690px;
    }
  `)}
  ${media.tablet(css`
    max-width: 600px;
  `)}
  ${media.mobileLarge(css`
    max-width: 93.8%;
    padding-bottom: 103px;
  `)}
`;
const Container = styled.div`
  border-radius: 32px;
  box-shadow: 0 0 85.3333px rgba(255, 255, 255, 0.06);
  ${media.laptop(css`
    border-right: 24px;
  `)}
  ${media.mobile(css`
    border-right: 16px;
  `)}
`;
const List = styled.div``;

const CardCell = styled.div`
  width: 100%;
  height: 100%;
`;

const SliderEducationPagination = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 30px;
  height: 28px;
  z-index: 7;

  .swiper-pagination-bullet {
    width: 18px;
    height: 18px;
    background: ${colors.white};
    border-radius: 100px;
    opacity: 1;
    transition: all 0.3s linear;
    margin: 0 13px;

    &-active {
      width: 28px;
      height: 28px;
      border: 5px solid ${colors.white};
      background: transparent;
      //transform: rotate(360deg);
    }
    &:hover {
    opacity: 0.6;
    }
  }
    
  ${media.laptop(css`
    bottom: 30px !important;
    height: 24px;
    @media (max-height: 880px) {
      bottom: 10px !important;
    }
    @media (max-height: 850px) {
      bottom: -20px !important;
    }
    @media (max-height: 800px) {
      bottom: 30px !important;
    }

    .swiper-pagination-bullet {
      width: 14px;
      height: 14px;
      border-radius: 10px;
      margin: 0 10px;
      &-active {
        width: 24px;
        height: 24px;
        border: 4px solid ${colors.white};
        border-radius: 12px;
      }
    }
  `)}
  ${media.tabletSmall(css`
    .swiper-pagination-bullet {
      cursor: default;
    }
  `)}
  ${media.mobile(css`
    bottom: 45px !important;
    z-index: 1;
  `)}
    ${media.mobileSmall(css`
      bottom: 45px !important;
      z-index: 1;
      height: 14px;
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        margin: 0 13px;
        &-active {
          width: 14px;
          height: 14px;
          border: 3px solid ${colors.white};
          border-radius: 10px;
        }
      }
    `)}
    ${media.mobileSmall(css`
      .swiper-pagination-bullet {
         margin: 0 10px;

    `)}
`;
const ButtonWrapper = styled.div<{ left?: boolean }>`
  ${(props) => css`
    position: absolute;
    top: calc(50% + 60px);
    transform: translateY(calc(-50%));
    z-index: 7;
    bottom: 0;
    width: 48px;
    ${props.left
      ? css`
            right: auto;
            left: -24px;
            @media (max-height: 680px) {
                left: -18px;
            }
            ${media.laptop(css`
              left: -24px;
            `)}
            ${media.tablet(css`
              left: -18px;
            `)}
            ${media.mobile(css`
              left: 17px;
            `)}
          `
      : css`
            right: -24px;
            left: auto;
            @media (max-height: 680px) {
                right: -18px;
            }
            ${media.laptop(css`
              right: -24px;
            `)}
            ${media.tablet(css`
              right: -18px;
            `)}
            ${media.mobile(css`
              right: 17px;
            `)}
          `};
    @media (max-height: 680px) {
      width: 36px;
    }
    ${media.laptop(css`
      width: 48px;
    `)};
    ${media.tablet(css`
      width: 36px;
      top: calc(50% + 40px);
    `)};
    ${media.mobile(css`
      top: auto;
      bottom: 20px;
    `)}
  `}
`;
const ButtonSlider = styled.button<{ next?: boolean }>`
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
  border: 2.66667px dashed ${colors.white};
  border-radius: 100%;
  transition: all 0.3s linear;
  position: relative;
  
    &:hover {
          box-shadow: 0 3px 20px rgba(255, 255, 255, 0.32);
      &:before {
        opacity: 1;
      }
    }

    &:not(:active) {
      &:after {
        animation: bubble-in 0.3s;
      }
    }

    @keyframes bubble-in {
      0% {
        opacity: 1;
        transform: scale(1);
      }
      100% {
        opacity: 0;
        transform: scale(1.5);
      }
    }

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: inherit;
    }

    &:before {
      opacity: 0;
      box-shadow: 0 2px 12px 2px rgba(0, 0, 0, 0.35);
      transition: opacity 0.3s;
      z-index: -1;
    }

    &:after {
      opacity: 0;
      background-color: ${colors.white};
      z-index: -2;
    }
  svg {
    transform: ${(props) => (!props.next ? 'rotate(180deg)' : 'rotate(0deg)')};
      width: 20px;
      height: 20px;
    fill-opacity: 1;
  }
  @media (max-height: 680px) {
    height: 36px;
    svg {
      width: 10px;
      height: 20px;
    }
  }
  ${media.laptop(css`
    height: 48px;
    svg {
      width: 10px;
      height: 20px;
    }
  `)}
  ${media.tablet(css`
    height: 36px;
    cursor: default;
    svg {
      width: 10px;
      height: 20px;
    }
  `)}
    ${media.mobileLarge(css`
      svg {
        width: 10px;
        height: 20px;
      }
    `)}
`;

const Top = styled.div`
  flex: 1 1 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-height: 870px) {
    justify-content: flex-end;
  }
  @media (max-height: 850px) {
    justify-content: center;
  }
  @media (max-height: 800px) {
    justify-content: flex-end;
    margin-bottom: 50px;
  }
  @media (max-height: 700px) {
    margin-bottom: 0;
  }
  @media (max-width: 1025px) and (max-height: 850px) {
    display: block;
  }
  ${media.tablet(css`
    @media (max-height: 850px) {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0;
    }
    @media (max-height: 750px) {
      justify-content: center;
      margin-bottom: 0;
    }
  `)}
`;
const Bottom = styled.div`
  flex: 0 0 100px;
  margin-bottom: 20px;
  @media (max-height: 870px) {
    margin-bottom: 10px;
  }
  @media (max-height: 850px) {
    display: none;
  }
  @media (max-width: 1025px) and (max-height: 850px) {
    display: block;
  }
  ${media.tablet(css`
    flex: 0 0 80px;
    @media (max-height: 850px) {
      display: block;
    }
    @media (max-height: 750px) {
      display: none;
    }
  `)}
`;
export default Education;
