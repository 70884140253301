import React, { useRef, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import Swiper from 'swiper';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import Picture from '@/components/Picture';

import { QUIZ_BACKGROUND_LIST, QUIZ_LIST } from '../../Home.constants';

import FirstSlide from './components/FirstSlide';
import LastSlide, { ContentLastSlider } from './components/LastSlide';
import QuizSlide, {
  Attribute,
  ButtonSlider,
  Content,
} from './components/QuizSlide';
import { useLayoutContext } from '@/components/Layout/LayoutCtx';
import ContentContainer from '@/components/ContentContainer';

function Quiz() {
  const [isBackground, setBackground] = useState<number>(0);
  const [isLast, setLast] = useState(false);
  const [isRight, setRight] = useState(false);
  const [isWrong, setWrong] = useState(false);
  const [isAnswer, setAnswer] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!containerRef.current) return;
    const swiper: any = new Swiper(containerRef.current, {
      slidesPerView: 1,
      spaceBetween: 10,
      speed: 1000,
      navigation: {
        nextEl: '.slide-next',
        prevEl: '.slide-prev',
      },
      on: {
        slideChange: () => changeBackground(swiper.activeIndex, swiper),
      },
      pagination: {
        el: '.main-pagination',
        type: 'bullets',
        clickable: true,
      },
      breakpoints: {
        600: {
          spaceBetween: 15,
        },
        650: {
          spaceBetween: 30,
        },
        730: {
          spaceBetween: 20,
        },
        850: {
          spaceBetween: 60,
        },
        950: {
          spaceBetween: 100,
        },
        1023: {
          spaceBetween: 10,
        },
        1025: {
          spaceBetween: 20,
        },
        1100: {
          spaceBetween: 30,
        },
        1150: {
          spaceBetween: 50,
        },
        1220: {
          spaceBetween: 80,
        },
        1350: {
          spaceBetween: 140,
        },
        1400: {
          spaceBetween: 110,
        },
        1440: {
          spaceBetween: 150,
        },
        1500: {
          spaceBetween: 50,
        },
        1580: {
          spaceBetween: 90,
        },
        1800: {
          spaceBetween: 200,
        },
        1900: {
          spaceBetween: 225,
        },
      },
    });
  }, []);

  function changeBackground(index: number, swiper: any) {
    setBackground(index);
    setAnswer(false);
    setWrong(false);
    setRight(false);
  }

  function getBackgroundSlide(isBackground: number) {
    if (isBackground > 0 && isBackground < 6) {
      return QUIZ_BACKGROUND_LIST[isBackground];
    }
    return null;
  }

  const background = getBackgroundSlide(isBackground);
  const { arrayRef } = useLayoutContext();
  const { questionsRef, teaserRef } = arrayRef;

  return (
    <Wrapper className="section" ref={questionsRef}>
      <Inner>
        {background ? (
          <BackgroundImage>
            <Picture
              desktop={{
                src: background.bgImage.tablet,
                src2x: background.bgImage.tablet2x,
                webp: background.bgImageWebp.tablet,
                webp2x: background.bgImageWebp.tablet2x,
              }}
              laptop={{
                src: background.bgImage.smallTablet,
                src2x: background.bgImage.smallTablet2x,
                webp: background.bgImageWebp.smallTablet,
                webp2x: background.bgImageWebp.smallTablet2x,
              }}
              mobileLarge={{
                src: background.bgImage.mobile,
                src2x: background.bgImage.mobile2x,
                webp: background.bgImageWebp.mobile,
                webp2x: background.bgImageWebp.mobile2x,
              }}
              mobileSmall={{
                src: background.bgImage.mobileS,
                src2x: background.bgImage.mobileS2x,
                webp: background.bgImageWebp.mobileS,
                webp2x: background.bgImageWebp.mobileS2x,
              }}
            />
          </BackgroundImage>
        ) : null}
        <SliderContainer className="swiper-container" ref={containerRef}>
          <List className="swiper-wrapper">
            {QUIZ_LIST.map((sliderItem, index) => {
              if (sliderItem.isFirstSlide) {
                return (
                  <QuizCell
                    isFirstSlide={sliderItem.isFirstSlide}
                    className="swiper-slide"
                    key={index}
                  >
                    <FirstSlide />
                  </QuizCell>
                );
              } else if (sliderItem.isLastSlide) {
                return (
                  <QuizCell
                    isLastSlide={sliderItem.isLastSlide}
                    className="swiper-slide"
                    key={index}
                  >
                    <LastSlide teaserRef={teaserRef} />
                  </QuizCell>
                );
              } else {
                return (
                  <QuizCell className="swiper-slide" key={index}>
                    <QuizSlide
                      isAnswer={isAnswer}
                      setAnswer={() => setAnswer(true)}
                      sliderItem={sliderItem}
                      isRight={isRight}
                      setRight={() => setRight(true)}
                      isWrong={isWrong}
                      setWrong={() => setWrong(true)}
                    />
                  </QuizCell>
                );
              }
            })}
          </List>
          <SliderPagination isLast={isLast} className="main-pagination" />
        </SliderContainer>
      </Inner>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  flex: 1 1 auto;
  background: ${colors.black600};
  position: relative;

  .fp-tableCell {
    vertical-align: top;
  }
`;

const BackgroundImage = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Inner = styled.div`
  display: flex;
  height: calc(100vh - 110px);
  margin-top: 110px;
  align-items: center;
  overflow: hidden;
  position: relative;
  ${media.mobile(css`
    height: auto;
    margin-top: 0;
  `)}
`;

const SliderContainer = styled.div`
  max-width: 1300px;
  overflow: visible;
  ${media.laptop(css`
    max-width: 970px;
  `)}
  ${media.tabletSmall(css`
    max-width: 690px;
  `)}
  ${media.mobile(css`
    max-width: 87.5%;
  `)}
`;

const List = styled.div`
  display: flex;
  align-items: flex-end;
`;

const QuizCell = styled.div<{ isFirstSlide?: boolean; isLastSlide?: boolean }>`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 0 100px;
  transition: all 0.3s linear;
  position: relative;

  &.swiper-slide-active {
    ${Content} {
      visibility: visible;
      opacity: 1;
    }
  }

  @media (max-height: 1020px) {
    padding: 0 0 40px;
  }
  @media (max-height: 900px) {
    padding: 0 0 60px;
  }
  @media (max-height: 820px) {
    padding: 0 0 40px;
  }
  @media (max-height: 750px) {
    padding: 0 0 60px;
  }

  ${media.laptop(css`
    padding: 0 0 180px;
    @media (max-height: 1020px) {
      padding: 0 0 100px;
    }
    @media (max-height: 820px) {
      padding: 0 0 60px;
    }
    @media (max-height: 760px) {
      display: flex;
      align-items: center;
      padding: 80px 0 140px;
    }
  `)}
  
  ${media.tablet(css`
    padding: 80px 0 80px;
  `)}
  
  ${media.mobile(css`
    padding: 80px 0 120px;
  `)}
  
  ${media.mobileSmall(css`
    @media (max-height: 670px) {
      padding: 80px 0;
    }
  `)}

  img {
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s linear;
  }
  ${(props) =>
    props.isFirstSlide
      ? css`
          ${ContentContainer} {
            position: relative;
            pointer-events: none;
            z-index: 1;
            visibility: hidden;
          }
          ${ButtonSlider} {
            transition: opacity, z-index 0.2s ease-in-out 0.4s;
            opacity: 1;
            visibility: visible;
            z-index: 0;
            background-color: ${colors.black500};
            left: 0;
            right: 0;
          }
          &.swiper-slide-active {
            ${ContentContainer} {
              pointer-events: auto;
              visibility: visible;
            }
            opacity: 1;
            ${ButtonSlider} {
              opacity: 0;
              visibility: hidden;
              background-color: ${colors.black500};
              box-shadow: none;
              z-index: -1;
            }
          }
        `
      : css`
          &.swiper-slide-active {
            ${Attribute} {
              opacity: 1;
              visibility: visible;
            }
            ${ButtonSlider} {
              display: none;
            }

            img {
              opacity: 1;
              visibility: visible;
            }
          }
        `}
  ${(props) =>
    props.isLastSlide
      ? css`
          ${ContentLastSlider} {
            visibility: hidden;
          }
          ${ButtonSlider} {
            transition: opacity, background-color 0.2s ease-in-out 0.4s;
            background-color: ${colors.black500};
            opacity: 1;
            visibility: visible;
            left: 0;
            right: 0;
          }
          &.swiper-slide-active {
            opacity: 1;
            ${ContentLastSlider} {
              visibility: visible;
            }
            ${ButtonSlider} {
              opacity: 0;
              visibility: hidden;
              background-color: transparent;
              box-shadow: none;
            }
          }
        `
      : css`
          &.swiper-slide-active {
            ${Attribute} {
              opacity: 1;
              visibility: visible;
            }
            ${ButtonSlider} {
              display: none;
            }
            img {
              opacity: 1;
              visibility: visible;
            }
          }
        `}
`;

const SliderPagination = styled.div<{ isLast: boolean }>`
  ${(props) =>
    props.isLast
      ? css`
          display: none;
        `
      : null};
  
  position: absolute;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 28px;
  @media (max-height: 900px) {
    bottom: 0!important;
  }
  .swiper-pagination-bullet {
    width: 18px;
    height: 18px;
    background: ${colors.white};
    border-radius: 100px;
    opacity: 1;
    margin: 0 13px!important;
    transition: all 0.3s linear;
    &-active {
      width: 28px;
      height: 28px;
      border: 5px solid ${colors.white};
      background: transparent;
      transform: rotate(360deg);
    }
  }
  
  ${media.laptop(css`
    height: 24px;
    .swiper-pagination-bullet {
      width: 14px;
      height: 14px;
      border-radius: 10px;
      &-active {
        width: 24px;
        height: 24px;
        border: 4px solid ${colors.white};
        border-radius: 12px;
      }
    }
    @media (max-height: 1020px) {
      bottom: 20px !important;
    }
    @media (max-height: 760px) {
      bottom: 80px !important;
    }
  `)}
  ${media.tablet(css`
    bottom: 40px !important;
    @media (max-height: 700px) {
      bottom: 50px !important;
    }
  `)}
  
  ${media.tabletSmall(css`
    bottom: 30px !important;
    .swiper-pagination-bullet {
      width: 14px;
      height: 14px;
      border-radius: 10px;
      &-active {
        width: 24px;
        height: 24px;
        border: 4px solid ${colors.white};
        border-radius: 12px;
      }
    }
  `)}
    ${media.mobile(css`
      bottom: 71px !important;
    `)}
    ${media.mobileSmall(css`
      bottom: 71px !important;
      height: 14px;
      .swiper-pagination-bullet {
        width: 10px;
        height: 10px;
        &-active {
          width: 14px;
          height: 14px;
          border: 3px solid ${colors.white};
          border-radius: 10px;
        }
      }
    `)}
`;

export default Quiz;
