import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';

import { ReactComponent as SuccessIcon } from '@/assets/svg/success.svg';

type Props = {
  isSentSuccess: boolean;
  isReadyBlock?: boolean;
};

function MessageSuccess({ isSentSuccess, isReadyBlock }: Props) {
  return (
    <Message isSentSuccess={isSentSuccess} isReadyBlock={isReadyBlock}>
      <ImgWrapper>
        <SuccessIcon />
      </ImgWrapper>
      <Title>You’re all set up!</Title>
      <Subtitle>
        You'll be among the first to know about our course updates. Stay tuned!
      </Subtitle>
    </Message>
  );
}

const ImgWrapper = styled.div`
  margin-bottom: 22px;
  svg {
    width: 226px;
    height: 226px;
  }
  ${media.laptop(css`
    margin-bottom: 16px;
    svg {
      width: 170px;
      height: 170px;
    }
  `)} ${media.mobile(css`
    margin-bottom: 32px;
    svg {
      width: 130px;
      height: 130px;
    }
  `)}
`;

export const Title = styled.span`
  display: block;
  font-weight: bold;
  font-size: 48px;
  line-height: 150%;
  color: ${colors.black400};
  margin-bottom: 40px;
  ${media.laptop(css`
    font-size: 36px;
    margin-bottom: 32px;
  `)}
  ${media.mobile(css`
    font-size: 21px;
    margin-bottom: 16px;
  `)}
`;

const Subtitle = styled.p`
  text-align: center;
  font-weight: 600;
  color: ${colors.black400};
  font-size: 26px;
  line-height: 150%;
  max-width: 600px;
  ${media.laptop(css`
    font-size: 20px;
    max-width: 570px;
  `)}
    ${media.tablet(css`
      max-width: 500px;
    `)}
  ${media.mobile(css`
    font-size: 14px;
  `)}
`;

const Message = styled.div<{ isSentSuccess: boolean; isReadyBlock?: boolean }>`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
  ${(props) =>
    props.isSentSuccess
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : css`
          visibility: hidden;
          opacity: 0;
        `}

  ${(props) =>
    props.isReadyBlock
      ? css`
          @media (max-height: 920px) {
            ${Title} {
              margin-bottom: 10px;
            }
          }
          @media (max-height: 840px) {
            ${ImgWrapper} {
              svg {
                width: 200px;
                height: 200px;
              }
              margin-bottom: 16px;
            }
            ${Title} {
              font-size: 36px;
            }
          }
          @media (max-height: 700px) {
            ${ImgWrapper} {
              svg {
                width: 170px;
                height: 170px;
              }
            }

            ${Subtitle} {
              line-height: 120%;
              font-size: 24px;
            }
          }
          ${media.tablet(css`
            @media (max-height: 920px) {
              ${Title} {
                font-size: 36px;
                margin-bottom: 32px;
              }
            }
            @media (max-height: 840px) {
              ${ImgWrapper} {
                margin-bottom: 16px;
                svg {
                  width: 170px;
                  height: 170px;
                }
              }
              ${Title} {
                font-size: 36px;
                margin-bottom: 32px;
              }
            }
            @media (max-height: 700px) {
              ${ImgWrapper} {
                margin-bottom: 16px;
                svg {
                  width: 170px;
                  height: 170px;
                }
              }

              ${Subtitle} {
                font-size: 20px;
              }
            }
          `)}

          ${media.mobile(css`
            @media (max-height: 920px) {
              ${Title} {
                font-size: 21px;
                margin-bottom: 16px;
              }
            }
            @media (max-height: 840px) {
              ${ImgWrapper} {
                margin-bottom: 32px;
                svg {
                  width: 130px;
                  height: 130px;
                }
              }
              ${Title} {
                font-size: 21px;
                margin-bottom: 16px;
              }
            }
            @media (max-height: 700px) {
              ${ImgWrapper} {
                margin-bottom: 32px;
                svg {
                  width: 130px;
                  height: 130px;
                }
              }

              ${Subtitle} {
                font-size: 14px;
              }
            }
          `)}
        `
      : ''}
`;

export default MessageSuccess;
