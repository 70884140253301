import React from 'react';
import styled, { css } from 'styled-components';
import { FormikProps, Form } from 'formik';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import Button from '@/components/Button';
import { PreorderFormPayload } from '@/services/requests';
import { TextInputFormik } from '@/components/TextInput';
import Spinner, { Overlay } from '@/components/Spinner';

import PreorderFormTop from './PreorderFormTop';
import MessageSuccess from '@/components/modals/PreorderModals/MessageSuccess';

export type PreorderFormValues = PreorderFormPayload;

type Props = FormikProps<PreorderFormValues> & {
  isSentSuccess: boolean;
  rowMarginBottom: number;
  isStatic?: boolean;
  onClose?: () => void;
  isReadyBlock?: boolean;
};

function PreorderForm({
  isSentSuccess,
  isSubmitting,
  rowMarginBottom,
  isStatic,
  onClose,
  isReadyBlock,
}: Props) {
  return (
    <Form autoComplete="off" noValidate>
      <FormContent
        rowMarginBottom={rowMarginBottom}
        isReadyBlock={isReadyBlock}
      >
        <MessageSuccess
          isSentSuccess={isSentSuccess}
          isReadyBlock={isReadyBlock}
        />
        <FormInner isSentSuccess={isSentSuccess}>
          {!isStatic ? <PreorderFormTop /> : null}
          <FormRow>
            <InputLabel>name</InputLabel>
            <TextInputFormik
              name="name"
              required
              autoComplete="off"
              clearErrorOnChange
            />
          </FormRow>
          <FormRow>
            <InputLabel>e-mail</InputLabel>
            <TextInputFormik
              type="email"
              name="email"
              required
              autoComplete="off"
              clearErrorOnChange
            />
          </FormRow>
          <FormRow>
            <SendButton
              variant="primary-third"
              type="submit"
              isSubmitting={isSubmitting}
              isSentSuccess={isSentSuccess}
              isStatic={isStatic}
            >
              Preorder for Free Now
              {isSubmitting ? (
                <Overlay>
                  <Spinner color="red" absolute={true} />
                </Overlay>
              ) : null}
            </SendButton>
          </FormRow>
          {!isStatic ? (
            <FormRow>
              <CloseButton onClick={onClose} isSentSuccess={isSentSuccess}>
                Actually I don't want to be successful :(
              </CloseButton>
            </FormRow>
          ) : null}
        </FormInner>
      </FormContent>
    </Form>
  );
}

const FormRow = styled.div`
  position: relative;
  max-width: 672px;
  margin: 0 auto;
  @media (max-height: 720px) {
    max-width: 600px;
  }

  & > div {
    width: 100%;
  }
  ${media.laptop(css`
    max-width: 504px;
  `)}
  ${media.tabletSmall(css`
    max-width: 450px;
  `)}
`;

const FormInner = styled.div<{ isSentSuccess: boolean }>`
  ${(props) =>
    props.isSentSuccess
      ? css`
          opacity: 0;
        `
      : css`
          opacity: 1;
        `}
`;

const SendButton = styled(Button)<{
  isSubmitting: boolean;
  isStatic?: boolean;
  isSentSuccess: boolean;
}>`
  margin-top: 32px;
  position: relative;
  padding: 25px 208px;
  border-radius: 16px;
  width: 100%;
  height: 100%;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  color: ${colors.white};
  ${(props) =>
    props.isStatic
      ? css`
          margin-bottom: 23px;
          ${media.laptop(css`
            margin-bottom: 16px;
          `)}
        `
      : ''}
  
  @media (max-height: 940px) {
    margin-top: 26px;
  }
  @media (max-height: 900px) {
  margin-top: 20px;
    padding: 20px 208px;
  }
  @media (max-height: 720px) {
    margin-top: 20px;
    padding: 20px 0;
  }
 
  ${media.laptop(css`
    padding: 19px 156px;
    font-size: 18px;
    line-height: 26px;
    border-radius: 12px;
    margin-top: 24px;
    @media (max-height: 720px) {
      margin-top: 18px;
    }
    @media (max-height: 700px) {
      margin-top: 14px;
      padding: 15px 156px;
    }
  `)}
  
  ${media.tabletSmall(css`
    padding: 19px 129px;
    cursor: default;
  `)}
    ${media.mobile(css`
      padding: 16px 59px;
      font-size: 14px;
      margin-top: 8px;
    `)}

  ${(props) =>
    props.isSubmitting
      ? css`
          cursor: default;
          &:hover {
            cursor: default;
          }
        `
      : css``};
  ${(props) =>
    props.isSentSuccess
      ? css`
          cursor: default;
          &:hover {
            cursor: default;
          }
        `
      : ''}
`;

const InputLabel = styled.div`
  font-weight: bold;
  font-size: 26px;
  line-height: 150%;
  text-transform: uppercase;
  margin-bottom: 8px;
  @media (max-height: 940px) {
    font-size: 24px;
    margin-bottom: 6px;
  }
  @media (max-height: 900px) {
    font-size: 20px;
    margin-bottom: 4px;
  }
  ${media.laptop(css`
    font-size: 20px;
    margin-bottom: 6px;
    @media (max-height: 700px) {
      margin-bottom: 4px;
    }
  `)}
  ${media.tabletSmall(css`
    font-size: 18px;
  `)}
  ${media.mobile(css`
    font-size: 14px;
    margin-bottom: 2px;
  `)}
`;

const CloseButton = styled.div<{ isSentSuccess: boolean }>`
  cursor: pointer;
  width: 100%;
  height: 32px;
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
  color: #fe9393;
  text-align: center;
  ${media.laptop(css`
    font-size: 16px;
  `)}
  ${media.tabletSmall(css`
    height: 24px;
    cursor: default;
  `)}
  ${media.mobile(css`
    height: 18px;
    font-size: 12px;
  `)}
  ${(props) =>
    props.isSentSuccess
      ? css`
          cursor: default;
        `
      : ''}
`;

const FormContent = styled.fieldset<{
  rowMarginBottom?: number;
  isReadyBlock?: boolean;
}>`
  position: relative;
  padding: 0;
  border: 0;
  flex: 0 0 100%;

  ${media.mobile(css`
    flex-basis: calc(100% - 40px);
  `)}
  & div:last-child {
    margin-bottom: 0;
  }
  ${FormRow} {
    margin-bottom: ${(props) =>
      props.rowMarginBottom ? props.rowMarginBottom : 30}px;
    @media (max-height: 820px) {
      margin-bottom: 20px;
    }
    ${media.laptop(css`
      margin-bottom: 24px;
      @media (max-height: 720px) {
        margin-bottom: 18px;
      }
    `)}
  }

  ${(props) =>
    props.isReadyBlock
      ? css`
          ${media.tabletSmall(css`
            @media (max-height: 700px) {
            }
          `)}
        `
      : ''}
`;

export default PreorderForm;
