import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '@/utils/mixin';
import ContentContainer from '@/components/ContentContainer';
import { colors } from '@/constants/theme';
import Button from '@/components/Button';
import { ButtonSlider } from '@/modules/Home/components/Quiz/components/QuizSlide';

function FirstSlide() {
  return (
    <Wrapper>
      <ContentContainer>
        <Description>
          During <TextChange>a consulting interview</TextChange> you will have
          to solve market sizing and estimations questions, making assumptions
          and performing calculations on the spot. Here are some examples taken
          <Text>
            from <TextChange>real case</TextChange> interviews
          </Text>
        </Description>
      </ContentContainer>
      <ButtonSlider isLeft>
        <Button className="slide-prev"></Button>
      </ButtonSlider>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: -13%;
  position: relative;
  min-height: 560px;
  height: 100%;
  @media (max-width: 1620px) {
   margin-left: -11%;
  }
  @media (max-width: 1580px) {
   margin-left: -10%;
  }
  @media (max-width: 1550px) {
   margin-left: -9%;
  }
  @media (max-height: 820px) {
    min-height: 519px;
    height: 100%;
    margin-bottom: 30px;
  }
  @media (max-height: 760px) {
    min-height: 403px;
    margin-bottom: 20px;
  }
  @media (max-height: 690px) {
    margin-bottom: 0;
  }
  ${media.laptop(css`
    margin-left: -14%;
    min-height: 420px;
    @media (max-height: 760px) {
      align-items: center;
      min-height: 403px;
      margin: 15px auto 0;
    }
  `)}
  ${media.tablet(css`
    margin-left: 0;
  `)}
  ${media.tabletSmall(css`
    min-height: 308px;
    @media (max-height: 900px) {
      min-height: 331px;
      margin-bottom: 15px;
    }
  `)}
  ${media.mobile(css`
    min-height: 300px;
  `)}
  ${media.mobileMedium(css`
    min-height: 460px;
    margin: 0 0 20px -18px;

    @media (min-height: 780px) {
      margin-bottom: 0;
    }
  `)}
`;

const Description = styled.p`
  font-weight: bold;
  font-size: 56px;
  line-height: 150%;
  max-width: 1385px;
  width: 100%;
  color:${colors.white};
  @media (max-height: 760px) {
      font-size: 50px;
  }
  @media (max-height: 700px) {
      font-size: 46px;
  }
  ${media.laptop(css`
    font-size: 44px;
    max-width: 1070px;
  `)}
  ${media.tabletSmall(css`
    font-weight: bold;
    font-size: 32px;
    line-height: 150%;
    max-width: 690px;
  `)}
  ${media.mobile(css`
    font-size: 26px;
    max-width: 87.5%;
  `)}
  ${media.mobileSmall(css`
    font-size: 21px;
  `)}
`;

const Text = styled.span`
  display: block;
  ${media.tabletSmall(css`
    display: inline;
  `)}
`;

const TextChange = styled.span`
  color: ${colors.red300};
`;

export default FirstSlide;
