import React from 'react';
import styled, { css } from 'styled-components';

import { PARTNERS_LIST_LOGO } from '../../../../Home.constants';
import PartnersItem from './components/PartnersItem';
import { media } from '@/utils/mixin';

type Props = {
  isHero?: boolean;
};

function PartnersBlock({ isHero }: Props) {
  return (
    <Wrapper isHero={isHero}>
      <List>
        {PARTNERS_LIST_LOGO.map((partnersItem, index) => {
          return (
            <ItemCell key={index}>
              <PartnersItem partnersItem={partnersItem} />
            </ItemCell>
          );
        })}
      </List>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ isHero?: boolean }>`
  display: flex;
  position: relative;
  ${media.mobile(css`
    order: 3;
  `)}
  ${(props) =>
    !props.isHero
      ? css`
          justify-content: center;
          position: absolute;
          bottom: 140px;
          @media (max-height: 940px) {
            bottom: 120px;
          }
          @media (max-height: 820px) {
            bottom: 90px;
          }
          @media (max-height: 750px) {
            bottom: 70px;
          }
          ${media.laptop(css`
            bottom: 180px;
            @media (max-height: 850px) {
              bottom: 120px;
            }
            @media (max-height: 780px) {
              bottom: 100px;
            }
            @media (max-height: 730px) {
              bottom: 50px;
            }
          `)}
          @media (max-width: 1025px) {
            position: relative;
            bottom: 0;
          }
          ${media.mobileMedium(css`
            bottom: -10px;
          `)}
        `
      : ''}
`;

const List = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -24px;
  @media (max-height: 850px) {
    flex-wrap: wrap;
    max-width: 60%;
    justify-content: center;
    margin: -10px -24px;
  }
  ${media.laptop(css`
    margin: 0 -20px;
    @media (max-height: 850px) {
      flex-wrap: nowrap;
      max-width: 100%;
      margin: 0px -24px;
    }
    @media (max-height: 800px) {
      flex-wrap: wrap;
      max-width: 60%;
      margin: -10px -24px;
    }
  `)}
  @media (max-width: 1025px) {
    max-width: 100%;
    flex-wrap: nowrap;
  }
  ${media.tabletSmall(css`
    flex-wrap: wrap;
    margin: -12px -20px;
    max-width: 661px;
  `)}
  ${media.mobileLarge(css`
    justify-content: center;
    margin: -12px -10px;
  `)}
`;
const ItemCell = styled.div`
  display: inline-flex;
  padding: 0 24px;
  @media (max-height: 850px) {
    padding: 10px 24px;
  }
  
  ${media.laptop(css`
    padding: 0 20px;
    @media (max-height: 850px) {
      padding: 0 24px;
    }
    @media (max-height: 800px) {
      padding: 10px 24px;
    }
    &:nth-child(1) {
      svg {
        width: 103px;
        height: 32px;
      }
    }
    &:nth-child(2) {
      svg {
        width: 115px;
        height: 24px;
      }
    }
    &:nth-child(3) {
      svg {
        max-width: 216px;
        max-height: 24px;
      }
    }
    &:nth-child(4) {
      svg {
        max-width: 85px;
        max-height: 36px;
      }
    }
    &:nth-child(5) {
      svg {
        max-width: 183px;
        max-height: 22px;
      }
    }
  `)}
  ${media.tabletSmall(css`
    padding: 12px 20px;
    &:nth-child(3) {
      svg {
        width: 216px;
        height: 24px;
      }
    }
    &:nth-child(4) {
      order: 2;
      svg {
        width: 85px;
        height: 36px;
      }
    }
    &:nth-child(5) {
      order: 2;
    }
    &:nth-child(6) {
      order: 2;
      svg {
        width: 272px;
        height: 24px;
      }
    }
  `)}
  ${media.mobileLarge(css`
    padding: 12px 10px;
    &:nth-child(1) {
      svg {
        width: 85px;
        height: 26px;
      }
    }
    &:nth-child(2) {
      svg {
        width: 95px;
        height: 20px;
      }
    }
    &:nth-child(3) {
      order: 3;
      svg {
        width: 145px;
        height: 13px;
      }
    }
    &:nth-child(4) {
      order: 4;
      svg {
        width: 57px;
        height: 24px;
      }
    }
    &:nth-child(5) {
      order: 3;
      svg {
        width: 123px;
        height: 15px;
      }
    }
    &:nth-child(6) {
      order: 2;
      svg {
        width: 115px;
        height: 13px;
      }
    }
  `)}
`;

export default PartnersBlock;
