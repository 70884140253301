import React from 'react';

import { ReactComponent as FacebookLogo } from '@/assets/svg/facebook.svg';
import { ReactComponent as InstagramLogo } from '@/assets/svg/instagram.svg';
import { ReactComponent as LinkedInLogo } from '@/assets/svg/linkedin.svg';
import { ReactComponent as YouTubeLogo } from '@/assets/svg/youtube.svg';

export type SocialItemType = {
  ItemIcon: React.ReactNode;
  url: string;
};

export const SOCIAL_LIST: Array<SocialItemType> = [
  {
    ItemIcon: <FacebookLogo />,
    url: 'https://facebook.com/Market-Sizing-100141778459952',
  },
  {
    ItemIcon: <YouTubeLogo />,
    url: 'https://www.youtube.com/channel/UC-B8K6TPjtLJMrB7KVoP70Q',
  },
];

export type NavbarItemType = {
  label: string;
  number: number;
  link: string;
};

export const FOOTER_NAVBAR_LIST: Array<NavbarItemType> = [
  {
    label: 'Home',
    number: 0,
    link: '#home',
  },
  {
    label: 'Quizz',
    number: 1,
    link: '#quizz',
  },
  {
    label: 'Teaser',
    number: 2,
    link: '#teaser',
  },
  {
    label: 'Examples',
    number: 3,
    link: '#examples',
  },
  {
    label: 'Team',
    number: 4,
    link: '#team',
  },
  {
    label: 'Offer',
    number: 5,
    link: '#offer',
  },
  {
    label: 'Preorder',
    number: 6,
    link: '#preorder',
  },
];
