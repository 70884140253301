import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import share from '@/services/share';

import ContentContainer from '@/components/ContentContainer';
import Button from '@/components/Button';

import FooterNav from './components/FooterNav/FooterNav';
import FooterEmail from './components/FooterEmail';
import FooterSocial from './components/FooterSocial';
import FooterLogo from './components/FooterLogo';
import { useLayoutContext } from '@/components/Layout/LayoutCtx';
import Picture from '@/components/Picture';

function Footer() {
  const SectionScroll = useLayoutContext();
  const authorsEnabled =
    process.env.NEXT_PUBLIC_SHOW_AUTHORS &&
    process.env.NEXT_PUBLIC_SHOW_AUTHORS.toString().toUpperCase() === 'TRUE';
  return (
    <FooterContainer>
      <ContentContainer>
        <FooterInner>
          <FooterTop>
            <FooterNav SectionScroll={SectionScroll} />
            <ButtonWrapper>
              <Button
                onClick={() => share('facebook')}
                variant="primary-second"
              >
                Share
              </Button>
            </ButtonWrapper>
          </FooterTop>
          <FooterBottom>
            <FooterRowLeft>
              <FooterEmail />
              <FooterSocial />
            </FooterRowLeft>
            <FooterRow>
              <FooterLogo />
              <FooterCopyright>
                © {new Date().getFullYear()} Market Sizing
              </FooterCopyright>
            </FooterRow>
            <FooterRowAuthors>
              {authorsEnabled ? (
                <>
                  <FooterDesignBy>
                    <DesignByText>
                      Design by{' '}
                      <FooterPixelLink
                        href="https://69pixels.com/"
                        target="_blank"
                      >
                        <span>69</span>pixels.
                      </FooterPixelLink>
                    </DesignByText>
                  </FooterDesignBy>
                  <FooterTager>
                    Powered by{' '}
                    <FooterTagerLink
                      href="https://tager.ozitag.com"
                      target="_blank"
                    >
                      TAGER
                    </FooterTagerLink>
                  </FooterTager>
                </>
              ) : null}
            </FooterRowAuthors>
          </FooterBottom>
        </FooterInner>
      </ContentContainer>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  flex: 0 0 288px;
  background-color: ${colors.black};
  ${media.laptop(css`
    flex: 0 0 216px;
  `)}
`;

const FooterInner = styled.div`
  padding: 42px 0 25px;
  ${media.tabletSmall(css`
    padding: 32px 0 19px;
  `)}
  ${media.mobileLarge(css`
    padding: 24px 0;
  `)}
`;

const FooterTop = styled.div`
  max-width: 1071px;
  width: 100%;
  margin: 0 auto 45px;
  display: flex;
  align-items: center;
  ${media.laptop(css`
    max-width: 811px;
    margin-bottom: 34px;
  `)}
  ${media.tabletSmall(css`
    max-width: 690px;
    margin-bottom: 32px;
  `)}
    ${media.mobileLarge(css`
      margin-bottom: 12px;
    `)}
`;

const ButtonWrapper = styled.div`
  width: 148px;
  margin-left: 45px;

  button {
    font-weight: 600;
    font-size: 24px;
    line-height: 150%;
    color: ${colors.white};
    padding: 8px 43px;
    border-radius: 133.333px;

  }
  ${media.laptop(css`
    width: 111px;
    margin-left: 24px;
    button {
      padding: 6px 32px;
      font-size: 18px;
      border-radius: 100px;
    }
  `)}
  ${media.tabletSmall(css`
    width: 106px;
    margin-left: 43px;
    button {
      font-size: 16px;
    }
  `)}
  
  ${media.mobile(css`
    position: relative;
    top: -10px;
    width: 127px;
    margin-left: 20px;
    button {
      font-size: 10px;
      line-height: 14px;
      padding: 9px 49.5px;
      border-radius: 29px;
    }
  `)}
    ${media.mobileSmall(css`
      margin-left: 0;
    `)}
`;

const FooterBottom = styled.div`
  padding-top: 32px;
  border-top: 1px solid #c4c4c4;
  display: flex;
  justify-content: space-between;
  ${media.laptop(css`
    padding-top: 24px;
  `)}
  ${media.mobile(css`
    padding-top: 16px;
    flex-direction: column;
    align-items: center;
    display: block;
  `)}
    ${media.mobile(css`
      padding-top: 12px;
    `)}
`;

const FooterRow = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FooterRowLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 340px;
`;

const FooterRowAuthors = styled.div`
  flex-basis: 340px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`;

const FooterCopyright = styled.div`
  font-weight: 900;
  font-size: 24px;
  line-height: 150%;
  color: ${colors.white};
  ${media.laptop(css`
    font-size: 18px;
  `)}
  ${media.tabletSmall(css`
    font-size: 16px;
    max-width: 140px;
    margin-left: -10px;
    text-align: center;
  `)}
  ${media.mobile(css`
    max-width: 100%;
    margin-left: 0;
  `)}
`;

const FooterCopyrightChange = styled.span`
  font-weight: 600;
`;

const FooterTager = styled.div`
  font-size: 20px;
  line-height: 130%;
  color: ${colors.white};
  font-weight: 600;
  width: 100%;
  text-align: right;
  margin-top: 8px;
  ${media.mobile(css`
    text-align: center;
  `)}
`;

const FooterTagerLink = styled.a`
  width: 84px;
  display: inline-block;
  letter-spacing: 0.2em;
  ${media.mobile(css`
    letter-spacing: 0.18em;
  `)}
  &:hover {
    color: #dd6900;
  }
`;
const FooterPixelLink = styled.a`
  &:hover {
    color: ${colors.redInvalid};
  }
`;

const FooterDesignBy = styled.div`
  width: 100%;
  ${media.laptop(css`
    svg {
      height: 18px;
    }
  `)}
  ${media.tabletSmall(css`
    svg {
      height: 16px;
    }
  `)}
    ${media.mobile(css`
      margin-top: 32px;
      svg {
        height: 14px;
      }
    `)}
    ${media.mobileSmall(css`
      svg {
        height: 12px;
      }
    `)}
`;

const DesignByText = styled.p`
  font-size: 20px;
  line-height: 130%;
  color: ${colors.white};
  font-weight: 600;
  margin-top: 8px;
  text-align: right;
  span {
    color: ${colors.redInvalid};
  }
  ${media.mobile(css`
    text-align: center;
  `)}
`;
export default Footer;
