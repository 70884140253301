import styled, { css } from 'styled-components';

import { CssSnippet } from '@tager/web-components';
import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

export type ReservedButtonVariant =
  | 'primary'
  | 'primary-second'
  | 'primary-third'
  | 'primary-fourth'
  | 'primary-fifth'
  | 'primary-sixth'
  | 'secondary'
  | 'outlined';

export type ButtonVariant = ReservedButtonVariant | CssSnippet;

const variantCssMap: Record<ReservedButtonVariant, CssSnippet> = {
  primary: css`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)),
      linear-gradient(257.15deg, #fd2828 0%, #ff1c2f 100%);
    box-shadow: 0px 5.33333px 32px rgba(255, 28, 47, 0.5);
    color: ${colors.white};
    ${media.laptop(css`
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.02),
          rgba(0, 0, 0, 0.02)
        ),
        linear-gradient(257.21deg, #fd2828 0%, #ff1c2f 100%);

      box-shadow: 0 4px 24px rgba(255, 28, 47, 0.5);
    `)}
    ${media.mobile(css`
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.02),
          rgba(0, 0, 0, 0.02)
        ),
        linear-gradient(257.14deg, #fd2828 0%, #ff1c2f 100%);
    `)}

    &:hover {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.12),
          rgba(0, 0, 0, 0.12)
        ),
        linear-gradient(256.66deg, #fd2828 0%, #ff1c2f 100%);
      box-shadow: 0 4px 32px rgba(255, 28, 47, 0.65);
    }
  `,

  'primary-second': css`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)),
      linear-gradient(256.66deg, #fd2828 0%, #ff1c2f 100%);
    box-shadow: 0 5.33333px 32px rgba(255, 28, 47, 0.5);
    ${media.laptop(css`
      box-shadow: 0 4px 24px rgba(255, 28, 47, 0.5);
    `)};
    ${media.mobile(css`
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.02),
          rgba(0, 0, 0, 0.02)
        ),
        linear-gradient(254.54deg, #fd2828 0%, #ff1c2f 100%);
    `)};
    &:hover {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.12),
          rgba(0, 0, 0, 0.12)
        ),
        linear-gradient(256.66deg, #fd2828 0%, #ff1c2f 100%);
      box-shadow: 0 4px 32px rgba(255, 28, 47, 0.65);
    }
  `,
  'primary-third': css`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)),
      linear-gradient(246.13deg, #fd2828 0%, #ff1c2f 100%);
    box-shadow: 0 5.33333px 32px rgba(255, 28, 47, 0.5);
    ${media.laptop(css`
      box-shadow: 0 4px 24px rgba(255, 28, 47, 0.5);
    `)};
    ${media.tabletSmall(css`
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.02),
          rgba(0, 0, 0, 0.02)
        ),
        linear-gradient(248.44deg, #fd2828 0%, #ff1c2f 100%);
    `)};
    ${media.mobile(css`
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.02),
          rgba(0, 0, 0, 0.02)
        ),
        linear-gradient(253.85deg, #fd2828 0%, #ff1c2f 100%);
    `)}
    &:hover {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.12),
          rgba(0, 0, 0, 0.12)
        ),
        linear-gradient(256.66deg, #fd2828 0%, #ff1c2f 100%);
      box-shadow: 0 4px 32px rgba(255, 28, 47, 0.65);
    }
  `,
  'primary-fourth': css`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)),
      linear-gradient(258.61deg, #fd2828 0%, #ff1c2f 100%);
    box-shadow: 0 5.33333px 32px rgba(255, 28, 47, 0.5);
    ${media.laptop(css`
      box-shadow: 0 4px 24px rgba(255, 28, 47, 0.5);
    `)}
    ${media.mobile(css`
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.02),
          rgba(0, 0, 0, 0.02)
        ),
        linear-gradient(257.14deg, #fd2828 0%, #ff1c2f 100%);
    `)};
    &:hover {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.12),
          rgba(0, 0, 0, 0.12)
        ),
        linear-gradient(256.66deg, #fd2828 0%, #ff1c2f 100%);
      box-shadow: 0 4px 32px rgba(255, 28, 47, 0.65);
    }
  `,
  'primary-fifth': css`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)),
      linear-gradient(266.25deg, #fd2828 0%, #ff1c2f 100%);
    box-shadow: 0 5.33333px 32px rgba(255, 28, 47, 0.5);
    ${media.laptop(css`
      box-shadow: 0 4px 24px rgba(255, 28, 47, 0.5);
    `)}
  `,
  'primary-sixth': css`
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.02), rgba(0, 0, 0, 0.02)),
      linear-gradient(260.91deg, #fd2828 0%, #ff1c2f 100%);
    box-shadow: 0 5.33333px 32px rgba(255, 28, 47, 0.5);
    ${media.laptop(css`
      box-shadow: 0 4px 24px rgba(255, 28, 47, 0.5);
    `)}
  `,
  secondary: css`
    background: ${colors.yellow};
    box-shadow: 0 5.33333px 21.3333px rgba(255, 210, 51, 0.5);
    ${media.laptop(css`
      box-shadow: 0 4px 16px rgba(255, 210, 51, 0.5);
    `)}
    &:hover {
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.06),
          rgba(0, 0, 0, 0.06)
        ),
        #ffd233;

      box-shadow: 0 4px 32px rgba(255, 210, 51, 0.65);
    }
  `,

  outlined: css`
    background-color: white;
    color: gray;
    border: 1px solid gray;

    &:hover {
      background-color: gray;
      opacity: 0.95;
      color: white;
    }
  `,
};

export const StyledButton = styled.button<{ variant?: ButtonVariant }>`
  width: 100%;
  height: 100%;
  text-align: center;
  transition: all 0.5s ease-in-out 0.1s;
  ${(props) =>
    typeof props.variant === 'string'
      ? variantCssMap[props.variant]
      : props.variant};
`;
