import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '@/utils/mixin';
import { colors } from '@/constants/theme';
import { BackgroundType } from '@/modules/Home/Home.constants';
import Picture from '@/components/Picture';

type Props = {
  profile: {
    avatar: BackgroundType;
    name: string;
    position: string;
  };
};

function Profile({ profile }: Props) {
  const { avatar, name, position } = profile;
  const { bgImage, bgImageWebp } = avatar;
  return (
    <Component>
      <Avatar>
        <Background>
          {bgImage && bgImageWebp ? (
            <Picture
              desktop={{
                src: bgImage.desktop,
                src2x: bgImage.desktop2x,
                webp: bgImageWebp.desktop,
                webp2x: bgImageWebp.desktop2x,
              }}
              tabletLarge={{
                src: bgImage.tablet,
                src2x: bgImage.tablet2x,
                webp: bgImageWebp.tablet,
                webp2x: bgImageWebp.tablet2x,
              }}
              mobileLarge={{
                src: bgImage.smallTablet,
                src2x: bgImage.smallTablet2x,
                webp: bgImageWebp.smallTablet,
                webp2x: bgImageWebp.smallTablet2x,
              }}
              mobileSmall={{
                src: bgImage.mobileS,
                src2x: bgImage.mobileS2x,
                webp: bgImageWebp.mobileS,
                webp2x: bgImageWebp.mobileS2x,
              }}
            />
          ) : null}
        </Background>
      </Avatar>
      <Info>
        <Name>{name}</Name>
        <Position>{position}</Position>
      </Info>
    </Component>
  );
}

const Component = styled.div`
  display: flex;
  margin-bottom: 16px;
  //margin-bottom: 21px;
  @media (max-height: 1000px) {
    margin-bottom: 15px;
  }
  @media (max-height: 820px) {
    margin-bottom: 16px;
  }
  @media (max-height: 780px) {
    margin-bottom: 15px;
  }
  ${media.laptop(css`
    margin-bottom: 16px;
    @media (max-height: 1000px) {
      margin-bottom: 15px;
    }
  `)}
  ${media.mobileLarge(css`
    margin-bottom: 19px;
  `)}
`;

const Avatar = styled.div`
  width: 72px;
  height: 72px;
  margin-right: 24px;
  border-radius: 100%;
  overflow: hidden;
  position: relative;
  //width: 96px;
  //height: 96px;
  //margin-right: 32px;
  @media (max-height: 1000px) {
    width: 64px;
    height: 64px;
  }
  @media (max-height: 820px) {
    width: 72px;
    height: 72px;
  }
  @media (max-height: 780px) {
    width: 64px;
    height: 64px;
  }
  ${media.laptop(css`
    width: 72px;
    height: 72px;
    margin-right: 24px;
    @media (max-height: 1000px) {
      width: 64px;
      height: 64px;
    }
    @media (max-height: 880px) {
      width: 48px;
      height: 48px;
      margin-right: 12px;
    }
    @media (max-height: 820px) {
      width: 64px;
      height: 64px;
      margin-right: 24px;
    }
    @media (max-height: 680px) {
      width: 48px;
      height: 48px;
      margin-right: 12px;
    }
  `)}
  ${media.tablet(css`
    width: 48px;
    height: 48px;
  `)}
  ${media.mobileLarge(css`
    width: 48px;
    height: 48px;
    margin-right: 12px;
  `)}
`;
const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Info = styled.div``;

const Name = styled.span`
  display: block;
  font-weight: bold;
  line-height: 150%;
  color: ${colors.white};
  font-size: 24px;
  margin-bottom: 12px;
  //font-size: 32px;
  //margin-bottom: 16px;
  @media (max-height: 1000px) {
    font-size: 20px;
    margin-bottom: 13px;
  }
  @media (max-height: 820px) {
    font-size: 24px;
    margin-bottom: 12px;
  }
  @media (max-height: 780px) {
    font-size: 20px;
    margin-bottom: 13px;
  }
  ${media.laptop(css`
    font-size: 24px;
    margin-bottom: 12px;
    @media (max-height: 1000px) {
      font-size: 20px;
      margin-bottom: 13px;
    }
    @media (max-height: 880px) {
      font-size: 16px;
      margin-bottom: 6px;
    }
    @media (max-height: 820px) {
      font-size: 20px;
      margin-bottom: 13px;
    }
    @media (max-height: 680px) {
      font-size: 16px;
      margin-bottom: 6px;
    }
  `)}
  ${media.tablet(css`
    font-size: 16px;
    margin-bottom: 6px;
  `)}
  ${media.mobileLarge(css`
    font-size: 16px;
    margin-bottom: 6px;
  `)}
`;
const Position = styled.span`
  display: block;
  font-weight: 600;
  line-height: 150%;
  color: ${colors.grayDark};
  font-size: 16px;
  //font-size: 21.3333px;
  @media (max-height: 1000px) {
    font-size: 14px;
  }
  @media (max-height: 820px) {
    font-size: 16px;
  }
  @media (max-height: 780px) {
    font-size: 14px;
  }
  ${media.laptop(css`
    font-size: 16px;
    @media (max-height: 1000px) {
      font-size: 14px;
    }
    @media (max-height: 880px) {
      font-size: 12px;
    }
    @media (max-height: 820px) {
      font-size: 14px;
    }
    @media (max-height: 680px) {
      font-size: 12px;
    }
  `)}
  ${media.tablet(css`
    font-size: 12px;
  `)}
  ${media.mobileLarge(css`
    font-size: 12px;
  `)}
`;

export default Profile;
