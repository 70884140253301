import React from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';

import { ReactComponent as CloseIcon } from '@/assets/svg/close-popup.svg';

type Props = {
  children?: React.ReactNode;
  width?: number;
  widthLaptop?: number;
  widthTabletSmall?: number;
  onClose?: () => void;
  centered?: boolean;
  isClose: boolean;
  isOnlyMobile?: boolean;
  isReadyBlock?: boolean;
};

function ModalBox({
  children,
  width,
  widthLaptop,
  widthTabletSmall,
  onClose,
  centered,
  isClose,
  isOnlyMobile,
  isReadyBlock,
}: Props) {
  return (
    <ModalContainer
      centered={centered}
      width={width}
      widthLaptop={widthLaptop}
      widthTabletSmall={widthTabletSmall}
      isReadyBlock={isReadyBlock}
    >
      {isClose ? (
        <CloseBtn isOnlyMobile={isOnlyMobile} onClick={onClose}>
          <CloseIcon />
        </CloseBtn>
      ) : null}
      <ModalContent>{children}</ModalContent>
    </ModalContainer>
  );
}

export const ModalContainer = styled.div<{
  width?: number;
  widthLaptop?: number;
  widthTabletSmall?: number;
  centered?: boolean;
  isReadyBlock?: boolean;
}>`
  ${(props) =>
    props.centered
      ? css`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `
      : css`
          position: relative;
          margin: 0 auto;
        `};

  max-width: ${(props) => props.width || 780}px;
  width: 100%;
  border-radius: 32px;
  background-color: ${colors.white};
  padding: 64px 0;
  position: relative;
  cursor: default;
  @media (max-height: 1000px) {
    padding: 35px 0;
  }
  @media (max-height: 820px) {
    padding: 25px 0;
    max-width: 760px;
  }
    @media (max-height: 700px) {
    padding: 20px 0 10px;
      max-width: 720px;
  }
  
  ${media.laptop(css`
    border-radius: 24px;
    padding: 48px 0;
    @media (max-height: 800px) {
      padding: 25px 0;
    }
  `)}
  

  
  ${(props) =>
    props.widthLaptop
      ? css`
          ${media.laptop(css`
            max-width: ${props.widthLaptop}px;
          `)}
        `
      : css`
          ${media.laptop(css`
            max-width: 770px;
          `)}
        `}

    ${(props) =>
      props.widthTabletSmall
        ? css`
            ${media.tabletSmall(css`
              max-width: ${props.widthTabletSmall}px;
            `)}
          `
        : css`
            ${media.tabletSmall(css`
              max-width: 650px;
            `)}
          `}
      ${(props) =>
        props.isReadyBlock
          ? css`
              padding: 40px 0;
              ${media.tabletSmall(css`
                @media (max-height: 700px) {
                  max-width: 550px;
                }
              `)}
            `
          : ''}
  ${media.mobile(css`
    max-width: calc(100% - 40px);
    border-radius: 16px;
    padding: 32px 20px;
  `)}
  ${media.mobileSmall(css`
    max-width: calc(100% - 20px);
    padding: 32px 16px;
  `)}
`;

export const CloseBtn = styled.button<{ isOnlyMobile?: boolean }>`
  position: absolute;
  top: 30px;
  right: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: transparent;
  transition: 0.3s ease;
  ${media.laptop(css`
    top: 20px;
    right: 20px;
  `)}
  ${media.mobileMedium(css`
    top: 3px;
    right: 3px;
  `)}

  svg {
    width: 34px;
    height: 34px;
    ${media.laptop(css`
      width: 26px;
      height: 26px;
    `)}
    ${media.tabletSmall(css`
      width: 24px;
      height: 24px;
    `)}
    ${media.mobile(css`
      width: 16px;
      height: 16px;
    `)}
    ${media.mobileSmall(css`
      width: 12px;
      height: 12px;
    `)}
  }

  &:hover {
    svg {
      fill: ${colors.black500};
    }
  }
  ${(props) =>
    props.isOnlyMobile
      ? css`
          display: none;
          ${media.mobile(css`
            display: block;
          `)}
        `
      : ''}
`;

export const ModalContent = styled.div`
  max-width: 760px;
  margin: 0 auto;

  ${media.laptop(css`
    max-width: 570px;
  `)}
    ${media.tabletSmall(css`
      max-width: 450px;
    `)}
  ${media.mobile(css`
    overflow-y: auto;
    &::-webkit-scrollbar-button {
      background-repeat: no-repeat;
      width: 4px;
      height: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: ${colors.white};
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      background-color: ${colors.white};
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: #56999f;
    }

    &::-webkit-resizer {
      background-repeat: no-repeat;
      width: 4px;
      height: 0;
    }

    &::-webkit-scrollbar {
      width: 4px;
    }
  `)}
`;
export default ModalBox;
